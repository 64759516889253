/* eslint-disable no-unused-vars */
import moment from 'moment';
import Vue from 'vue';

const initialState = () => ({
  carregando: false,
  faturamentoCashback: {
    valorTotalCompras: 0,
    valorTotalCashbackUtilizado: 0,
  },
  visaoGeralEnvios: [],
  totalMovimentacaoCPF: {
    totalMovimentacaoCPF: 0,
    totalUsuariosComDebitos: 0,
  },
  baseDeCredito: {
    totalGerado: 0,
    liberadoParaUso: 0,
    emBloqueio: 0,
    resgatado: 0,
    expirado: 0,
    totalSaldoPrestesExpirar: 0,
  },
  ultimosLancamentos: [],
  valorTotalEscaneado: {
    valorTotalEscaneado: 0,
    quantidadeVendas: 0,
  },
  mediaValorTotalEscaneado: 0,
  topClientesEngajados: [],
});

const state = initialState();

const getters = {
  getCarregando: (state) => {
    return state.carregando;
  },
  getFaturamentoCashback: (state) => {
    return state.faturamentoCashback;
  },
  getVisaoGeralEnvios: (state) => {
    return state.visaoGeralEnvios;
  },
  getTotalMovimentacaoCPF: (state) => {
    return state.totalMovimentacaoCPF;
  },
  getBaseDeCredito: (state) => {
    return state.baseDeCredito;
  },
  getUltimosLancamentos: (state) => {
    return state.ultimosLancamentos;
  },
  getValorTotalEscaneado: (state) => {
    return state.valorTotalEscaneado;
  },
  getMediaValorTotalEscaneado: (state) => {
    return state.mediaValorTotalEscaneado;
  },
  getTopClientesEngajados: (state) => {
    return state.topClientesEngajados;
  },
};

const actions = {
  async fetchFaturamentoCashback({ commit }, { filtros = {} }) {
    try {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/faturamentoCashback?dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}&associadoCnpj=${filtros.associadoCnpj}`;

      const res = await comunicacao.send(url);

      commit('setFaturamentoCashback', {
        valorTotalCompras: res.data.retorno.valorTotalCompras,
        valorTotalCashbackUtilizado: res.data.retorno.valorTotalCashbackUtilizado,
      });
    } catch (error) {
      commit('setFaturamentoCashback', {
        valorTotalCompras: 0,
        valorTotalCashbackUtilizado: 0,
      });
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchEngajamentoPorCPF({ commit }) {
    // commit('setLoader', true);
    // const comunicacao = new Vue.prototype.$comunicacao();
    // const url = '/documentoFiscal/v1/documentos/engajamentoPorCPF';
    // const res = await comunicacao.send(url);
    // commit('setLoader', false);
  },
  async fetchTotalMovimentacaoCPF({ commit }, { filtros = {} }) {
    try {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/movimentacaoCashback?filtroDataInicial=${filtros.dataInicial}&filtroDataFinal=${filtros.dataFinal}&filtroAssociadoCNPJ=${filtros.associadoCnpj}`;

      const res = await comunicacao.send(url);

      commit('setTotalMovimentacaoCPF', {
        totalMovimentacaoCPF: res.data.retorno.totalMovimentacaoCPF,
        totalUsuariosComDebitos: res.data.retorno.totalUsuariosComDebitos,
      });
    } catch (error) {
      commit('setTotalMovimentacaoCPF', {
        totalMovimentacaoCPF: 0,
        totalUsuariosComDebitos: 0,
      });
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchEngajamentoUsuarios({ commit }) {
    // commit('setLoader', true);
    // const comunicacao = new Vue.prototype.$comunicacao();
    // const url = '/fidelidade/v1/extratos/engajamentoUsuarios';
    // const res = await comunicacao.send(url);
    // commit('setLoader', false);
  },
  async fetchBaseDeCredito({ commit }, { filtros = {} }) {
    try {
      commit('setLoader', true);
      // pegar a data atual mais 7 dias
      const dataPrestesExpirar = moment().add(7, 'days').format('YYYY-MM-DD');

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/baseDeCredito?filtroDataPrestesExpirar=${dataPrestesExpirar}&filtroDataInicial=${filtros.dataInicial}&filtroDataFinal=${filtros.dataFinal}&filtroAssociadoCnpj=${filtros.associadoCnpj}`;

      const {
        data: {
          retorno: {
            total,
            totalSaldoDisponivel,
            totalSaldoExpirado,
            totalSaldoNaoDisponivel,
            totalSaldoUtilizado,
            totalSaldoPrestesExpirar,
          },
        },
      } = await comunicacao.send(url);

      commit('setBaseDeCredito', {
        totalGerado: total,
        liberadoParaUso: totalSaldoDisponivel,
        emBloqueio: totalSaldoNaoDisponivel,
        resgatado: totalSaldoUtilizado,
        expirado: totalSaldoExpirado,
        totalSaldoPrestesExpirar,
      });
    } catch (error) {
      commit('setBaseDeCredito', {
        totalGerado: 0,
        liberadoParaUso: 0,
        emBloqueio: 0,
        resgatado: 0,
        expirado: 0,
        totalSaldoPrestesExpirar: 0,
      });
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchVisaoGeralEnvios({ commit }, { filtros = {} }) {
    try {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/notificacao/v1/notificacoes/visaoGeralEnvios?dataHoraInicial=${filtros.dataInicial}&dataHoraFinal=${filtros.dataFinal}&associadoCNPJ=${filtros.associadoCnpj}`;

      const res = await comunicacao.send(url);

      commit('setVisaoGeralEnvios', res.data.retorno);
    } catch (error) {
      commit('setVisaoGeralEnvios', [
        { metodoEnvio: 'WHATSAPP', quantidade: 0 },
        { metodoEnvio: 'EMAIL', quantidade: 0 },
        { metodoEnvio: 'PUSH', quantidade: 0 },
        { metodoEnvio: 'SMS', quantidade: 0 },
      ]);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchUltimosLancamentos({ commit }) {
    try {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/cashback/v1/extratos/10/1';

      const res = await comunicacao.send(url);

      commit('setUltimosLancamentos', res.data.retorno.retorno);
    } catch (error) {
      commit('setUltimosLancamentos', []);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchValorTotalEscaneado({ commit }, { filtros = {} }) {
    try {
      commit('setLoader', true);

      // const campanha = Vue.prototype.$campanha.obterCampanhaAtual();

      const comunicacao = new Vue.prototype.$comunicacao();
      // const url = `/documentoFiscal/v1/documentos/valorTotalEscaneado?dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}&emitenteCnpj=${filtros.associadoCnpj}&anoCampanha=${campanha.ano}&identificacaoCampanha=${campanha.identificacao}`;
      const url = `/documentoFiscal/v1/documentos/valorTotalEscaneado?dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}&emitenteCnpj=${filtros.associadoCnpj}`;

      const res = await comunicacao.send(url);

      commit('setValorTotalEscaneado', {
        valorTotalEscaneado: res.data.retorno.valorTotalEscaneado,
        quantidadeVendas: res.data.retorno.quantidadeVendas,
      });
      commit('setMediaValorTotalEscaneado', res.data.retorno.valorTotalEscaneado / res.data.retorno.quantidadeVendas);
    } catch (error) {
      commit('setValorTotalEscaneado', {
        valorTotalEscaneado: 0,
        quantidadeVendas: 0,
      });
      commit('setMediaValorTotalEscaneado', 0);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchTopClientesEngajados({ commit }, { filtros = {} }) {
    try {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/saldo?ordemPor=concedido&ordemSentido=DESC&dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}&associado=${filtros.associadoCnpj}`;

      const res = await comunicacao.send(url);

      const splitTopClientesEngajados = res.data.retorno.slice(0, 4);

      commit('setTopClientesEngajados', splitTopClientesEngajados);
    } catch (error) {
      commit('setTopClientesEngajados', []);
    } finally {
      commit('setLoader', false);
    }
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setLoader(state, payload) {
    state.carregando = payload;
  },
  setFaturamentoCashback(state, payload) {
    state.faturamentoCashback = payload;
  },
  setVisaoGeralEnvios(state, payload) {
    state.visaoGeralEnvios = payload;
  },
  setTotalMovimentacaoCPF(state, payload) {
    state.totalMovimentacaoCPF = payload;
  },
  setBaseDeCredito(state, payload) {
    state.baseDeCredito = payload;
  },
  setUltimosLancamentos(state, payload) {
    state.ultimosLancamentos = payload;
  },
  setValorTotalEscaneado(state, payload) {
    state.valorTotalEscaneado = payload;
  },
  setMediaValorTotalEscaneado(state, payload) {
    state.mediaValorTotalEscaneado = payload;
  },
  setTopClientesEngajados(state, payload) {
    state.topClientesEngajados = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
