/**
 * Classe para formatar Número.
 */
class Numeros {

    /**
     * Construtor vazio.
     */
    constructor() { }

    /**
     * Formata o número com vírgula
     * @param {Number} string
     * @returns {string} - número com ,
     */
    static formatarNumero(numero) {
        return numero.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
}

export default Numeros;