var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[(this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase()))?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link cursor-pointer",on:{"click":function($event){_vm.notificacaoCollapse = !_vm.notificacaoCollapse}}},[_c('i',{staticClass:"fas fa-bell"}),_c('span',{staticClass:"nav-link-text",staticStyle:{"flex-grow":"1"}},[_vm._v("Disparos")]),_c('i',{staticClass:"text-right",class:{
            'fas fa-chevron-down': _vm.notificacaoCollapse,
            'fas fa-chevron-up': !_vm.notificacaoCollapse,
          }})])]):_vm._e(),(!_vm.notificacaoCollapse)?_c('ul',{staticClass:"sidebar-collapse"},[(this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase()))?_c('sidebar-item',{attrs:{"link":{
          name: 'Eventos',
          icon: 'fas fa-cog text-dark',
          path: '/crm/eventos',
        }}}):_vm._e(),(this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase()))?_c('sidebar-item',{attrs:{"link":{
          name: 'Notificações',
          icon: 'fas fa-paper-plane text-dark',
          path: '/crm/notificacao',
        }}}):_vm._e()],1):_vm._e()],[(this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase()))?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link cursor-pointer",on:{"click":function($event){_vm.emailCollapse = !_vm.emailCollapse}}},[_c('i',{staticClass:"fas fa-envelope"}),_c('span',{staticClass:"nav-link-text",staticStyle:{"flex-grow":"1"}},[_vm._v("Email")]),_c('i',{staticClass:"text-right",class:{
            'fas fa-chevron-down': _vm.emailCollapse,
            'fas fa-chevron-up': !_vm.emailCollapse,
          }})])]):_vm._e(),(!_vm.emailCollapse)?_c('ul',{staticClass:"sidebar-collapse"},[(this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase()))?_c('sidebar-item',{attrs:{"link":{
          name: 'Modelo de email',
          icon: 'fas fa-cog text-dark',
          path: '/crm/email/listaModelos',
        }}}):_vm._e()],1):_vm._e()],_c('sidebar-item',{attrs:{"link":{
      name: 'Integrações',
      icon: 'fas fa-cogs',
      path: '/crm/integracoes',
    }}}),_c('sidebar-item',{attrs:{"link":{
      name: 'Grupos',
      icon: 'fas fa-users',
      path: '/crm/grupos',
    }}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }