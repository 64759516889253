import { Permissoes } from '../../lib/Helper';


export default function acessoPagina({ to, router, next }) {
  const permissaoUsuario = Permissoes.obter();

  if (permissaoUsuario) {
    const [_, caminhoRota] = to.path.split('/')

    if (permissaoUsuario.acessoTotal) {
      return next();
    }

    if (permissaoUsuario.paginas.includes(caminhoRota)) {
      return next();
    }

    return router.push({
      path: `/${permissaoUsuario.paginas[0]}`
    });
  }

  return next();

}