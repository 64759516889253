/**
 * Retorno padrão para chamadas à métodos e api.
 * @class {Autenticacao}
 */
class Autenticacao {
  /**
   * Cria um objeto de comunicação com o WebService
   * @param {string} token [optional] - token de autenticacao
   * @param {string} usuario - Usuario
   * @param {string} senha - senha
   * @param {string} tipo - tipo do usuario
   * @param {Comunicacao} comunicacao - objeto de comunicacao
   * @param {function} errorHandler - senha
   */
  constructor(token = null, usuario, senha, comunicacao, tipo = null, nome = null, errorHandler = function () {}) {
    this.token = token;
    this.usuario = usuario;
    this.senha = senha;
    this.comunicacao = comunicacao;
    this.tipo = tipo;
    this.nome = nome;
    this.errorHandler = errorHandler;
    this.error;
  }
  async iniciar() {
    // console.log(this.token, this.usuario, this.senha, this.comunicacao, this.tipo, this.nome);
    // Verificando token autorização (caso não exista).
    if (!this.token) {
      // Requisição para obter token.
      let resposta = await this.comunicacao.send('/login/v1/autenticacao', {
        method: 'POST',
        data: {
          usuario: this.usuario,
          senha: this.senha,
        },
      });
      // Resposta da requisição.
      if (resposta.status == 200) {
        this.token = resposta.data.retorno.token;
        this.tipo = resposta.data.retorno.tipo;
        this.nome = resposta.data.retorno.nome;
      } else {
        this.error = resposta.status;
        this.errorHandler(resposta);
      }
    }

    // Verificando token de autorização (caso exista).
    if (this.token) {
      // Atualizar token do cliente http.
      this.comunicacao.token = this.token;
      // Atualizar login no localStorage.
      localStorage.setItem(
        'login',
        JSON.stringify({
          token: this.token,
          usuario: this.usuario,
          tipo: this.tipo,
          nome: this.nome,
        })
      );
    }

    return this;
  }
  // Retorna token de autenticação (se existir).
  static logado() {
    let login = localStorage.getItem('login');

    if (login) {
      login = JSON.parse(login);
      if (login.token) {
        return login;
      }
    }
    return false;
  }
}

export default Autenticacao;
