const servicoKey = 'servicos@DashboardQRSorteios'
const servicoPadrao = 'SORTEIOS'

/**
 * Classe para salvar e recuperar o serviço ativo do local storage quando necessário.
 */
class Servico {
  /**
   * Construtor vazio.
   */
  constructor() { }
  /**
   * Grava a o serviço ativo no LocalStorage da aplicação.
   * @param {object} servico 
   */
  static gravarServicoAtivo(servico = servicoPadrao) {
    localStorage.setItem(servicoKey, servico);
  }

  /**
   * Retorna a serviço atual ativo.
   */
  static obterServicoAtivo() {
    if (localStorage.getItem(servicoKey)) {
      return localStorage.getItem(servicoKey);
    } else {
      return servicoPadrao;
    }
  }
  
  /**
   * Remover o serviço ativo do LocalStorage da aplicação.
   */
  static removerServicoAtivo() {
    if (localStorage.getItem(servicoKey)) {
      localStorage.removeItem(servicoKey);
      return true;
    } else {
      return false;
    }
  }
}

export default Servico;