// Módulos do app.
import { StoreConfig } from "./lib/Storage";
import Vue from "vue";
import App from "./App.vue";
import AppError from "./AppError.vue";

import "./registerServiceWorker";

import router from "./router";
import plugins from "./plugins";
import store from './store';

Vue.config.productionTip = false;
// Método para iniciar app.
const iniciarApp = () => {
  // Vue plugins.
  Vue.use(plugins);
  // Vue app.
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
};

let storeConfig = new StoreConfig();

if (StoreConfig.configuracoes()) {
  iniciarApp();
  storeConfig.iniciar();
} else {
  storeConfig.iniciar().then(retorno => {
    if (retorno) {
      iniciarApp();
    } else {
      new Vue({ render: h => h(AppError) }).$mount("#app");
    }
  });
}