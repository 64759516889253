<template>
  <div v-if="dados.condicao(configuracoes)">
    <template v-if="dados.paginas.length">
      <li class="nav-item" @click="toggle">
        <a class="nav-link cursor-pointer">
          <i :class="dados.icone"></i>
          <span class="nav-link-text" style="flex-grow: 1">{{ dados.titulo }}</span>

          <i
            class="text-right"
            :class="{
              'fas fa-chevron-down': !ativo,
              'fas fa-chevron-up': ativo,
            }"
          />
        </a>
      </li>

      <ul v-show="ativo" class="sidebar-collapse">
        <sidebar-item
          v-for="(pagina, idx) in paginasFiltradas"
          :key="idx"
          :link="{
            name: pagina.titulo(configuracoes),
            icon: `${pagina.icone} text-dark`,
            path: `/${pagina.caminho}`,
          }"
        />
      </ul>
    </template>

    <template v-else>
      <sidebar-item
        :link="{
          name: dados.titulo,
          icon: `${dados.icone} text-dark`,
          path: `/${dados.caminho}`,
        }"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'ItemSidebar',

  props: {
    dados: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      ativo: false,
      configuracoes: null,
    };
  },

  computed: {
    paginasFiltradas() {
      return this.dados.paginas.filter((e) => e.condicao(this.configuracoes) === true);
    },
  },

  methods: {
    toggle() {
      this.ativo = !this.ativo;
    },
  },

  beforeMount() {
    this.configuracoes = this.$storeConfig.configuracoes();
  },
};
</script>

<style>
</style>
