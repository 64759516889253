import Vue from 'vue'

// Object initial state
const initialState = () => ({
  industrias: [],
  totalIndustrias: 0,
  carregando: false,
  erro: null,
  totalPaginas: 0,
  industria: {}
})

// State object
const state = initialState()

// Getter object
const getters = {
  getIndustria(state) {
    return state.industria
  },

  getIndustrias(state) {
    return state.industrias
  },

  getTotalizador(state) {
    return state.totalIndustrias
  },

  getPaginas(state) {
    return state.totalPaginas;
  },

  getLoader(state) {
    return state.carregando
  },

  getErro(state) {
    return state.erro
  },
}

// Actions
const actions = {
  listarIndustrias({ commit }, { paginacao, filtro = {} }) {
    return new Promise(async resolve => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()

      let endpoint = `/produto/v1/industrias/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`

      if (Object.entries(filtro).length > 0) {
        if (filtro.filtroCnpj.ativo && filtro.filtroCnpj.conteudo) {
          endpoint += `?cnpjCpf=${filtro.filtroCnpj.conteudo}`
        }
        if (filtro.filtroCnpj.ativo && filtro.filtroCnpj.conteudo && filtro.filtroNome.ativo && filtro.filtroNome.conteudo) {
          endpoint += `&nomeFantasia=${filtro.filtroNome.conteudo}`
        } else if (filtro.filtroNome.ativo && filtro.filtroNome.conteudo) {
          endpoint += `?nomeFantasia=${filtro.filtroNome.conteudo}`
        }
        if ((filtro.filtroCnpj.conteudo || filtro.filtroNome.conteudo) && filtro.filtroCodigo.ativo && filtro.filtroCodigo.conteudo) {
          endpoint += `&codigoInterno=${filtro.filtroCodigo.conteudo}`
        } else if (filtro.filtroCodigo.ativo && filtro.filtroCodigo.conteudo) {
          endpoint += `?codigoInterno=${filtro.filtroCodigo.conteudo}`
        }
      }

      const res = await comunicacao.send(endpoint, {
        method: 'get'
      })

      if ([200, 201].includes(res.status)) {
        commit('setIndustrias', res.data.retorno.retorno)
        commit('setTotalizador', res.data.retorno.total)
        commit('setPaginas', res.data.retorno.paginas);
        commit('setErro', null)
      } else if ([404].includes(res.status)) {
        commit('setIndustrias', [])
        commit('setTotalizador', 0)
        commit('setErro', null)
      } else {
        commit('setTotalizador', 0)
        commit(
          'setErro',
          res.data.mensagem ||
          'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        )
      }
      commit('setLoader', false)
      resolve()
    })
  },
  obterIndustria({ commit }, { cnpjCpf }) {
    return new Promise(async resolve => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = `/produto/v1/industria/${cnpjCpf}`
      const res = await comunicacao.send(endpoint, {
        method: 'get'
      })
      if ([200, 201].includes(res.status)) {
        commit('setIndustria', res.data.retorno)
        commit('setErro', null)
      } else if ([404].includes(res.status)) {
        commit('setIndustria', {})
        commit('setErro', null)
      } else {
        commit(
          'setErro',
          res.data.mensagem ||
          'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        )
      }
      commit('setLoader', false)
      resolve()
    })
  },
  cadastrarIndustria({ commit }, { cnpjCpf, razaoSocial, nomeFantasia, senhaBI, urlFotoIndustria, codigoInterno }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = '/produto/v1/industrias'
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          cnpjCpf,
          razaoSocial,
          nomeFantasia,
          senhaBI,
          urlFotoIndustria,
          codigoInterno,
        },
      })
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false)
        resolve({ status: 200, mensagem: 'Indústria cadastrada com sucesso' })
      } else {
        let msgErro = ''
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i]
          }
        } else {
          msgErro = res.data.mensagem
        }
        commit('setLoader', false)
        resolve({ status: res.status || 500, mensagem: msgErro })
      }
    })
  },
  alterarIndustria({ commit }, { cnpjCpf, razaoSocial, nomeFantasia, senhaBI, urlFotoIndustria, codigoInterno }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = '/produto/v1/industrias'
      const res = await comunicacao.send(endpoint, {
        method: 'PUT',
        data: {
          cnpjCpf,
          razaoSocial,
          nomeFantasia,
          senhaBI,
          urlFotoIndustria,
          codigoInterno
        },
      })
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false)
        resolve({ status: 200, mensagem: 'Indústria alterada com sucesso' })
      } else {
        let msgErro = ''
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i]
          }
        } else {
          msgErro = res.data.mensagem
        }
        commit('setLoader', false)
        resolve({ status: res.status || 500, mensagem: msgErro })
      }
    })
  },

  obterListaParaProduto({ commit }, { id }) {
      const industriaFiltrada = state.industrias.filter((e) => e.id != id);
      commit('setIndustrias', industriaFiltrada);
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  setIndustrias(state, data) {
    state.industrias = data
  },

  setIndustria(state, data) {
    state.industria = data
  },

  setTotalizador(state, data) {
    state.totalIndustrias = data
  },

  setLoader(state, data) {
    state.carregando = data
  },

  setPaginas(state, data) {
    state.totalPaginas = data;
  },

  setErro(state, data) {
    state.erro = data
  },
}

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
