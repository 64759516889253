import Vue from 'vue'

// Object initial state
const initialState = () => ({
  docs: [],
  docTotal: 0,
  carregando: false
})

// State object
const state = initialState()

// Getter object
const getters = {
  getDocs(state) {
    return state.docs;
  },
  getTotalDocs(state) {
    return state.docs.length
  },
  getLoader(state) {
    return state.carregando
  }
}

// Actions
const actions = {
  fetchDocs({ commit }, { campanha, data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()
      const url = `/documentoFiscal/v1/documentos/resumoPorEmitente/${campanha.ano}/${campanha.identificacao}/${data}`
      let res = await comunicacao.send(url, { method: 'GET' } )
      if([200, 201].includes(res.status)) {
        commit('setDocs', res.data.retorno)
        commit('setTotalDocs', res.data.retorno.total)
      } else {
        commit('setDocs', [])
        commit('setTotalDocs', 0)
      }
      resolve();
      commit('setLoader', false)
    })
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
  setTotalDocs(state, data) {
    state.docTotal = data;
  },
  setDocs(state, data) {
    state.docs = data;
  },
  setLoader(state, data) {
    state.carregando = data
  }
}

// Exporting store module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}