import Vue from 'vue';

const initialState = () => ({});

const state = initialState();

const getters = {};

const actions = {
  salvarConfiguracaoCompra(_, { campanha, quantidade, valor, compra, produtos = [] }) {
    return new Promise(async resolve => {
      const data = {
        campanha: { ano: campanha.ano, identificacao: campanha.identificacao },
        quantidade,
        valor,
        compra,
        produtos
      };

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/raspadinhaAlternativa/v1/configuracaoCompra';
      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data,
      });

      if ([200, 201].includes(resposta.data.status)) {
        resolve({
          status: 200,
          mensagem: 'Configuração compra salva com sucesso!',
        });
      } else {
        resolve({
          status: resposta.data.status,
          mensagem:
            resposta.data.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição',
        });
      }
    });
  },

  obterConfiguracaoCompra(_, { campanha }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/raspadinhaAlternativa/v1/configuracaoCompra/${campanha.ano}/${campanha.identificacao}`;

      const resposta = await comunicacao.send(url, {
        method: 'GET',
      });

      if ([200, 201, 404].includes(resposta.data.status)) {
        resolve({
          status: 200,
          mensagem: 'Configuração de compra obtida com sucesso',
          dados: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.data.status,
          mensagem:
            resposta.data.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição',
        });
      }
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
