export default [
  {
    titulo: 'Home',
    condicao: () => true,
    icone: 'fas fa-home',
    caminho: 'cashbacklight',
    paginas: [],
  },
  {
    titulo: 'Consumidores',
    // condicao: (configuracoes) => configuracoes.MODULO_CONSUMIDORES,
    condicao: () => true,
    icone: 'ni ni-single-02',
    caminho: 'consumidores',
    paginas: [],
  },
  {
    titulo: 'Vendas',
    // condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS,
    condicao: () => true,
    icone: 'fas fa-file-invoice',
    caminho: 'vendas',
    paginas: [],
  },

  {
    titulo: 'Saldos',
    condicao: () => true,
    icone: 'fas fa-money-bill-wave',
    caminho: 'saldosCashback',
    paginas: [],
  },

  {
    titulo: 'Configurações',
    condicao: () => true,
    icone: 'fas fa-cogs',
    caminho: 'editarCampanhaCashback',
    paginas: [],
  },

  {
    titulo: 'Notificações',
    condicao: () => true,
    icone: 'fas fa-paper-plane text-dark',
    paginas: [
      {
        titulo: () => 'Notificações',
        condicao: () => true,
        icone: 'fas fa-paper-plane text-dark',
        caminho: 'notificacoes',
      },
      {
        titulo: () => 'Grupos',
        condicao: () => true,
        icone: 'fas fa-users',
        caminho: 'grupos-notificacoes',
      },
      {
        titulo: () => 'Eventos',
        condicao: () => true,
        icone: 'fas fa-cog text-dark',
        caminho: 'eventos',
      },
    ],
  },
];
