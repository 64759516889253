import moment from 'moment';

class Validations {
  // Método para validar um CPF, espera uma string sem máscara e retorna true caso seja válido ou false caso seja inválido
  static cpf(cpf) {
    var cpff = cpf.replace(/[^\d]+/g, '');
    if (cpff.length > 11) {
      return Validations.cnpj(cpff);
    }
    if (!cpff) return false;
    var i, add, rev;

    if (cpff == '') return false;
    if (
      cpff.length != 11 ||
      cpff == '00000000000' ||
      cpff == '11111111111' ||
      cpff == '22222222222' ||
      cpff == '33333333333' ||
      cpff == '44444444444' ||
      cpff == '55555555555' ||
      cpff == '66666666666' ||
      cpff == '77777777777' ||
      cpff == '88888888888' ||
      cpff == '99999999999'
    )
      return false;
    add = 0;
    for (i = 0; i < 9; i++) add += parseInt(cpff.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpff.charAt(9))) return false;
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpff.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpff.charAt(10))) return false;
    return true;
  }

  // Método para validar um CNPJ, funciona da mesma forma que o de CPF praticamente
  static cnpj(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj == '') return false;
    if (cnpj.length != 14) return false;
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return false;
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;
    return true;
  }

  // Método para validar o formato de uma data
  static data_YYYY_MM_DD_HH_mm_ss(dataHora) {
    let s, r;
    if (dataHora.length != 19) return false;
    try {
      s = moment(dataHora).format('YYYY-MM-DD HH:mm:ss');
      r = s != 'Invalid date';
    } catch (e) {
      r = false;
    }
    return r;
  }

  //  Método para validar nome...
  static textoAlfaNumerico(texto, tamanhoMinimo = 0, tamanhoMaximo = 250) {
    let re = new RegExp(
      "^[a-zA-ZÀ-ú0-9-_.,@:$&/'` ]{" + tamanhoMinimo.toString() + ',' + tamanhoMaximo.toString() + '}$'
    );
    return re.test(texto);
  }

  static dataHora_A_AntesQue_B(dataHoraA, dataHoraB) {
    let sA, sB, r;
    if (dataHoraA.length != 19 || dataHoraB.length != 19) return true;
    try {
      sA = moment(dataHoraA);
      sB = moment(dataHoraB);
      if ([sA, sB].includes('Invalid date')) {
        r = true;
      } else {
        r = !sA.isSameOrAfter(sB);
      }
    } catch (e) {
      r = true;
    }
    return r;
  }

  static data_A_AntesQue_B(dataA, dataB) {
    let sA, sB, r;
    if (dataA.length != 10 || dataB.length != 10) return true;
    try {
      sA = moment(dataA);
      sB = moment(dataB);
      if ([sA, sB].includes('Invalid date')) {
        r = true;
      } else {
        r = !sA.isSameOrAfter(sB);
      }
    } catch (e) {
      r = true;
    }
    return r;
  }

  // Validando gênero...
  static genero(genero) {
    let r;
    try {
      //let re = new RegExp(/feminino|masculino|outro/);
      //r = re.test(genero);
      let g = genero.toLowerCase();
      r = g === 'masculino' || g === 'feminino' || g === 'outro';
    } catch (e) {
      r = false;
    }
    return r;
  }

  // Validando telefone...
  static telefone(numeroTelefoneComDDD) {
    let r;
    // 99?99999999
    let re = new RegExp(/^[0-9]{10,11}$/);
    try {
      r = re.test(numeroTelefoneComDDD);
    } catch (e) {
      r = false;
    }
    return r;
  }

  //Validando data no formato YYYY-MM-DD
  static data_YYYY_MM_DD(data) {
    let s, r;
    if (data.length != 10) return false;
    try {
      s = moment(data, 'YYYY-MM-DD', true);
      r = s.isValid();
    } catch (e) {
      r = false;
    }
    return r;
  }

  // Método para validação de números/algarismos
  static textoSoNumerero(texto, tamanhoMinimo = 0, tamanhoMaximo = 250) {
    let re = new RegExp('^[0-9]{' + tamanhoMinimo.toString() + ',' + tamanhoMaximo.toString() + '}$');
    return re.test(texto);
  }

  static textoAlfaNumericoPuro(texto, tamanhoMinimo = 0, tamanhoMaximo = 250) {
    let re = new RegExp('^[a-zA-Z0-9]{' + tamanhoMinimo.toString() + ',' + tamanhoMaximo.toString() + '}$');
    return re.test(texto);
  }

  static email(email) {
    let re = new RegExp(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
    );
    return re.test(email);
  }

  static url(url) {
    let re = new RegExp(/^[a-zA-Z0-9-_]+[:./\\]+([a-zA-Z0-9 -_./:=&"'?%+@#$!])+$/g);
    return re.test(url);
  }
  static UF(uf) {
    if (uf.length != 2) return false;
    let re = new RegExp(/AC|AL|AP|AM|BA|CE|DF|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO/i);
    return re.test(uf);
  }
  static cep(cep) {
    if (cep.replace(/-/, '').length > 8) {
      return false;
    }
    let re = new RegExp(/[0-9]{5}-[0-9]{3}/);
    return re.test(cep);
  }
  static textoNomeLogin(texto, tamanhoMinimo = 0, tamanhoMaximo = 250) {
    let re = new RegExp(
      '^[\\u00C0-\\u017FAa-zA-ZÀ-ú0-9-_.,@ ]{' + tamanhoMinimo.toString() + ',' + tamanhoMaximo.toString() + '}$'
    );
    return re.test(texto);
  }

  static textoNomeAssociado(texto, tamanhoMinimo = 0, tamanhoMaximo = 250) {
    let re = new RegExp(
      "^[a-zA-ZÀ-ú0-9-_.,@:$&()/'` ]{" + tamanhoMinimo.toString() + ',' + tamanhoMaximo.toString() + '}$'
    );
    return re.test(texto);
  }

  static verificaNumeroDeCelularValido(numeroCelular) {
    const regexValidador = /^[1-9]{2}[9][6789]\d{7}$/;
    try {
      if (!numeroCelular) return false;

      return regexValidador.test(numeroCelular);
    } catch (err) {
      return false;
    }
  }
}

export default Validations;
