<template>
  <div class="card-cashback d-flex flex-column justify-content-center align-items-center">
    <div class="icon d-flex align-items-center justify-content-center">
      <i :class="icone"></i>
    </div>

    <div class="titulo text-white text-uppercase">{{ label }}</div>
    <div class="valor text-white" v-tooltip="valorTooltip">{{ valor }}</div>
  </div>
</template>

<script>
export default {
  name: 'CardStatsWithIcon',
  props: {
    icone: {
      type: String,
      required: true,
    },
    valor: {
      required: true,
    },
    valorTooltip: {
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-cashback {
  background-color: #045ca4;
  flex: 1;
  height: 180px;
  position: relative;
  border-radius: 8px;
  padding: 0 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);

  > .icon {
    width: 54px;
    height: 54px;
    left: -27px;
    top: calc(50% - 27px);
    background-color: #fff;
    color: #045ca4;
    border-radius: 100%;
    position: absolute;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);

    > i {
      font-size: 1rem;
    }
  }

  > .titulo {
    font-size: 0.8125rem;
  }

  > .valor {
    font-size: 3rem;
    font-weight: 600;
    line-height: 100%;
    margin-top: 4px;
  }
}

@media screen and (max-width: 1260px) {
  .card-cashback {
    padding-left: 30px;
  }
}
@media screen and (max-width: 991px) {
  .card-cashback {
    padding-top: 20px;
    margin-bottom: 15px;

    > .icon {
      left: 16px;
      top: 16px;
    }
  }
}
</style>
