import Vue from 'vue';

// Object initial state
const initialState = () => ({
  logins: [],
  loginsChat: [],
  totalLogins: 0,
  totalPaginas: 0,
  carregando: false,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getLogins(state) {
    return state.logins;
  },
  getLoginsChat(state) {
    return state.loginsChat;
  },
  getTotalizador(state) {
    return state.totalLogins;
  },
  getPaginas(state) {
    return state.totalPaginas;
  },
  getLoader(state) {
    return state.carregando;
  },
  getErro(state) {
    return state.erro;
  },
  getMsgErro(state) {
    return state.msgErro;
  },
};

// Actions
const actions = {
  fetchLogins({ commit }, { filtro, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `login/v1/logins/listar/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          filtro: filtro,
        },
      });
      if ([200, 201].includes(res.status)) {
        commit('setLogins', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setPaginas', res.data.retorno.paginas);
      } else {
        commit('RESET');
      }
      commit('setLoader', false);
      resolve();
    });
  },

  fetchLoginsChat({ commit }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `login/v1/chat`;
      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });
      if ([200, 201].includes(res.status)) {
        commit('setLoginsChat', res.data.retorno);
      } else {
        commit('RESET');
      }
      commit('setLoader', false);
      resolve();
    });
  },

  createLoginAdmin({ commit }, { login }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      if (login.senha != login.confirmarSenha) {
        commit('setLoader', false);
        resolve({ status: 400, mensagem: 'As duas senhas devem ser iguais' });
      }
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = '/login/v1/logins/admin';
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { ...login },
      });
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false);
        resolve({ status: 200, mensagem: 'Login Administrativo cadastrado com sucesso' });
      } else {
        let msgErro = '';
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i];
          }
        } else {
          msgErro = res.data.mensagem;
        }
        commit('setLoader', false);
        resolve({ status: res.status || 500, mensagem: msgErro });
      }
    });
  },
  createLoginAdminImportado({ commit }, { login }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = '/login/v1/logins/admin';
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { ...login },
      });
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false);
        resolve({ status: 200, mensagem: 'Login Administrativo cadastrado com sucesso' });
      } else {
        let msgErro = '';
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i];
          }
        } else {
          msgErro = res.data.mensagem;
        }
        commit('setLoader', false);
        resolve({ status: res.status || 500, mensagem: msgErro });
      }
    });
  },

  redefinirSenha({ commit }, { login }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      if (login.novaSenha != login.confirmarNovaSenha) {
        commit('setLoader', false);
        resolve({ status: 400, mensagem: 'As duas senhas devem ser iguais' });
      }
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = '/login/v1/logins/trocarSenhaAdmin';
      const res = await comunicacao.send(endpoint, {
        method: 'PATCH',
        data: { ...login },
      });
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false);
        resolve({ status: 200, mensagem: 'A senha foi alterada com sucesso' });
      } else {
        let msgErro = '';
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i];
          }
        } else {
          msgErro = res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!';
        }
        commit('setLoader', false);
        resolve({ status: res.status || 500, mensagem: msgErro });
      }
    });
  },

  createLoginNormalImportado({ commit }, { login }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = '/login/v1/logins';
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { ...login },
      });
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false);
        resolve({ status: 200, mensagem: `Login do tipo '${login.tipo}' cadastrado com sucesso` });
      } else {
        let msgErro = '';
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i];
          }
        } else {
          msgErro = res.data.mensagem;
        }
        commit('setLoader', false);
        resolve({ status: res.status || 500, mensagem: msgErro });
      }
    });
  },
  createLoginNormal({ commit }, { login }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      if (login.senha != login.confirmarSenha) {
        commit('setLoader', false);
        resolve({ status: 400, mensagem: 'As duas senhas devem ser iguais' });
      }
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = '/login/v1/logins';
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { ...login },
      });
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false);
        resolve({ status: 200, mensagem: `Login do tipo '${login.tipo}' cadastrado com sucesso` });
      } else {
        let msgErro = '';
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i];
          }
        } else {
          msgErro = res.data.mensagem;
        }
        commit('setLoader', false);
        resolve({ status: res.status || 500, mensagem: msgErro });
      }
    });
  },

  enviarSmsAutenticacaoTemporariaUsuarioSemCadastro({ commit }, { usuario, associadoCnpj, campanhaCashback }) {
    return new Promise(async (sucesso, erro) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = '/login/v1/autenticacaoTemporaria/enviarNotificacaoToken';
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { usuario, associadoCnpj, campanhaCashback },
      });
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false);
        return sucesso({
          status: 200,
          mensagem: res.data.mensagem,
          retorno: res.data.retorno,
        });
      } else {
        commit('setLoader', false);
        return sucesso({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Não foi possível processar a requisição, tente novamente!',
          retorno: null,
        });
      }
    });
  },

  fetchTokenAutenticacaoTemporaria({ commit }, { usuario, codigo, telefoneContato = null }) {
    return new Promise(async (sucesso, erro) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = '/login/v1/autenticacaoTemporaria/obterToken';
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { usuario, codigo, telefoneContato },
      });
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false);
        return sucesso({
          status: 200,
          mensagem: res.data.mensagem,
          retorno: res.data.retorno,
        });
      } else {
        commit('setLoader', false);
        return sucesso({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Não foi possível processar a requisição, tente novamente!',
          retorno: null,
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setLogins(state, data) {
    state.logins = data;
  },
  setLoginsChat(state, data) {
    state.loginsChat = data;
  },
  setTotalizador(state, data) {
    state.totalLogins = data;
  },
  setPaginas(state, data) {
    state.totalPaginas = data;
  },
  setLoader(state, data) {
    state.carregando = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
