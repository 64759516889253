/* eslint-disable no-unused-vars */
import Vue from 'vue';

// Object initial state
const initialState = () => ({
  docFiscaisPendentes: [],
  totalRegistros: 0,
  carregando: false,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getTotalizador(state) {
    return state.totalRegistros;
  },
  getLoader(state) {
    return state.carregando;
  },
  getPaginas(state) {
    return state.totalPaginas
  },
  getDocFiscaisPendentes(state) {
    return state.docFiscaisPendentes;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  fetchDocFiscaisPendentes({ commit }, { campanha, dados, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/documentoFiscal/v1/listarTodosDocumentosFiscaisPendentes/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;

      //PARÂMETROS DE FILTRAGEM AQUI

      const ativo = dados.dataEscaneado.ativo
      let atual = new Date()
      atual =
        atual.getFullYear().toString() +
        "-" +
        (atual.getMonth() + 1).toString() +
        "-" +
        atual.getDate().toString()
      const ate = dados.dataEscaneado.ate
      const fim = ate && ativo ? ate : atual
      const inicioSelecionada = dados.dataEscaneado.de
      const inicio = inicioSelecionada && ativo ? inicioSelecionada : "2000-01-01"
      // const usuario = dados.usuarioAtivo ? dados.usuario.replace(/[^\d]/g, "") : ""

      var dadosEnvio = {
        dataHoraInicio: inicio,
        dataHoraFinal: fim,
      }

      if (dados.status) {
        dadosEnvio.statusDoc = dados.status
      }

      if (dados.usuario) {
        dadosEnvio.usuario = dados.usuario
      }

      let res = await comunicacao.send(endpoint, {
        method: 'GET',
        data: dadosEnvio,
      });

      if ([200, 201].includes(res.status)) {
        commit('setDocFiscaisPendentes', res.data.retorno.retorno);
        // commit('setDocFiscaisPendentes', res.data.retorno.retorno.filter(e => e.status != 'REPROCESSADO' ));
        commit('setTotalizador', res.data.retorno.total);
        commit('setPaginas', res.data.retorno.paginas);
        resolve({
          status: 200,
          mensagem: 'Lista de documentos obtida com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setDocFiscaisPendentes', []);
        resolve({ status: 404, mensagem: 'Documentos fiscais não encontrados!' });
      }

      commit('setLoader', false)
      resolve()
    });
  },

  fetchProximoDocFiscalPendente({ commit }, { campanha, dataHoraLimite }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/documentoFiscal/v1/documentosPendentes/fila/${campanha.ano}/${campanha.identificacao}/${dataHoraLimite}`

      let res = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      if ([200, 201].includes(res.status)) {
        //commit('setDocFiscaisPendentes', res.data.retorno.retorno);
        resolve({
          status: 200,
          mensagem: 'Chave de acesso obtida com sucesso!',
          retorno: res.data.retorno,
        });
      } else if ([404].includes(res.status)) {
        resolve({ status: 404, mensagem: 'Chave de acesso não encontrados!' });
      }

      commit('setLoader', false)
      resolve()
    });
  },

  enviarPE({ commit }, dadosEnviar) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/documentoFiscal/v1/documentosPendentes/enviarListaDocumentosProcessamentoExterno`;
      let res = await comunicacao.send(url, {
        method: 'POST',
        data: {
          campanha: dadosEnviar.campanha,
          chavesDocumentosFiscaisPendentes: dadosEnviar.chavesDocumentosFiscaisPendentes,
        },
      });
      if ([200, 201].includes(res.status)) {
        resolve({
          status: res.data.status,
          mensagem: res.data.mensagem,
          retorno: res.data.retorno,
        });
      } else {
        resolve({
          status: res.data.status,
          mensagem: res.data.mensagem,
          retorno: res.data.retorno,
        });
      }
    });
  },

  alterarStatus({ commit }, dadosEnviar) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/documentoFiscal/v1/documentosFiscaisPendentes/alterarStatusEmLote/`;
      let res = await comunicacao.send(url, {
        method: 'PUT',
        data: {
          campanha: dadosEnviar.campanha,
          chavesAcesso: dadosEnviar.chavesAcesso,
          novoStatus: dadosEnviar.novoStatus,
          historicoTentativa: dadosEnviar.historicoTentativa
        },
      });
      if ([200, 201].includes(res.status)) {
        resolve({
          status: res.data.status,
          mensagem: 'Status atualizados com sucesso!',
          retorno: res.data.retorno,
        });
      } else {
        resolve({
          status: res.data.status,
          mensagem: res.data.mensagem,
          retorno: res.data.retorno,
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setDocFiscaisPendentes(state, data) {
    state.docFiscaisPendentes = data;
  },
  setTotalizador(state, data) {
    state.totalRegistros = data;
  },
  setPaginas(state, data) {
    state.totalPaginas = data
  },
  setLoader(state, data) {
    state.carregando = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};