/* eslint-disable no-unused-vars */
import moment from 'moment';
import Vue from 'vue';

const initialState = () => ({
  carregando: false,
  temComparativo: false,
  faturamentoCashback: {
    valorTotalCompras: 0,
    valorTotalCashbackUtilizado: 0,
  },
  visaoGeralEnvios: [],
  totalMovimentacaoCPF: {
    totalMovimentacaoCPF: 0,
    totalUsuariosComDebitos: 0,
  },
  baseDeCredito: {
    totalGerado: 0,
    liberadoParaUso: 0,
    emBloqueio: 0,
    resgatado: 0,
    expirado: 0,
    totalSaldoPrestesExpirar: 0,
  },
  ultimosLancamentos: [],
  valorTotalEscaneado: {
    valorTotalEscaneado: 0,
    quantidadeVendas: 0,
  },
  mediaValorTotalEscaneado: 0,
});

const state = initialState();

const getters = {
  getCarregandoComparativo: (state) => {
    return state.carregando;
  },
  getTemComparativo: (state) => {
    return state.temComparativo;
  },
  getFaturamentoCashbackComparativo: (state) => {
    return state.faturamentoCashback;
  },
  getVisaoGeralEnviosComparativo: (state) => {
    return state.visaoGeralEnvios;
  },
  getTotalMovimentacaoCPFComparativo: (state) => {
    return state.totalMovimentacaoCPF;
  },
  getBaseDeCreditoComparativo: (state) => {
    return state.baseDeCredito;
  },
  getUltimosLancamentosComparativo: (state) => {
    return state.ultimosLancamentos;
  },
  getValorTotalEscaneadoComparativo: (state) => {
    return state.valorTotalEscaneado;
  },
  getMediaValorTotalEscaneadoComparativo: (state) => {
    return state.mediaValorTotalEscaneado;
  },
};

const actions = {
  async fetchFaturamentoCashbackComparativo({ commit }, { filtros = {} }) {
    try {
      commit('setLoaderComparativo', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/faturamentoCashback?dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}&associadoCnpj=${filtros.associadoCnpj}`;

      const res = await comunicacao.send(url);

      commit('setFaturamentoCashbackComparativo', {
        valorTotalCompras: res.data.retorno.valorTotalCompras,
        valorTotalCashbackUtilizado: res.data.retorno.valorTotalCashbackUtilizado,
      });
    } catch (error) {
      commit('setFaturamentoCashbackComparativo', {
        valorTotalCompras: 0,
        valorTotalCashbackUtilizado: 0,
      });
    } finally {
      commit('setLoaderComparativo', false);
    }
  },
  async fetchTotalMovimentacaoCPFComparativo({ commit }) {
    commit('setLoaderComparativo', true);

    const comunicacao = new Vue.prototype.$comunicacao();
    const url = '/fidelidade/v1/movimentacaoCashback';

    const res = await comunicacao.send(url);

    commit('setTotalMovimentacaoCPFComparativo', {
      totalMovimentacaoCPF: res.data.retorno.totalMovimentacaoCPF,
      totalUsuariosComDebitos: res.data.retorno.totalUsuariosComDebitos,
    });
    commit('setLoaderComparativo', false);
  },
  async fetchBaseDeCreditoComparativo({ commit }, { filtros = {} }) {
    try {
      commit('setLoaderComparativo', true);
      // pegar a data atual mais 7 dias
      const dataPrestesExpirar = moment().add(7, 'days').format('YYYY-MM-DD');

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/baseDeCredito?filtroDataPrestesExpirar=${dataPrestesExpirar}&filtroDataInicial=${filtros.dataInicial}&filtroDataFinal=${filtros.dataFinal}&filtroAssociadoCnpj=${filtros.associadoCnpj}`;

      const {
        data: {
          retorno: {
            total,
            totalSaldoDisponivel,
            totalSaldoExpirado,
            totalSaldoNaoDisponivel,
            totalSaldoUtilizado,
            totalSaldoPrestesExpirar,
          },
        },
      } = await comunicacao.send(url);

      commit('setBaseDeCreditoComparativo', {
        totalGerado: total,
        liberadoParaUso: totalSaldoDisponivel,
        emBloqueio: totalSaldoNaoDisponivel,
        resgatado: totalSaldoUtilizado,
        expirado: totalSaldoExpirado,
        totalSaldoPrestesExpirar,
      });
    } catch (error) {
      commit('setBaseDeCreditoComparativo', {
        totalGerado: 0,
        liberadoParaUso: 0,
        emBloqueio: 0,
        resgatado: 0,
        expirado: 0,
        totalSaldoPrestesExpirar: 0,
      });
    } finally {
      commit('setLoaderComparativo', false);
    }
  },
  async fetchVisaoGeralEnviosComparativo({ commit }, { filtros = {} }) {
    try {
      commit('setLoaderComparativo', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/notificacao/v1/notificacoes/visaoGeralEnvios?dataHoraInicial=${filtros.dataInicial}&dataHoraFinal=${filtros.dataFinal}&associadoCNPJ=${filtros.associadoCnpj}`;

      const res = await comunicacao.send(url);

      commit('setVisaoGeralEnviosComparativo', res.data.retorno);
    } catch (error) {
      commit('setVisaoGeralEnviosComparativo', [
        { metodoEnvio: 'WHATSAPP', quantidade: 0 },
        { metodoEnvio: 'EMAIL', quantidade: 0 },
        { metodoEnvio: 'PUSH', quantidade: 0 },
        { metodoEnvio: 'SMS', quantidade: 0 },
      ]);
    } finally {
      commit('setLoaderComparativo', false);
    }
  },
  async fetchUltimosLancamentosComparativo({ commit }) {
    commit('setLoaderComparativo', true);

    const comunicacao = new Vue.prototype.$comunicacao();
    const url = '/cashback/v1/extratos/10/1';

    const res = await comunicacao.send(url);

    commit('setUltimosLancamentosComparativo', res.data.retorno.retorno);
    commit('setLoaderComparativo', false);
  },
  async fetchValorTotalEscaneadoComparativo({ commit }, { filtros = {} }) {
    try {
      commit('setLoaderComparativo', true);

      // const campanha = Vue.prototype.$campanha.obterCampanhaAtual();

      const comunicacao = new Vue.prototype.$comunicacao();
      // const url = `/documentoFiscal/v1/documentos/valorTotalEscaneado?dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}&emitenteCnpj=${filtros.associadoCnpj}&anoCampanha=${campanha.ano}&identificacaoCampanha=${campanha.identificacao}`;
      const url = `/documentoFiscal/v1/documentos/valorTotalEscaneado?dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}&emitenteCnpj=${filtros.associadoCnpj}`;

      const res = await comunicacao.send(url);

      commit('setValorTotalEscaneadoComparativo', {
        valorTotalEscaneado: res.data.retorno.valorTotalEscaneado,
        quantidadeVendas: res.data.retorno.quantidadeVendas,
      });
      commit(
        'setMediaValorTotalEscaneadoComparativo',
        res.data.retorno.valorTotalEscaneado / res.data.retorno.quantidadeVendas
      );
    } catch (error) {
      commit('setValorTotalEscaneadoComparativo', {
        valorTotalEscaneado: 0,
        quantidadeVendas: 0,
      });
      commit('setMediaValorTotalEscaneadoComparativo', 0);
    } finally {
      commit('setLoaderComparativo', false);
    }
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setLoaderComparativo(state, payload) {
    state.carregando = payload;
  },
  setTemComparativo(state, payload) {
    state.temComparativo = payload;
  },
  setFaturamentoCashbackComparativo(state, payload) {
    state.faturamentoCashback = payload;
  },
  setVisaoGeralEnviosComparativo(state, payload) {
    state.visaoGeralEnvios = payload;
  },
  setTotalMovimentacaoCPFComparativo(state, payload) {
    state.totalMovimentacaoCPF = payload;
  },
  setBaseDeCreditoComparativo(state, payload) {
    state.baseDeCredito = payload;
  },
  setUltimosLancamentosComparativo(state, payload) {
    state.ultimosLancamentos = payload;
  },
  setValorTotalEscaneadoComparativo(state, payload) {
    state.valorTotalEscaneado = payload;
  },
  setMediaValorTotalEscaneadoComparativo(state, payload) {
    state.mediaValorTotalEscaneado = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
