<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }} - Versão: {{ APPLICATION_VERSION }}
        </div>
      </div>
      <div class="col-lg-6">
        <ul class="nav nav-footer justify-content-center justify-content-lg-end">
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link" target="_blank"></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      // eslint-disable-next-line
      APPLICATION_VERSION: APPLICATION_VERSION
    };
  }
};
</script>
<style></style>
