<template>
  <label class="custom-toggle">
    <input type="checkbox" v-model="value" @click="toggle" />
    <span class="custom-toggle-slider rounded-circle"></span>
  </label>
</template>
<script>
export default {
  name: 'base-switch',
  props: {
    value: {
      type: Boolean,
      description: 'Switch value',
    },
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    },
  },
};
</script>
<style></style>
