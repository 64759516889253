import Vue from 'vue';
import axios from 'axios';

const initialState = () => ({
  cashbackConsolidadoAssociadoCampanha: [],
  cashbackConsolidadoCampanha: [],
  campanhaCashbackCopiar: {},
  campanhaCashbackEditar: {},
  colaboradores: [],
  totalColaboradores: 0,
  responsaveis: [],
  campanhasCashback: [],
  campanhasVinculadasCashback: [],
  campanhaIDCashback: [],
  baseDeCredito: {},
  cadastrarCashin: [],
  evolucaoCashback: [],
  metricasCampanhasCashback: {},
  relatoriosCampanhaCashback: [],
  maiorSlugCampanhaCashback: 0,
  extratoCashbackUsuario: [],
  extratosCashback: [],
  engajamentoUsuarios: {
    usuariosEngajados: 0,
  },
  movimentacaoCashbackCPF: {
    totalMovimentacaoCPF: 0,
  },
  carregando: false,
  saldoCashbackUsuario: {},
  extrato: [],
  saldosCashback: [],
  valorCashback: 0,
  rankingSetores: {},
  rankingDepartamentos: {},

  produtosFidelidade: [],
  totalProdutosFidelidade: 0,
  todosProdutosFidelidade: [],
  produtoSelecionado: {},
  totalPaginasEstoque: 0,
  estoqueProdutos: [],
  totalEstoqueProdutos: [],
  todosProdutosDoEstoqueProdutos: [],
  pedidos: [],
  totalPedidos: 0,
  totalPaginasPedidos: 0,

  pedidoAtual: {},
  proximoPedido: null,
  pedidoAnterior: null,
  //Fim Produtos Fidelidade

  faturamentoCashback: {},
});

const state = initialState();

const getters = {
  getCashbackConsolidadoCampanha(state) {
    return state.cashbackConsolidadoCampanha;
  },
  getTotalizadorColaboradores(state) {
    return state.totalColaboradores;
  },
  getCashbackConsolidadoAssociadoCampanha(state) {
    return state.cashbackConsolidadoAssociadoCampanha;
  },
  getCampanhaCashbackCopiar(state) {
    return state.campanhaCashbackCopiar;
  },
  getCampanhaCashbackEditar(state) {
    return state.campanhaCashbackEditar;
  },
  getColaboradores(state) {
    return state.colaboradores;
  },
  getResponsaveis(state) {
    return state.responsaveis;
  },
  getCampanhasCashback(state) {
    return state.campanhasCashback;
  },
  getCampanhasVinculadasCashback(state) {
    return state.campanhasVinculadasCashback;
  },
  getCampanhaIDCashback(state) {
    return state.campanhaIDCashback;
  },
  getBaseDeCredito(state) {
    return state.baseDeCredito;
  },
  getEvolucaoCashback(state) {
    return state.evolucaoCashback;
  },
  getMetricasCampanhasCashback(state) {
    return state.metricasCampanhasCashback;
  },
  getRelatoriosCampanhaCashback(state) {
    return state.relatoriosCampanhaCashback;
  },
  getMaiorSlugCampanhaCashback(state) {
    return state.maiorSlugCampanhaCashback;
  },
  getExtratoCashbackUsuario(state) {
    return state.extratoCashbackUsuario;
  },
  getExtratosCashback(state) {
    return state.extratosCashback;
  },
  getEngajamentoUsuarios(state) {
    return state.engajamentoUsuarios;
  },
  getMovimentacaoCashbackCPF(state) {
    return state.movimentacaoCashbackCPF;
  },
  getSaldoCashbackUsuario(state) {
    return state.saldoCashbackUsuario;
  },
  getSaldosCashback(state) {
    return state.saldosCashback;
  },
  getExtrato(state) {
    return state.extrato;
  },
  getValorCashback(state) {
    return state.valorCashback;
  },
  getLoader(state) {
    return state.carregando;
  },
  getRankingSetores(state) {
    return state.rankingSetores;
  },
  getRankingDepartamentos(state) {
    return state.rankingDepartamentos;
  },
  getProdutosFidelidade(state) {
    return state.produtosFidelidade;
  },
  getTotalProdutosFidelidade(state) {
    return state.totalProdutosFidelidade;
  },
  getTodosProdutosFidelidade(state) {
    return state.todosProdutosFidelidade;
  },

  getSelecionarProduto(state) {
    return state.produtoSelecionado;
  },

  getTotalPaginasEstoque(state) {
    return state.totalPaginasEstoque;
  },

  getEstoqueProdutos(state) {
    return state.estoqueProdutos;
  },
  getTotalEstoqueProdutos(state) {
    return state.totalEstoqueProdutos;
  },
  getPedidos(state) {
    return state.pedidos;
  },
  getTotalPedidos(state) {
    return state.totalPedidos;
  },
  getTotalPaginasPedidos(state) {
    return state.totalPaginasPedidos;
  },

  getPedidoAtual(state) {
    return state.pedidoAtual;
  },

  getProximoPedido(state) {
    return state.proximoPedido;
  },

  getPedidoAnterior(state) {
    return state.pedidoAnterior;
  },
  getTodosProdutosDoEstoqueProdutos(state) {
    return state.todosProdutosDoEstoqueProdutos;
  },
  getFaturamentoCashback(state) {
    return state.faturamentoCashback;
  },
};

const actions = {
  fetchCashbackConsolidadoCampanha({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/cashback/v1/campanhaCashback/consolidado';

      const dataFormatada =
        data && Object.entries(data).length
          ? {
              ...data,
            }
          : {
              listaTipos: 'PRODUTO',
              limiteInvestimento: true,
              status: 'ativa',
            };

      const response = await comunicacao.send(url, {
        method: 'GET',
        data: dataFormatada,
      });
      commit('setLoader', false);

      if (response.status == 200) {
        commit('setCashbackConsolidadoCampanha', response.data.retorno);

        resolve({
          status: 200,
          msg: 'Cashback consolidado por campanha obtido com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setCashbackConsolidadoCampanha', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter cashback consolidado por campanha',
          data: [],
        });
      }
    });
  },

  fetchRelatoriosCampanhaCashback({ commit }, { listaCampanhaID, downloadCompilado }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/relatorios/${listaCampanhaID}`;
      const response = await comunicacao.send(url, {
        method: 'GET',
        data: { downloadCompilado },
      });
      commit('setLoader', false);

      if (response.status == 200) {
        commit('setRelatoriosCampanhaCashback', response.data.retorno);

        resolve({
          status: 200,
          msg: 'Relatórios por campanha obtido com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setRelatoriosCampanhaCashback', {});
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter relatórios por campanha',
          data: {},
        });
      }
    });
  },

  fetchCashbackConsolidadoAssociadoCampanha({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/cashback/v1/associado/consolidado';

      const response = await comunicacao.send(url, { method: 'GET', data });
      commit('setLoader', false);

      if (response.status == 200) {
        commit('setCashbackConsolidadoAssociadoCampanha', response.data.retorno);

        resolve({
          status: 200,
          msg: 'Cashback consolidado por associado obtido com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setCashbackConsolidadoAssociadoCampanha', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter cashback consolidado por associado',
          data: [],
        });
      }
    });
  },

  copiarCampanhaCashback({ commit }, campanha) {
    commit('setCampanhaCashbackCopiar', campanha);
  },

  editarCampanhaCashback({ commit }, campanha) {
    commit('setCampanhaCashbackEditar', campanha);
  },

  fetchColaboradores({ commit }, { paginacao, data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/colaborador/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });
      commit('setLoader', false);
      if (response.status == 200) {
        commit('setColaboradores', response.data.retorno.retorno);
        commit('setTotalizadorColaborador', response.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Colaboradores obtidos com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setColaboradores', []);
        commit('setTotalizadorColaborador', 0);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao listar colaboradores.',
          data: [],
        });
      }
    });
  },

  fetchResponsaveis({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/responsavel/listar`;
      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });
      commit('setLoader', false);
      if (response.status == 200) {
        commit('setResponsaveis', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Responsáveis obtidos com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setResponsaveis', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao listar responsáveis.',
          data: [],
        });
      }
    });
  },

  alterarContrato({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/atualizarContrato`;
      const response = await comunicacao.send(url, {
        method: 'POST',
        data,
      });
      commit('setLoader', false);
      if (response.status == 200) {
        resolve({
          status: 200,
          msg: 'Código de contrato alterado com sucesso!',
          data: response.data.retorno,
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao alterar o código de contrato.',
          data: {},
        });
      }
    });
  },

  publicarCampanhaRascunho({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/`;
      const response = await comunicacao.send(url, {
        method: 'POST',
        data,
      });
      commit('setLoader', false);
      if (response.status == 200 || response.status == 201) {
        resolve({
          status: 200,
          msg: 'Campanha rascunho publicada com sucesso!',
          data: response.data.retorno,
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao publicar campanha rascunho.',
          data: {},
        });
      }
    });
  },

  fetchCampanhasCashback({ commit }, { paginacao, data, skipCommit = false }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/listarCampanhasCashback/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });
      commit('setLoader', false);
      if (response.status == 200) {
        if (!skipCommit) commit('setCampanhasCashback', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Campanhas cashback obtidas com sucesso.',
          data: response.data.retorno,
        });
      } else {
        if (!skipCommit) commit('setCampanhasCashback', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao listar campanhas cashback.',
          data: [],
        });
      }
    });
  },

  fetchCampanhasVinculadasCashback({ commit }, { paginacao, data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/listarCampanhasCashback/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });
      commit('setLoader', false);
      if (response.status == 200) {
        commit('setCampanhasVinculadasCashback', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Campanhas cashback obtidas com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setCampanhasVinculadasCashback', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao listar campanhas cashback.',
          data: [],
        });
      }
    });
  },

  fetchCampanhaIDCashback({ commit }, { paginacao, data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/listarCampanhasCashback/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });
      commit('setLoader', false);
      if (response.status == 200) {
        commit('setCampanhaIDCashback', response.data.retorno.retorno[0]);
        resolve({
          status: 200,
          msg: 'Campanhas cashback obtidas com sucesso.',
          data: response.data.retorno.retorno[0],
        });
      } else {
        commit('setCampanhaIDCashback', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao listar campanhas cashback.',
          data: {},
        });
      }
    });
  },

  alterarStatusCampanhaCashback({ commit }, { campanhaID, statusCampanha, descricao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/atualizarStatus`;
      const response = await comunicacao.send(url, {
        method: 'POST',
        data: {
          campanhaID,
          statusCampanha,
          descricao,
        },
      });

      commit('setLoader', false);

      if (response.status == 200) {
        resolve({
          status: 200,
          msg: 'Status campanha cashback alterado com sucesso.',
          data: response.data.retorno,
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao alterar o status da campanha cashback.',
          data: [],
        });
      }
    });
  },

  publicarCampanhaCashback({ commit }, { campanhaID, statusCampanha, descricao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/`;
      const response = await comunicacao.send(url, {
        method: 'POST',
        data: {
          campanhaID,
          statusCampanha,
          descricao,
        },
      });

      commit('setLoader', false);

      if (response.status == 200) {
        resolve({
          status: 200,
          msg: 'Status campanha cashback alterado com sucesso.',
          data: response.data.retorno,
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao alterar o status da campanha cashback.',
          data: [],
        });
      }
    });
  },

  fetchBaseDeCredito({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/baseDeCredito`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setBaseDeCredito', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Base de crédito obtido com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setBaseDeCredito', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter a base de crédito.',
          data: [],
        });
      }
    });
  },

  fetchEvolucaoCashback({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/extratos/evolucaoLancamentos`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data: { ...data },
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setEvolucaoCashback', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Evolução de cashback obitada com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setEvolucaoCashback', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao evolução de cachback.',
          data: [],
        });
      }
    });
  },

  fetchExtratosCashback({ commit }, { data, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/extratos/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setExtratosCashback', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Lista de lançamentos obtida com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setExtratosCashback', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter lista de lançamentos.',
          data: [],
        });
      }
    });
  },

  fetchExtratoCashbackUsuario({ commit }, { usuario, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/extrato/${usuario}/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setExtratoCashbackUsuario', response.data.retorno.retorno);
        resolve({
          status: 200,
          msg: 'Extrato de cashback obtida com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setExtratoCashbackUsuario', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro na devolução de extrato de cachback.',
          data: [],
        });
      }
    });
  },

  fetchRankingSetores({ commit }, { data, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/rankingSetores/${paginacao.porPagina}/${paginacao.paginaAtual}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setRankingSetores', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Ranking de setores obtido com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setRankingSetores', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro na devolução de ranking de setores.',
          data: [],
        });
      }
    });
  },

  fetchRankingDepartamentos({ commit }, { data, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/rankingDepartamentos/${paginacao.porPagina}/${paginacao.paginaAtual}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setRankingDepartamentos', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Ranking de departamentos obtido com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setRankingDepartamentos', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro na devolução de ranking de departamentos.',
          data: [],
        });
      }
    });
  },

  fetchMetricasCampanhaCashback({ commit }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/metricas`;

      const response = await comunicacao.send(url, {
        method: 'GET',
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setMetricasCampanhasCashback', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Métricas campanhas cashback obitada com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setMetricasCampanhasCashback', {});
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao métricas.',
          data: [],
        });
      }
    });
  },

  fetchMaiorSlugCampanhaCashback({ commit }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/campanhaCashback/maiorSlug`;

      const response = await comunicacao.send(url, {
        method: 'GET',
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setMaiorSlugCampanhaCashback', response.data.retorno.maiorSlug);
        resolve({
          status: 200,
          msg: 'Maior slug entre campanhas cashback obtido.',
          data: response.data.retorno,
        });
      } else {
        commit('setMaiorSlugCampanhaCashback', 0);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter maior slug.',
          data: [],
        });
      }
    });
  },

  fetchEngajamentoUsuarios({ commit }, { dados }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/extratos/engajamentoUsuarios`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data: dados,
      });

      commit('setLoader', false);

      if (response.status === 200) {
        commit('setEngajamentoUsuarios', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Engajamento de usuários obitado com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setEngajamentoUsuarios', { usuariosEngajados: 0 });
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter engajamento de usuários.',
          data: null,
        });
      }
    });
  },

  fetchMovimentacaoCashbackCPF({ commit }, { dados }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/movimentacaoCashback`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data: dados,
      });

      commit('setLoader', false);

      if (response.status === 200) {
        commit('setMovimentacaoCashbackCPF', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Movimentação de cashback por CPF obtida com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setMovimentacaoCashbackCPF', { totalMovimentacaoCPF: 0 });
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter movimentação de cashback por CPF.',
          data: null,
        });
      }
    });
  },

  calculaCashBackCompras({ commit }, { produtos, valorCompra, cnpjAssociado }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/calcularCashBackCompra/`;

      const response = await comunicacao.send(url, {
        method: 'POST',
        data: {
          produtos: produtos,
          valorCompra: valorCompra,
          cnpjAssociado: cnpjAssociado,
        },
      });
      if (response.status == 200) {
        commit('setValorCashback', response.data.retorno.cashbackGerado);
        resolve({
          status: 200,
          msg: 'Cashback calculado com sucesso .',
        });
      } else {
        commit('setValorCashback', 0);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao calcular o cashback.',
          data: [],
        });
      }
    });
  },

  fetchSaldoCashbackUsuario({ commit }, { cpf }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/saldo/${cpf}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
      });

      commit('setSaldoCashbackUsuario', false);

      if (response.status == 200) {
        commit('setSaldoCashbackUsuario', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Saldo obtido com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setSaldoCashbackUsuario', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter o saldo do usuário.',
          data: [],
        });
      }
    });
  },

  fetchSaldosCashback({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/saldo`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setSaldosCashback', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Saldos obtidos com sucesso.',
          data: response.data.retorno,
        });
      } else {
        commit('setSaldosCashback', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter os saldos.',
          data: [],
        });
      }
    });
  },

  fetchRetirarSaldoUsuarioCashout(
    { commit },
    {
      urlCashout,
      configuracaoClienteId,
      usuario,
      token,
      valor,
      associado,
      colaborador = null,
      descricao,
      valorTotalCompra = null,
    }
  ) {
    return new Promise(async (sucesso) => {
      commit('setLoader', true);
      const endpoint = `${urlCashout}/${configuracaoClienteId}/cashback/v1/cashout/${usuario}`;

      const configuracoesRequisicao = {
        headers: {
          Authorization: token,
        },
      };
      const dados = { valor, associado, colaborador, codigoControle: null, descricao, valorTotalCompra };

      let res;
      try {
        res = await axios.post(endpoint, dados, configuracoesRequisicao);
      } catch (error) {
        res = error.response;
      }

      if ([200, 201].includes(res.status)) {
        commit('setLoader', false);
        return sucesso({
          status: 200,
          mensagem: res.data.mensagem,
          retorno: res.data.retorno,
        });
      } else {
        commit('setLoader', false);
        return sucesso({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Não foi possível processar a requisição, tente novamente!',
          retorno: res.data && res.data.retorno ? res.data.retorno : null,
        });
      }
    });
  },

  fetchPedidosProdutos({ commit }, { paginacao, filtro }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `cashback/v1/pedido/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data: { ...filtro },
      });

      if ([200, 201].includes(response.status)) {
        commit('setPedidos', response.data.retorno.retorno);
        commit('setTotalPedidos', response.data.retorno.total);
        commit('setTotalPaginasPedidos', response.data.retorno.paginas);
        resolve({
          status: response.status,
          msg: 'Lista de pedidos obtida com sucesso!',
          data: response.data.retorno,
        });
      } else {
        commit('setPedidos', []);
        commit('setTotalPedidos', 0);
        resolve({ status: response.status || 500, msg: 'Erro desconhecido ao listar os pedidos dos produtos.' });
      }
    });
  },

  alterarStatusPedido({ commit }, { data, id }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `cashback/v1/pedido/atualizarStatus/${id}`;
      const response = await comunicacao.send(url, {
        method: 'POST',
        data,
      });
      commit('setLoader', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: response.status,
          msg: 'Status do pedido alterado com sucesso!',
          data: response.data.retorno,
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao alterar status do pedido.',
          data: {},
        });
      }
    });
  },

  cancelarItemPedido({ commit }, { pedidoID, indiceItem, motivoCancelamento }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `fidelidade/v1/pedido/${pedidoID}/item/${indiceItem}/cancelar`;

      const response = await comunicacao.send(url, {
        method: 'PATCH',
        data: { motivoCancelamento },
      });

      commit('setLoader', false);

      if ([200].includes(response.status)) {
        return resolve({
          status: response.status,
          msg: response.data.mensagem,
          data: response.data.retorno,
        });
      } else {
        return resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao cancelar item do pedido.',
          data: {},
        });
      }
    });
  },

  fetchEstoqueProdutos({ commit }, { paginacao, filtro, estoque }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `cashback/v1/estoque/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data: { ...filtro, ...estoque },
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setEstoqueProdutos', response.data.retorno.retorno);
        commit('setTotalEstoqueProdutos', response.data.retorno.total);
        commit('setTotalPaginasEstoque', response.data.retorno.paginas);
        resolve({
          status: 200,
          msg: 'Estoque de produtos obtido com sucesso!',
          data: response.data.retorno,
        });
      } else {
        commit('setEstoqueProdutos', []);
        commit('setTotalEstoqueProdutos', 0);
        resolve({ status: response.status || 500, msg: 'Erro desconhecido ao listar estoque de produtos' });
      }
    });
  },

  fetchEstoqueTodosProdutos({ commit }, { paginacao, filtro, estoque }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `cashback/v1/estoque/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data: { ...filtro, ...estoque },
      });

      if (response.status == 200) {
        commit('setTodosProdutosDoEstoqueProdutos', response.data.retorno.retorno);
        resolve({
          status: 200,
          msg: 'Estoque de produtos obtido com sucesso!',
          data: response.data.retorno,
        });
      } else {
        commit('setTodosProdutosDoEstoqueProdutos', []);
        resolve({ status: response.status || 500, msg: 'Erro desconhecido ao listar estoque de produtos' });
      }
    });
  },

  cadastrarEstoqueProdutos({ commit }, { estoque, codigo }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `cashback/v1/estoque/${codigo}`;
      const response = await comunicacao.send(url, {
        method: 'POST',
        data: {
          quantidade: estoque.quantidade,
          associadoCNPJ: estoque.cnpj,
        },
      });
      commit('setLoader', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: response.status,
          msg: 'Estoque cadastrado com sucesso!',
          data: response.data.retorno,
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido ao cadastrar estoque.',
          data: {},
        });
      }
    });
  },

  //INICIO PRODUTOS FIDELIDADE
  fetchProdutosFidelidade({ commit }, { paginacao, filtros }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `cashback/v1/produto/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const res = await comunicacao.send(endpoint, { method: 'GET', data: filtros });

      if ([200, 201].includes(res.status)) {
        commit('setProdutosFidelidade', res.data.retorno.retorno);
        commit('setTotalProdutosFidelidade', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setProdutosFidelidade', []);
        commit('setTotalProdutosFidelidade', 0);
        resolve({ status: 404, msg: 'Não foi encontrado nenhum produto!' });
      } else {
        commit('setProdutosFidelidade', []);
        commit('setTotalProdutosFidelidade', 0);
        resolve({ status: res.status || 500, msg: 'Erro desconhecido ao listar produtos' });
      }
    });
  },

  listarTodosProdutos({ commit }, { paginacao, filtros }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `cashback/v1/produto/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const res = await comunicacao.send(endpoint, { method: 'GET', data: filtros });

      if ([200, 201].includes(res.status)) {
        commit('setTodosProdutosFidelidade', res.data.retorno.retorno);
        commit('setTotalProdutosFidelidade', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
          total: res.data.retorno.total,
        });
      } else {
        commit('setTodosProdutosFidelidade', []);
        commit('setTotalProdutosFidelidade', 0);
        resolve({ status: res.status || 500, msg: 'Erro desconhecido ao listar produtos' });
      }
    });
  },

  selecionarProduto({ commit }, { produto }) {
    if (produto && Object.entries(produto).length) commit('setSelecionarProduto', produto);
  },

  cadastrarProdutoFidelidade({ commit }, { produto }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `cashback/v1/produto`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: { ...produto },
      });

      commit('setLoader', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Produto cadastrado com sucesso!' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  atualizarProdutoFidelidade({ commit }, { produto, codigo }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `cashback/v1/produto/${codigo}`;

      const resposta = await comunicacao.send(url, {
        method: 'PUT',
        data: { ...produto },
      });

      commit('setLoader', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Produto atualizado com sucesso!' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  //FIM PRODUTOS FIDELIDADE

  obterFaturamentoCashback({ commit }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cashback/v1/faturamentoCashback`;

      const response = await comunicacao.send(url, {
        method: 'GET',
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setFaturamentoCashback', response.data.retorno);
        resolve({
          status: 200,
          msg: response.data.mensagem,
          data: response.data.retorno,
        });
      } else {
        commit('setFaturamentoCashback', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter o faturamento cashback.',
          data: [],
        });
      }
    });
  },

  realizarCashin({ commit }, { dados }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `cashback/v1/cashin/${dados.usuario}`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: { ...dados },
      });

      commit('setLoader', false);
      if (resposta.data.status == 200) {
        resolve({ status: 200, mensagem: 'Cashin realizado com sucesso!' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  cancelarCashout({ commit }, { dados }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `fidelidade/v1/cashout/cancelamento`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: { ...dados },
      });

      if (resposta.data.status === 200) {
        resolve({ status: 200, mensagem: 'Cancelamento de cashout realizado com sucesso' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.status.mensagem });
      }
    });
  },

  calculaValorMaximoCashout({ commit }, { dados }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `fidelidade/v1/calculaValorMaximoCashout`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: { ...dados },
      });

      if (resposta.data.status == 200) {
        resolve({
          status: 200,
          mensagem: 'Valor calculado com sucesso!',
          valorMaximo: resposta.data.retorno.valorMaximo,
        });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  fetchExtratoSimplificado({ commit }, { paginacao, data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/fidelidade/v1/extratos/simplificados/${paginacao.porPagina}/${paginacao.paginaAtual}`;

      const response = await comunicacao.send(url, {
        method: 'GET',
        data,
      });

      commit('setLoader', false);

      if (response.status == 200) {
        commit('setExtrato', response.data.retorno.retorno);
        resolve({
          status: 200,
          msg: 'Extrato simplificado obtido com sucesso',
          data: response.data.retorno,
        });
      } else {
        commit('setExtrato', []);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter os extratos simplificados.',
          data: [],
        });
      }
    });
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setCashbackConsolidadoCampanha(state, data) {
    state.cashbackConsolidadoCampanha = data;
  },
  setCashbackConsolidadoAssociadoCampanha(state, data) {
    state.cashbackConsolidadoAssociadoCampanha = data;
  },
  setTotalizadorColaborador(state, data) {
    state.totalColaboradores = data;
  },
  setCampanhaCashbackCopiar(state, data) {
    state.campanhaCashbackCopiar = data;
  },
  setCampanhaCashbackEditar(state, data) {
    state.campanhaCashbackEditar = data;
  },
  setColaboradores(state, data) {
    state.colaboradores = data;
  },
  setResponsaveis(state, data) {
    state.responsaveis = data;
  },
  setCampanhasCashback(state, data) {
    state.campanhasCashback = data;
  },
  setCampanhasVinculadasCashback(state, data) {
    state.campanhasVinculadasCashback = data;
  },
  setCampanhaIDCashback(state, data) {
    state.campanhaIDCashback = data;
  },
  setBaseDeCredito(state, data) {
    state.baseDeCredito = data;
  },
  setEvolucaoCashback(state, data) {
    state.evolucaoCashback = data;
  },
  setMetricasCampanhasCashback(state, data) {
    state.metricasCampanhasCashback = data;
  },
  setRelatoriosCampanhaCashback(state, data) {
    state.relatoriosCampanhaCashback = data;
  },
  setMaiorSlugCampanhaCashback(state, data) {
    state.maiorSlugCampanhaCashback = data;
  },
  setExtratoCashbackUsuario(state, data) {
    state.extratoCashbackUsuario = data;
  },
  setExtratosCashback(state, data) {
    state.extratosCashback = data;
  },
  setEngajamentoUsuarios(state, data) {
    state.engajamentoUsuarios = data;
  },
  setMovimentacaoCashbackCPF(state, data) {
    state.movimentacaoCashbackCPF = data;
  },
  setSaldoCashbackUsuario(state, data) {
    state.saldoCashbackUsuario = data;
  },
  setExtrato(state, data) {
    state.extrato = data;
  },
  setSaldosCashback(state, data) {
    state.saldosCashback = data;
  },
  setValorCashback(state, data) {
    state.valorCashback = data;
  },
  setLoader(state, data) {
    state.carregando = data;
  },
  setRankingSetores(state, data) {
    state.rankingSetores = data;
  },
  setRankingDepartamentos(state, data) {
    state.rankingDepartamentos = data;
  },
  setProdutosFidelidade(state, data) {
    state.produtosFidelidade = data;
  },
  setTotalProdutosFidelidade(state, data) {
    state.totalProdutosFidelidade = data;
  },
  setTodosProdutosFidelidade(state, data) {
    state.todosProdutosFidelidade = data;
  },
  setSelecionarProduto(state, data) {
    state.produtoSelecionado = data;
  },

  setTotalPaginasEstoque(state, data) {
    state.totalPaginasEstoque = data;
  },

  setEstoqueProdutos(state, data) {
    state.estoqueProdutos = data;
  },
  setPedidos(state, data) {
    state.pedidos = data;
  },
  setTotalPedidos(state, data) {
    state.totalPedidos = data;
  },
  setTotalPaginasPedidos(state, data) {
    state.totalPaginasPedidos = data;
  },
  setPedidoAtual(state, data) {
    let indice = -1;
    state.pedidoAtual = state.pedidos.find((e) => {
      indice++;
      return e.id == data;
    });
    state.proximoPedido = state.pedidos[indice + 1] ? state.pedidos[indice + 1] : null;
    state.pedidoAnterior = state.pedidos[indice - 1] ? state.pedidos[indice - 1] : null;
  },
  setAtualizarDadosPedidoAtual(state, { dados, pedidoID }) {
    const indicePedido = state.pedidos.findIndex((pedido) => pedido.id === pedidoID);

    state.pedidoAtual = { ...state.pedidoAtual, ...dados };
    state.pedidos[indicePedido] = state.pedidoAtual;
  },
  setTotalEstoqueProdutos(state, data) {
    state.totalEstoqueProdutos = data;
  },
  setTodosProdutosDoEstoqueProdutos(state, data) {
    state.todosProdutosDoEstoqueProdutos = data;
  },
  setFaturamentoCashback(state, data) {
    state.faturamentoCashback = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
