import md5 from 'md5';

const senhaColaborador = 'ffe127535009e508305fec3c0d418eeb'
const chaveAcesso = 'a9a892cb3024af2eecd0ac6e3de102af'

/**
 * Classe para salvar e recuperar a chave de acesso.
 */
class Colaborador {
  /**
   * Construtor vazio.
   */
  constructor() { }
  /**
   * Grava o acesso no LocalStorage da aplicação.
   * @param {object} servico 
   */
  static gravarAcesso(senha) {
    if (md5(md5(senha)) === senhaColaborador) {
      localStorage.setItem(chaveAcesso, md5(md5(senha)));
    } else {
      this.limparAcesso();
    }
  }

  /**
   * Retorna se o acesso está liberado.
   */
  static obterAcessoColaborador() {
    return localStorage.getItem(chaveAcesso) && localStorage.getItem(chaveAcesso) == senhaColaborador;
  }

  /**
   * Remover o acesso do LocalStorage da aplicação.
   */
  static limparAcesso() {
    if (localStorage.getItem(chaveAcesso)) {
      localStorage.removeItem(chaveAcesso);
      return true;
    } else {
      return false;
    }
  }
}

export default Colaborador;