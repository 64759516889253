import Vue from 'vue'

// Object initial state
const initialState = () => ({
  categorias: [],
  totalCategorias: 0,
  carregando: false,
  erro: null,
  totalPaginas: 0,
  categoria: {}
})

// State object
const state = initialState()

// Getter object
const getters = {
  getCategoria(state) {
    return state.categoria
  },

  getCategorias(state) {
    return state.categorias
  },

  getTotalizador(state) {
    return state.totalCategorias
  },

  getPaginas(state) {
    return state.totalPaginas;
  },

  getLoader(state) {
    return state.carregando
  },

  getErro(state) {
    return state.erro
  },
}

// Actions
const actions = {
  carregarCategorias({ commit }, { paginacao, filtro = {} }) {
    return new Promise(async resolve => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()

      let endpoint = `/produto/v1/categorias/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`

      if (Object.entries(filtro).length > 0) {
        if (filtro.filtroCodigo.ativo && filtro.filtroCodigo.conteudo) {
          endpoint += `?codigo=${filtro.filtroCodigo.conteudo}`
        }
        if (filtro.filtroCodigo.ativo && filtro.filtroCodigo.conteudo && filtro.filtroNome.ativo && filtro.filtroNome.conteudo) {
          endpoint += `&nome=${filtro.filtroNome.conteudo}`
        } else if (filtro.filtroNome.ativo && filtro.filtroNome.conteudo) {
          endpoint += `?nome=${filtro.filtroNome.conteudo}`
        }
      }

      const res = await comunicacao.send(endpoint, {
        method: 'get'
      })

      if ([200, 201].includes(res.status)) {
        commit('setCategorias', res.data.retorno.retorno)
        commit('setTotalizador', res.data.retorno.total)
        commit('setPaginas', res.data.retorno.paginas);
        commit('setErro', null)
      } else if ([404].includes(res.status)) {
        commit('setCategorias', [])
        commit('setTotalizador', 0)
        commit('setErro', null)
      } else {
        commit('setTotalizador', 0)
        commit(
          'setErro',
          res.data.mensagem ||
          'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        )
      }
      commit('setLoader', false)
      resolve()
    })
  },
  cadastrarCategoria({ commit }, { codigo, nome, imagem }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = '/produto/v1/categorias'
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          codigo,
          nome,
          imagem
        },
      })
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false)
        resolve({ status: 201, mensagem: 'Categoria cadastrada com sucesso' })
      } else {
        let msgErro = ''
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i]
          }
        } else {
          msgErro = res.data.mensagem
        }
        commit('setLoader', false)
        resolve({ status: res.status || 500, mensagem: msgErro })
      }
    })
  },
  alterarCategoria({ commit }, { codigo, nome, imagem }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = `produto/v1/categorias/${codigo}`
      const res = await comunicacao.send(endpoint, {
        method: 'PATCH',
        data: {
          nome,
          imagem
        },
      })
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false)
        resolve({ status: 200, mensagem: 'Categoria alterada com sucesso' })
      } else {
        let msgErro = ''
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i]
          }
        } else {
          msgErro = res.data.mensagem
        }
        commit('setLoader', false)
        resolve({ status: res.status || 500, mensagem: msgErro })
      }
    })
  },
  excluirCategoria({ commit }, { codigo }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `produto/v1/categorias/${codigo}`;

      const resposta = await comunicacao.send(endpoint, {
        method: 'DELETE'
      });

      if ([200, 204].includes(resposta.data.status)) {
        commit('setLoader', false)
        resolve({ status: 200, mensagem: 'Categoria excluída com sucesso' });
      } else {
        commit('setLoader', false)
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  setCategorias(state, data) {
    state.categorias = data
  },

  setCategoria(state, data) {
    state.categoria = data
  },

  setTotalizador(state, data) {
    state.totalCategorias = data
  },

  setLoader(state, data) {
    state.carregando = data
  },

  setPaginas(state, data) {
    state.totalPaginas = data;
  },

  setErro(state, data) {
    state.erro = data
  },
}

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
