import Vue from 'vue';

// Object initial state
const initialState = () => ({
  carregando: false,
  integracoes: [],
});

// State object
const state = initialState();

// Getter object
const getters = {
  getIntegracoes(state) {
    return state.integracoes;
  },
};

// Actions
const actions = {
  listarIntegracoes({ commit }, { filtros = {} }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `notificacao/v1/integracoes`;

      const res = await comunicacao.send(endpoint, { method: 'GET', data: filtros });
      if ([200, 201].includes(res.status)) {
        commit('setIntegracoes', res.data.retorno);
        resolve({
          status: 200,
          msg: 'Integrações listadas!',
          integracoes: res.data.retorno,
        });
      } else {
        commit('setIntegracoes', []);
        resolve({
          status: res.status || 500,
          msg: res.data.mensagem || 'Erro desconhecido ao listar integrações. Tente novamente.',
        });
      }
    });
  },

  desabilitarIntegracao({ commit }, { plataforma }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `notificacao/v1/integracoes/desabilitar`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          plataforma,
        },
      });

      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Integração desabilitada com sucesso!' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  habilitarIntegracaoRDStation({ commit }, { code }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `notificacao/v1/integracoes/rdstation/autenticar`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          code,
        },
      });

      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Integração habilitada com sucesso!' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setIntegracoes(state, data) {
    state.integracoes = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
