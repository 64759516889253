const initialState = () => ({
  tipoDisparo: '',
  modalEstaAberto: false,
  // o primeiro modal é o MetodosEnvios -> actualModal: 'MetodosEnvios',
  actualModal: 'MetodosEnvios',
  cardSelecionado: '',
  mensagemDisparo: '',
  grupos: {},
  grupoSelecionado: '',
  assunto: '',
  gatilhos: {},
  titulo: '',
  mensagem: '',
  dataAgendamento: '',
  gatilhoSelecionado: '',
  dialogActive: false,
  diasParaExpirar: 0,
  horaNotificacao: 0,
  tags: '',
  objetoWhatsapp: {},
  modeloEmail: '',
  erros: [],
  buscarNoBanco: true,
  estadoGatilho: '',
});

const state = initialState();

const getters = {
  getTipoDisparo: (state) => {
    return state.tipoDisparo;
  },
  getModalEstaAberto: (state) => {
    return state.modalEstaAberto;
  },
  getActualModal: (state) => {
    // lembrar sempre de adicionar esse modal no arquivo: src/views/CashbackLight/index.vue
    // e fazer a lógica assim como nos outros
    return state.actualModal;
  },
  getCardSelecionado: (state) => {
    return state.cardSelecionado;
  },
  getMensagemDisparo: (state) => {
    return state.mensagemDisparo;
  },
  getGrupos: (state) => {
    return state.grupos;
  },
  getGatilhos: (state) => {
    return state.gatilhos;
  },
  getAssunto: (state) => {
    return state.assunto;
  },
  getMensagem: (state) => {
    return state.assunto;
  },
  getDataAgendamento: (state) => {
    return state.dataAgendamento;
  },
  getGrupoSelecionado: (state) => {
    return state.grupoSelecionado;
  },
  getGatilhoSelecionado: (state) => {
    return state.gatilhoSelecionado;
  },
  getDialogActive: (state) => {
    return state.dialogActive;
  },
  getDiasParaExpirar: (state) => {
    return state.diasParaExpirar;
  },
  getHoraNotificacao: (state) => {
    return state.horaNotificacao;
  },
  getTags: (state) => {
    return state.tags;
  },
  getObjetoWhatsapp: (state) => {
    return state.objetoWhatsapp;
  },
  getModeloEmail: (state) => {
    return state.modeloEmail;
  },
  getErros: (state) => {
    return state.erros;
  },
  getBuscarNoBanco: (state) => {
    return state.buscarNoBanco;
  },
  getEstadoGatilho: (state) => {
    return state.estadoGatilho;
  },
};

const actions = {
  setTipoDisparo({ commit }, payload) {
    commit('setTipoDisparo', payload);
  },
  setModalEstaAberto({ commit }, payload) {
    commit('setModalEstaAberto', payload);
  },
  setActualModal({ commit }, payload) {
    commit('setActualModal', payload);
  },
  setCardSelecionado({ commit }, payload) {
    commit('setCardSelecionado', payload);
  },
  setMensagemDisparo({ commit }, payload) {
    commit('setMensagemDisparo', payload);
  },
  setGrupos({ commit }, payload) {
    commit('setGrupos', payload);
  },
  setGrupoSelecionado({ commit }, payload) {
    commit('setGrupoSelecionado', payload);
  },
  setGatilhos({ commit }, payload) {
    commit('setGatilhos', payload);
  },
  setAssunto({ commit }, payload) {
    commit('setAssunto', payload);
  },
  setDataAgendamento({ commit }, payload) {
    commit('setDataAgendamento', payload);
  },
  setGatilhoSelecionado({ commit }, payload) {
    commit('setGatilhoSelecionado', payload);
  },
  setDialogActive({ commit }, payload) {
    commit('setDialogActive', payload);
  },
  setDiasParaExpirar({ commit }, payload) {
    commit('setDiasParaExpirar', payload);
  },
  setHoraNotificacao({ commit }, payload) {
    commit('setHoraNotificacao', payload);
  },
  setTags({ commit }, payload) {
    commit('setTags', payload);
  },
  setObjetoWhatsapp({ commit }, payload) {
    commit('setObjetoWhatsapp', payload);
  },
  setModeloEmail({ commit }, payload) {
    commit('setModeloEmail', payload);
  },
  setErros({ commit }, payload) {
    commit('setErros', payload);
  },
  setBuscarNoBanco({ commit }, payload) {
    commit('setBuscarNoBanco', payload);
  },
  setEstadoGatilho({ commit }, payload) {
    commit('setEstadoGatilho', payload);
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setTipoDisparo(state, payload) {
    state.tipoDisparo = payload;
  },
  setModalEstaAberto(state, payload) {
    state.modalEstaAberto = payload;
  },
  setActualModal(state, payload) {
    state.actualModal = payload;
  },
  setCardSelecionado(state, payload) {
    state.cardSelecionado = payload;
  },
  setMensagemDisparo(state, payload) {
    state.mensagemDisparo = payload;
  },
  setGrupos(state, payload) {
    state.grupos = payload;
  },
  setGrupoSelecionado(state, payload) {
    state.grupoSelecionado = payload;
  },
  setGatilhos(state, payload) {
    state.gatilhos = payload;
  },
  setAssunto(state, payload) {
    state.assunto = payload;
  },
  setDataAgendamento(state, payload) {
    state.dataAgendamento = payload;
  },
  setGatilhoSelecionado(state, payload) {
    state.gatilhoSelecionado = payload;
  },
  setDialogActive(state, payload) {
    state.dialogActive = payload;
  },
  setDiasParaExpirar(state, payload) {
    state.diasParaExpirar = payload;
  },
  setHoraNotificacao(state, payload) {
    state.horaNotificacao = payload;
  },
  setTags(state, payload) {
    state.tags = payload;
  },
  setObjetoWhatsapp(state, payload) {
    state.objetoWhatsapp = payload;
  },
  setModeloEmail(state, payload) {
    state.modeloEmail = payload;
  },
  setErros(state, payload) {
    state.erros = payload;
  },
  setBuscarNoBanco(state, payload) {
    state.buscarNoBanco = payload;
  },
  setEstadoGatilho(state, payload) {
    state.estadoGatilho = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
