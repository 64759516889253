import Vue from 'vue';

// Object initial state
const initialState = () => ({
  carregando: false,
  raspadinhas: [],
  listagemRaspadinhas: [],
  raspadinhasUsuario: [],
  totalRaspadinha: 0,
  totalListagemRaspadinha: 0,
  erro: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getRaspadinhas(state) {
    return state.raspadinhas;
  },
  getListagemRaspadinhas(state) {
    return state.listagemRaspadinhas;
  },
  getRaspadinhasUsuario(state) {
    return state.raspadinhasUsuario;
  },
  getTotalizador(state) {
    return state.totalRaspadinha;
  },
  getTotalizadorListagemRaspadinha(state) {
    return state.totalListagemRaspadinha;
  },
  getLoader(state) {
    return state.carregando;
  },
  getErro(state) {
    return state.erro;
  },
};

// Actions
const actions = {
  fetchRaspadinhas({ commit }, { campanha, filtro, paginacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: filtro || {},
      });
      if ([200, 201].includes(res.status)) {
        commit('setRaspadinhas', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setRaspadinhas', []);
        commit('setTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },
  fetchListagemRaspadinhas({ commit }, { campanha, filtro, paginacao, ordenacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          ...(filtro || {}),
          ...(ordenacao || {}),
        },
      });
      if ([200, 201].includes(res.status)) {
        commit('setListagemRaspadinhas', res.data.retorno.retorno);
        commit('setListagemRaspadinhaTotalizador', res.data.retorno.total);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setListagemRaspadinhas', []);
        commit('setListagemRaspadinhaTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },
  fetchRaspadinhasPorUsuario({ commit }, { campanha, usuario }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${usuario}`;
      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });
      if ([200, 201].includes(res.status)) {
        commit('setRaspadinhasUsuario', res.data.retorno);
        commit('setErro', null);
        resolve({
          status: 200,
          mensagem: 'Lista de cupons do usuário recuperada com sucesso',
          retorno: res.data,
        });
      } else if ([404].includes(res.status)) {
        commit('setRaspadinhasUsuario', []);
        commit('setErro', null);
      } else {
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },
  cancelarPremio({ commit }, { identificacao, justificativa }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/cancelarPremio/${identificacao}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { justificativa },
      });
      if ([200, 201].includes(res.status)) {
        commit('switchCarregando', false);
        commit('setErro', null);
        resolve({
          status: 200,
          mensagem: 'O Prêmio dessa raspadinha foi marcado como cancelado com sucesso!',
        });
      } else {
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
        commit('switchCarregando', false);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
        });
      }
    });
  },
  entregarPremio({ commit }, { identificacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/${identificacao}`;
      const res = await comunicacao.send(endpoint);
      if ([200, 201].includes(res.status)) {
        commit('switchCarregando', false);
        commit('setErro', null);
        resolve({
          status: 200,
          data: res.data,
          mensagem: 'O Prêmio dessa raspadinha foi marcado como entregue com sucesso!',
        });
      } else {
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
        commit('switchCarregando', false);
        resolve({
          status: res.status || 500,
          data: res.data,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
        });
      }
    });
  },
  entregarPremioPicPay({ commit }, { identificacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/picPay/${identificacao}`;
      const res = await comunicacao.send(endpoint);
      if ([200, 201].includes(res.status)) {
        commit('switchCarregando', false);
        commit('setErro', null);
        resolve({
          status: 200,
          data: res.data,
          mensagem: 'O Prêmio dessa raspadinha foi marcado como entregue e transferido com sucesso!',
        });
      } else {
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
        commit('switchCarregando', false);
        resolve({
          status: res.status || 500,
          data: res.data,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setRaspadinhas(state, data) {
    state.raspadinhas = data;
  },
  setListagemRaspadinhas(state, data) {
    state.listagemRaspadinhas = data;
  },
  setRaspadinhasUsuario(state, data) {
    state.raspadinhasUsuario = data;
  },
  setTotalizador(state, data) {
    state.totalRaspadinha = data;
  },
  setListagemRaspadinhaTotalizador(state, data) {
    state.totalListagemRaspadinha = data;
  },
  switchCarregando(state, data) {
    state.carregando = data;
  },
  setErro(state, data) {
    state.erro = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
