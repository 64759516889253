<template>
  <div class="base-info-card d-flex align-items-center justify-content-center flex-column">
    <div class="icone d-flex align-items-center justify-content-center">
      <i :class="icone"></i>
    </div>

    <h1 class="principal m-0" v-if="tooltip" v-tooltip="tooltip">{{ principal }}</h1>
    <h1 class="principal m-0" v-else>{{ principal }}</h1>
    <p class="descricao text-uppercase m-0">{{ descricao }}</p>
  </div>
</template>

<script>
export default {
  name: 'BaseCardInformacao',

  props: {
    icone: {
      type: String,
      required: true,
    },
    principal: {
      type: String,
      required: true,
    },
    descricao: {
      type: String,
      required: true,
    },
    tooltip: {
      required: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.base-info-card {
  width: 100%;
  border-radius: 0.5rem;
  background-color: #055ba3;
  color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;

  > .icone {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #0092d8;
    font-size: 1.5rem;
    margin-top: -30px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }

  > .principal {
    font-size: 2rem;
    color: #fff;
    line-height: 100%;
  }

  > .descricao {
    font-size: 1rem;
  }
}
</style>