// Dependencies
import Vue from 'vue'

// Object initial state
const initialState = () => ({
  raspadinhasStats: {
    total: 0,
    naoRaspadas: 0,
    naoPremiadas: 0,
    premiadas: 0
  },
  documentosFiscais: 0,
  documentosFiscaisDia: 0
})

// State object
const state = initialState()

// Getter object
const getters = {
  estatisticaDiaria(state) {
    return state.raspadinhasStats;
  },
  documentosFiscais(state) {
    return state.documentosFiscais;
  },
  documentosFiscaisDiaria(state) {
    return state.documentosFiscaisDia;
  }
}

// Actions
const actions = {
  FETCH_RASPADINHAS_STATS({
    commit
  }, campanha) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao()
      const url = `/raspadinhaAlternativa/v1/totalizador/${campanha.ano}/${campanha.identificacao}`
      let res = await comunicacao.send(url, { method: 'POST', data: { /*data: '2019-12-16'*/ } })
      if ([200, 201].includes(res.status)) {
        commit('SET_STATS', res.data.retorno)
      } else {
        commit('SET_STATS', {
          total: 0,
          naoRaspadas: 0,
          naoPremiadas: 0,
          premiadas: 0
        })
      }
      resolve()
    })
  },
  FETCH_DOCUMENTOSFISCAIS_STATS({ commit }, { campanha, dia }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao()
      const url = `/documentoFiscal/v1/documentos/totalizador/${campanha.ano}/${campanha.identificacao}`
      let res = await comunicacao.send(url, {
        method: 'POST',
        data: {
          data: dia
        }
      })
      if ([200, 201].includes(res.status)) {
        commit('SET_DOCUMENTOSFISCAIS_STATS', res.data.retorno)
      } else {
        commit('SET_DOCUMENTOSFISCAIS_STATS', 0)
      }
      resolve()
    })
  }

}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
  SET_STATS(state, data) {
    state.raspadinhasStats = data
  },
  SET_DOCUMENTOSFISCAIS_STATS(state, data) {
    state.documentosFiscais = data
  }
}

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
