<template>
  <div class="notifications">
    <slide-y-up-transition :duration="transitionDuration" group mode="out-in">
      <notification
        v-for="notification in notifications"
        v-bind="notification"
        :clickHandler="notification.onClick"
        :key="notification.timestamp.getTime()"
        @close="removeNotification"
        style="cursor: pointer"
      >
      </notification>
    </slide-y-up-transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Notification from './Notification.vue';
import { SlideYUpTransition } from 'vue2-transitions';
export default {
  components: {
    SlideYUpTransition,
    Notification,
  },
  props: {
    transitionDuration: {
      type: Number,
      default: 200,
    },
    overlap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      configuracoes: null,
      notifications: this.$notifications.state,
      chat: null,
      campanha: null,
      som: new Audio('/message.mp3'),
    };
  },
  computed: {
    ...mapGetters({
      listaLoginsChat: 'Logins/getLoginsChat',
    }),
    isProd() {
      return window.location.href.startsWith(this.configuracoes.VUE_APP_PROD_BASE_URL);
    },
  },
  mounted: function () {
    if (this.$campanha.obterCampanhaAtual()) {
      this.campanha = this.$campanha.obterCampanhaAtual();
    } else {
      this.campanha = null;
    }
    this.configuracoes = this.$storeConfig.configuracoes();
    if (this.configuracoes.MODULO_CHAT) {
      this.buscaLogins();
    }
    this.conectarSocket();
  },
  methods: {
    ...mapActions({
      fetchLoginsChat: 'Logins/fetchLoginsChat',
    }),
    buscaLogins() {
      this.fetchLoginsChat({});
    },
    removeNotification(timestamp) {
      this.$notifications.removeNotification(timestamp);
    },

    conectarSocket() {
      if (
        typeof this.configuracoes.URL_CHAT != 'undefined' &&
        this.configuracoes.URL_CHAT != 'false' &&
        typeof this.configuracoes.URL_CHAT_PROD != 'undefined' &&
        this.configuracoes.URL_CHAT_PROD != 'false'
      ) {
        this.chat = new this.$chat(
          this.isProd ? this.configuracoes.URL_CHAT_PROD : this.configuracoes.URL_CHAT,
          this.socketAberto,
          this.socketFechado,
          this.socketRecebido
        );
      }
    },
    socketAberto() {
      this.chat.enviar('identificar', this.$autenticacao.logado().usuario);
    },
    identificar() {
      this.chat.enviar('carregaUltimasConversas', {
        usuario: this.$autenticacao.logado().usuario,
        cliente: `${this.campanha.ano}_${this.campanha.identificacao}`,
      });
    },
    socketFechado() {
      setTimeout(this.conectarSocket, 1000);
    },
    socketRecebido(msg) {
      this.$root.$emit('socketRecebido', msg);
      if (this[msg.canal]) {
        this[msg.canal](msg.valor);
      }
    },
    msg: async function (data) {
      if (!this.$route.path.startsWith('/chat') && this.$autenticacao.logado().usuario == data.destinatario) {
        this.$notify(
          `${this.listaLoginsChat.filter((e) => e.usuario == data.remetente)[0].nome}: ${data.mensagem}`,
          data.remetente
        );
        this.som.play();
      }

      this.chat.enviar('carregaUltimasConversas', {
        usuario: this.$autenticacao.logado().usuario,
        cliente: `${this.campanha.ano}_${this.campanha.identificacao}`,
      });
    },
  },
  created() {
    this.$notifications.settings.overlap = this.overlap;
  },
  watch: {
    overlap: function (newVal) {
      this.$notifications.settings.overlap = newVal;
    },
  },
};
</script>
