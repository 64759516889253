import Vue from 'vue';

// Object initial state
const initialState = () => ({
  totalNS: 0,
  carregando: false,
  totalGeracao: 0,
  erro: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getTotalizador(state) {
    return state.totalNS;
  },

  getTotalGeracao(state) {
    return state.totalGeracao;
  },

  getLoader(state) {
    return state.carregando;
  },

  getErro(state) {
    return state.erro;
  },
};

// Actions
const actions = {
  obterTotalNumerosDaSorte({ commit }, { campanha }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/numerosDaSorteQR/v1/numerosDaSorte/total/${campanha.ano}/${campanha.identificacao}/`;
      const res = await comunicacao.send(endpoint);
      if ([200, 201].includes(res.status)) {
        commit('setTotalizador', res.data.retorno);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem ||
            'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve(res.data);
    });
  },

  gerarNumerosDaSorte({ commit }, { campanha, quantidadeNumeros, quantidadeSeries }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/numerosDaSorteQR/v1/numerosDaSorte/gerar`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          campanha,
          quantidadeNumeros,
          quantidadeSeries
        },
      })
      if ([200, 201].includes(res.status)) {
        commit('setTotalGeracao', res.data.retorno);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setTotalGeracao', 0);
        commit('setErro', null);
      } else {
        commit('setTotalGeracao', 0);
        commit(
          'setErro',
          res.data.mensagem ||
            'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve(res.data);
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setTotalizador(state, data) {
    state.totalNS = data;
  },

  setTotalGeracao(state, data) {
    state.totalGeracao = data;
  },

  switchCarregando(state, data) {
    state.carregando = data;
  },

  setErro(state, data) {
    state.erro = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
