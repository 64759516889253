import Vue from 'vue';

// Object initial state
const initialState = () => ({
  configuracoes: {
    total: 0,
    lista: [],
  },

  totalCedulas: 0,

  carregando: false,

  cedulas: [],

  linkDownload: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getConfiguracoes(state) {
    return state.configuracoes;
  },
  getTotalizador(state) {
    return state.totalCedulas;
  },
  getLoader(state) {
    return state.carregando;
  },
  getCedulas(state) {
    return state.cedulas;
  },
  getLinkDownload(state) {
    return state.linkDownload;
  },
};

// Actions
const actions = {
  buscarConfiguracoes({ commit }, { campanha, paginacao }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/quiz/v1/configuracoes/${campanha.ano}/${campanha.identificacao}/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      //
      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      if ([200, 201].includes(res.status)) {
        commit('setConfiguracoes', { lista: res.data.retorno.retorno, total: res.data.retorno.total });

        resolve({
          status: 200,
          msg: 'Questionario obtido com sucesso!',
          configuracoes: res.data.retorno.retorno,
        });
      } else if ([404].includes(res.status)) {
        commit('setConfiguracoes', []);
        resolve({
          status: 404,
          msg: 'Não foi encontrada nenhuma pergunta!',
        });
      }
    });
  },

  excluirConfiguracao({ commit }, { id }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/quiz/v1/configuracoes`;
      //
      const res = await comunicacao.send(endpoint, {
        method: 'DELETE',
        data: {
          id,
        },
      });

      if (![200, 201].includes(res.status)) {
        return resolve({
          status: res.status,
          msg: res.data.mensagem || 'Erro ao excluir configuração',
        });
      }

      return resolve({
        status: 200,
        msg: 'Configuração excluída com sucesso!',
      });
    });
  },

  listarCedulas({ commit }, { campanha, paginacao, filtro = {} }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/quiz/v1/cedulas/listarCedulas/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;

      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          filtro,
        },
      });
      commit('setCarregando', false);

      if ([200, 201].includes(res.status)) {
        commit('setCedulas', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Cédulas obtidas com sucesso!',
          cedulas: res.data.retorno.retorno,
        });
      } else if ([404].includes(res.status)) {
        commit('setCedulas', []);
        commit('setTotalizador', 0);
        resolve({
          status: 404,
          msg: 'Não foi encontrada nenhuma cédula!',
        });
      } else {
        resolve({
          status: res.status || 500,
          msg: 'Ocorreu um erro desconhecido ao listar cédulas',
        });
      }
    });
  },

  exportarCedulas({ commit }, { campanha, emailEnviar = '', retornaLink = true }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/quiz/v1/cedulas/exportarCedulas`;

      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          anoCampanha: Number(campanha.ano),
          identificacaoCampanha: campanha.identificacao,
          retornaLink,
          emailEnviar,
        },
      });
      commit('setCarregando', false);

      if ([200, 201].includes(res.status)) {
        commit('setLinkDownload', res.data.retorno);
        resolve({
          status: 200,
          msg: 'Cédulas exportadas com sucesso!',
          link: res.data.retorno,
        });
      } else if ([404].includes(res.status)) {
        resolve({
          status: 404,
          msg: 'Não foi encontrada nenhuma cédula respondida para exportar!',
        });
      } else {
        resolve({
          status: res.status || 500,
          msg: res.data.mensagem || 'Ocorreu um erro desconhecido ao exportar cédulas',
        });
      }
    });
  },

  exportarRespostasCedulas({ commit }, { campanha, emailEnviar = '', retornaLink = true }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/quiz/v1/relatorio/respostas`;

      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          campanha,
          retornaLink,
          emailEnviar,
        },
      });
      commit('setCarregando', false);

      if ([200, 201].includes(res.status)) {
        commit('setLinkDownload', res.data.retorno);
        resolve({
          status: 200,
          msg: 'Respostas exportadas com sucesso!',
          link: res.data.retorno.link,
        });
      } else if ([404].includes(res.status)) {
        resolve({
          status: 404,
          msg: 'Não foi encontrada nenhuma resposta!',
        });
      } else {
        resolve({
          status: res.status || 500,
          msg: res.data.mensagem || 'Ocorreu um erro desconhecido ao exportar respostas',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setConfiguracoes(state, { lista, total }) {
    state.configuracoes = {
      lista,
      total,
    };
  },
  setTotalizador(state, data) {
    state.totalCedulas = data;
  },
  setCarregando(state, data) {
    state.carregando = data;
  },
  setCedulas(state, data) {
    state.cedulas = data;
  },
  setLinkDownload(state, data) {
    state.linkDownload = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
