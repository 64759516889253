const initialState = () => ({
  mockWpp: [
    {
      id: 1,
      template: 'boas_vindas',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Olá! Que incrível ter você em nosso programa de cashback. 😊 Estamos felizes em tê-lo conosco e esperamos vê-lo sempre aproveitando suas recompensas!',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 2,
      template: 'ganhou_cashback7',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        '{{1}}: Você ganhou R$ {{2}} de cashback para ser utilizado nas próximas compras. Válido de {{3}} a {{4}} para compras de no mínimo R$ {{5}}',
      tipo: 'gatilho',
      gatilho: 'lancamentocashbackgerado',
      variaveis: ['loja', 'pontos', 'dataLiberacao', 'dataExpiracao', 'valorMinimo'],
    },
    {
      id: 3,
      template: 'expira_clientes',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        '{{1}}: 🚨 Última chamada! Seu cashback está quase vencendo. Não deixe passar essa oportunidade, visite nossa loja para aproveitar seu saldo 🤑',
      tipo: 'gatilho',
      gatilho: 'cashbackprestesaexpirar',
      variaveis: ['loja'],
    },
    {
      id: 4,
      template: 'expira_cliente',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        '🚨 Última chamada! Seu cashback está quase vencendo. Não deixe passar essa oportunidade, visite nossa loja para aproveitar seu saldo 🤑',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 5,
      template: 'oferta_especial',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Não perca nossa oferta especial! 🎉🎁 Corra para nossa loja e aproveite ao máximo seu cashback. Estamos te esperando para fazer suas compras ainda mais especiais! 💰🛍️',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 6,
      template: 'oferta_especial',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Não perca nossa oferta especial! 🎉🎁 Corra para nossa loja e aproveite ao máximo seu cashback. Estamos te esperando para fazer suas compras ainda mais especiais! 💰🛍️',
      tipo: 'agendada',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 7,
      template: 'reativacao_base',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Sentimos sua falta! 😢 Faz tempo que você não nos visita. Venha conferir as novidades e aproveitar seu cashback. Temos muitas surpresas esperando por você! 🎉🛍️',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 8,
      template: 'reativacao_base',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Sentimos sua falta! 😢 Faz tempo que você não nos visita. Venha conferir as novidades e aproveitar seu cashback. Temos muitas surpresas esperando por você! 🎉🛍️',
      tipo: 'agendada',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 9,
      template: 'black_friday',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'A Black Friday chegou! 🛍️💸 Aproveite os descontos arrasadores e ainda ganhe cashback em suas compras. Corra para garantir as melhores ofertas! 🚀🎁',
      tipo: 'agendada',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 10,
      template: 'black_friday',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'A Black Friday chegou! 🛍️💸 Aproveite os descontos arrasadores e ainda ganhe cashback em suas compras. Corra para garantir as melhores ofertas! 🚀🎁',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 11,
      template: 'dia_dos_pais',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Pai merece o melhor! 👨‍👧‍👦 Aproveite nossas promoções especiais para o Dia dos Pais e ainda ganhe cashback em suas compras. Venha nos visitar! 🎉🎁',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 12,
      template: 'dia_dos_pais',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Pai merece o melhor! 👨‍👧‍👦 Aproveite nossas promoções especiais para o Dia dos Pais e ainda ganhe cashback em suas compras. Venha nos visitar! 🎉🎁',
      tipo: 'agendada',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 13,
      template: 'dia_dos_namorados',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Amor em dobro! ❤️💸 Compre um presente especial para o seu amor e ganhe cashback para sua próxima compra. Venha conferir nossas ofertas! 💑🎁',
      tipo: 'agendada',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 14,
      template: 'dia_dos_namorados',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Amor em dobro! ❤️💸 Compre um presente especial para o seu amor e ganhe cashback para sua próxima compra. Venha conferir nossas ofertas! 💑🎁',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 15,
      template: 'feliz_natal',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Feliz Natal! 🎅✨ Ho ho ho, o papai noel chegou adiantado com ótimas promoções. Venha conferir nossas novidades e utilize seu saldo de cashback para economizar ainda mais! 🎁💰',
      tipo: 'agendada',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 16,
      template: 'feliz_natal',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Feliz Natal! 🎅✨ Ho ho ho, o papai noel chegou adiantado com ótimas promoções. Venha conferir nossas novidades e utilize seu saldo de cashback para economizar ainda mais! 🎁💰',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 17,
      template: 'aniversario',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Parabéns pelo seu dia! 🎉🎁 Aproveite e venha nos visitar, temos um presentinho especial esperando por você. Consulte seu saldo de cashback e faça seu dia ainda mais especial! 🙂',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 18,
      template: 'aniversario',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Parabéns pelo seu dia! 🎉🎁 Aproveite e venha nos visitar, temos um presentinho especial esperando por você. Consulte seu saldo de cashback e faça seu dia ainda mais especial! 🙂',
      tipo: 'agendada',
      gatilho: 'geral',
      variaveis: [],
    },
    {
      id: 19,
      template: 'expira_15_dias',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Opa! Seu saldo de R$ {{1}} em cashback está prestes a expirar em 15 dias. Aproveite enquanto há tempo para garantir seus benefícios! ⏳💸',
      tipo: 'gatilho',
      gatilho: 'cashbackprestesaexpirar',
      variaveis: ['saldo'],
    },
    {
      id: 20,
      template: 'expira_30_dias',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Opa! Seu saldo de R$ {{1}} em cashback está prestes a expirar em 30 dias. Aproveite essa oportunidade para economizar em suas compras! ⏳💸',
      tipo: 'gatilho',
      gatilho: 'cashbackprestesaexpirar',
      variaveis: ['saldo'],
    },
    {
      id: 21,
      template: 'expira7_dias',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'Opa! Seu saldo de R$ {{1}} em cashback está prestes a expirar em 7 dias. Não perca a chance de usar esse desconto especial! ⏳💸',
      tipo: 'gatilho',
      gatilho: 'cashbackprestesaexpirar',
      variaveis: ['saldo'],
    },

    {
      id: 22,
      template: 'sorteio_oiweek',
      namespace: 'c29f8cd5_ac81_4f1a_8fcd_03130127e85d',
      mensagem:
        'O sorteio da Polgo na feira Oi Week vai ocorrer em instantes. Boa sorte🍀🍀Confira no site se você foi um ganhador: https://oiweek.polgo.com.br/ Lembre-se de estar presente em nosso stand na hora do sorteio para ser considerado um ganhador válido! 😉',
      tipo: 'agora',
      gatilho: 'geral',
      variaveis: [],
    },
  ],

  listagemWhatsappMockPosFiltragem: [],
});

const state = initialState();

const getters = {
  getMockWpp: (state) => {
    return state.mockWpp;
  },

  getListagemWhatsappMockPosFiltragem: (state) => {
    return state.listagemWhatsappMockPosFiltragem;
  },
};

const actions = {
  setListagemWhatsappMockPosFiltragem({ commit }, payload) {
    commit('setListagemWhatsappMockPosFiltragem', payload);
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setListagemWhatsappMockPosFiltragem(state, payload) {
    state.listagemWhatsappMockPosFiltragem = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
