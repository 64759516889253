<template>
  <nav
    class="navbar"
    :class="[
      {'navbar-expand-md': expand},
      {'navbar-transparent': transparent},
      {[`bg-${type}`]: type}
    ]"
  >
    <navbar-toggle-button
      v-if="showToggleButton"
      :toggled="toggled"
      :target="contentId"
      @click.native.stop="toggled = !toggled"
    >
      <span class="navbar-toggler-icon"></span>
    </navbar-toggle-button>
    <div
      :class="{show: toggled}"
      :id="contentId"
      v-click-outside="closeMenu"
      style="position: absolute; right: 20px;"
    >
      <slot :close-menu="closeMenu"></slot>
    </div>
    <div :class="containerClasses">
      <div class="row" style="margin-top: 15px;">
        <slot name="brand">
          <router-link
            :to="$route.path"
            style="font-size: 1rem;"
            class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block font-montserrat"
          >
            {{ descricao }}
          </router-link>
        </slot>
      </div>
    </div>
  </nav>
</template>
<script>
  import NavbarToggleButton from "./NavbarToggleButton";
  

  export default {
    beforeMount() {
      // Carregar configurações.
      this.configuracoes = this.$storeConfig.configuracoes();
    },
    name: "base-nav",
    components: {
      NavbarToggleButton
    },
    props: {
      type: {
        type: String,
        default: "",
        description: "Navbar type (e.g default, primary etc)"
      },
      title: {
        type: String,
        default: "",
        description: "Title of navbar"
      },
      contentId: {
        type: [String, Number],
        default: Math.random().toString(),
        description:
          "Explicit id for the menu. By default it's a generated random number"
      },
      containerClasses: {
        type: [String, Object, Array],
        default: 'container-fluid'
      },
      transparent: {
        type: Boolean,
        default: false,
        description: "Whether navbar is transparent"
      },
      expand: {
        type: Boolean,
        default: false,
        description: "Whether navbar should contain `navbar-expand-lg` class"
      },
      showToggleButton: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        toggled: false,
        configuracoes: null,
      };
    },
    methods: {
      closeMenu() {
        this.toggled = false;
      }
    },
    computed: {
      descricao() {
        let descricao = this.$route.meta.desc;
        if (
          this.$route.path == '/raspadinhasAlternativas' &&
          this.configuracoes &&
          this.configuracoes.MUDAR_TEXTO_RASPADINHAS
        ) {
          descricao = 'Giros realizados'
        }else if(
          this.$route.path == '/configCompraRaspadinha' &&
          this.configuracoes &&
          this.configuracoes.MUDAR_TEXTO_RASPADINHAS
        ) {
          descricao = 'configurações compra/giro'
        }else if(
          this.$route.path == '/configRaspadinha' &&
          this.configuracoes &&
          this.configuracoes.MUDAR_TEXTO_RASPADINHAS
        ) {
          descricao = 'configurações giro'
        }
        return descricao
      }
    }
  };
</script>
<style>
</style>
