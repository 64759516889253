<template>
  <div class="main-content d-flex">
    <!-- Page content -->
    <div class="containerLeft">
      <img src="../assets/bkgLogin.png" />
    </div>

    <div class="container containerRigth">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view :instancias="instancias"></router-view>
      </slide-y-up-transition>
    </div>

    <!-- <footer class="my-4">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col">
            <div class="copyright footer-login text-center text-xl-left text-white">
              © {{ year }} - Versão: {{ APPLICATION_VERSION }}
            </div>
          </div>
        </div>
      </div>
    </footer> -->
  </div>
</template>

<script>
import { SlideYUpTransition } from 'vue2-transitions';
export default {
  name: 'auth-layout',
  props: ['instancias'],
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      configuracoes: null,
      // eslint-disable-next-line
      APPLICATION_VERSION: APPLICATION_VERSION,
    };
  },
  beforeMount() {
    this.configuracoes = this.$storeConfig.configuracoes();
  },
};
</script>

<style scoped>
.containerLeft{
  height: 100vh;
  width: 89.91vh;
  img{
    width: 100%;
    height: 100%;
  }
}

.containerRigth {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 0px;
  padding-left: 0px;
  flex: 1;
  max-width: unset;
}

.footer-login {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .containerLeft{
    display: none;
    width: 0%;
  }

  .containerRigth{
    width: 100%;
  }
}
</style>
