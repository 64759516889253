/**
 * Classe de manipuladores de geolocalização
 */
class Geo {
    /**
     * Retorna um objeto JSON com os estados e siglas da federação
     */
    static estados() {
        return [
            {
                uf: "AC",
                desc: "Acre"
            },
            {
                uf: "AL",
                desc: "Alagoas"
            },
            {
                uf: "AM",
                desc: "Amazonas"
            },
            {
                uf: "AP",
                desc: "Amapá"
            },
            {
                uf: "BA",
                desc: "Bahia"
            },
            {
                uf: "CE",
                desc: "Ceará"
            },
            {
                uf: "DF",
                desc: "Distrito Federal"
            },
            {
                uf: "ES",
                desc: "Espírito Santo"
            },
            {
                uf: "GO",
                desc: "Goiás"
            },
            {
                uf: "MA",
                desc: "Maranhão"
            },
            {
                uf: "MG",
                desc: "Minas Gerais"
            },
            {
                uf: "MS",
                desc: "Mato Grosso do Sul"
            },
            {
                uf: "MT",
                desc: "Mato Grosso"
            },
            {
                uf: "PA",
                desc: "Pará"
            },
            {
                uf: "PB",
                desc: "Paraíba"
            },
            {
                uf: "PE",
                desc: "Pernambuco"
            },
            {
                uf: "PI",
                desc: "Piauí"
            },
            {
                uf: "PR",
                desc: "Paraná"
            },
            {
                uf: "RJ",
                desc: "Rio de Janeiro"
            },
            {
                uf: "RN",
                desc: "Rio Grande do Norte"
            },
            {
                uf: "RO",
                desc: "Rondônia"
            },
            {
                uf: "RR",
                desc: "Roraima"
            },
            {
                uf: "RS",
                desc: "Rio Grande do Sul"
            },
            {
                uf: "SC",
                desc: "Santa Catarina"
            },
            {
                uf: "SE",
                desc: "Sergipe"
            },
            {
                uf: "SP",
                desc: "São Paulo"
            },
            {
                uf: "TO",
                desc: "Tocantins"
            }
        ];
    }
}
export default Geo;