<template>
  <div
    class="engajamento d-flex align-items-center"
    :class="possuiPorcentagem ? 'porcentagem-ok' : 'sem-porcentagem'"
  >
    <div class="porcentagem" :class="{ 'd-none': !possuiPorcentagem }">{{ porcentagem }}%</div>

    <div
      class="base-titulo text-uppercase d-flex align-items-center"
      :class="possuiPorcentagem ? 'porcentagem-ok' : 'sem-porcentagem'"
    >
      <p class="titulo" v-tooltip="nome">{{ nome }}</p>
    </div>

    <div
      class="dados d-flex align-items-center"
      :class="possuiPorcentagem ? 'porcentagem-ok' : 'sem-porcentagem'"
    >
      <slot></slot>
    </div>

    <template v-if="possuiPorcentagem">
      <div
        class="progresso"
        v-if="!progressoInvertido"
        :class="{
          verde: porcentagemVerificada < 75,
          amarelo: porcentagemVerificada >= 75 && porcentagemVerificada < 90,
          vermelho: porcentagemVerificada >= 90 && porcentagemVerificada <= 100,
        }"
        :style="{ width: `${porcentagemVerificada}%` }"
      ></div>

      <div
        class="progresso"
        v-else
        :class="{
          vermelho: porcentagemVerificada < 50,
          amarelo: porcentagemVerificada >= 50 && porcentagemVerificada < 90,
          verde: porcentagemVerificada >= 90 && porcentagemVerificada <= 100,
        }"
        :style="{ width: `${porcentagemVerificada}%` }"
      ></div>
    </template>
    <div class="indice">{{ indice }}º</div>
  </div>
</template>

<script>
export default {
  name: 'StatsProgressBar',
  props: {
    porcentagem: {
      type: Number,
      required: false,
    },
    possuiPorcentagem: {
      type: Boolean,
      required: false,
      default: true,
    },
    nome: {
      type: String,
      required: true,
    },
    indice: {
      type: Number,
      required: true,
    },
    progressoInvertido: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    porcentagemVerificada() {
      return this.porcentagem <= 100 ? this.porcentagem : 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.engajamento {
  width: 100%;
  height: 55px;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  padding: 0 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  color: #b1587a;
  padding-bottom: 5px;

  &.sem-porcentagem {
    justify-content: space-between;
  }

  > .porcentagem {
    font-size: 1.625rem;
    font-weight: 600;
    color: #045ca4;
    width: 74px;
    text-align: center;
  }

  > .progresso {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 8px;
    border-radius: 0 5px 5px 12px;

    &.verde {
      background-color: #1eba09;
    }
    &.amarelo {
      background-color: #e1a12c;
    }
    &.vermelho {
      background-color: #ff1e3d;
    }
  }

  > .base-titulo {
    &.porcentagem-ok {
      &::before,
      &::after {
        content: '';
        width: 2px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.08);
        display: block;
        margin: 0 12px;
      }
    }

    &.sem-porcentagem {
      padding: 10px;
    }

    > .titulo {
      font-weight: 500;
      font-size: 0.9375rem;
      margin-bottom: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  > .dados {
    font-size: 0.87rem;
    &.sem-porcentagem {
      padding-right: 20px;
    }

    > strong {
      font-size: 1rem;
    }
  }

  > .indice {
    position: absolute;
    right: -16px;
    margin-top: 2px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #045ca4;
    color: #fff;
    font-size: 0.875rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  }
}

@media screen and (max-width: 1399px) {
  .engajamento {
    padding-right: 16px;

    > .base-titulo {
      > .titulo {
        font-size: 0.8125rem;
      }

      &::before,
      &::after {
        margin: 0 8px;
      }
    }

    > .dados {
      font-size: 0.8125rem;

      > strong {
        font-size: 0.75rem;
      }
    }

    &::before {
      right: 16px;
    }

    .porcentagem {
      font-size: 1.25rem;
      width: 57px;
    }
  }
}

@media screen and (max-width: 550px) {
  .engajamento {
    > .base-titulo {
      > .titulo {
        width: 70px;
      }
    }
  }
}

@media screen and (min-width: 551px) and (max-width: 767px) {
  .engajamento {
    > .base-titulo {
      > .titulo {
        width: 240px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .engajamento {
    > .base-titulo {
      > .titulo {
        width: 160px;
      }
    }
  }
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
  .engajamento {
    > .base-titulo {
      > .titulo {
        width: 320px;
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .engajamento {
    > .base-titulo {
      > .titulo {
        width: 90px;
      }
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1560px) {
  .engajamento {
    > .base-titulo {
      > .titulo {
        width: 140px;
      }
    }
  }
}

@media screen and (min-width: 1561px) and (max-width: 1879px) {
  .engajamento {
    > .base-titulo {
      > .titulo {
        width: 220px;
      }
    }
  }
}

@media screen and (min-width: 1880px) {
  .engajamento {
    > .base-titulo {
      > .titulo {
        width: 390px;
      }
    }
  }
}
</style>