var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.campanha && this.tipoUsuario.toLowerCase() == 'industria')?_c('sidebar-item',{attrs:{"link":{
      name: 'Home',
      icon: 'fas fa-industry text-dark',
      path: '/homeIndustria',
    }}}):_vm._e(),(this.campanha && this.tipoUsuario.toLowerCase() == 'rede')?_c('sidebar-item',{attrs:{"link":{
      name: 'Home',
      icon: 'fas fa-igloo text-dark',
      path: '/homerede',
    }}}):_vm._e(),_vm._l((_vm.listaSidebar),function(pagina,idx){return _c('ItemSiderbar',{key:idx,attrs:{"dados":pagina}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }