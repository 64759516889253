import Vue from 'vue'

// Object initial state
const initialState = () => ({
  docs: [],
  totalDocs: 0,
  totalPaginas: 0,
  carregando: false
})

// State object
const state = initialState()

// Getter object
const getters = {
  getDocs(state) {
    return state.docs
  },
  getTotalizador(state) {
    return state.totalDocs
  },
  getPaginas(state) {
    return state.totalPaginas
  },
  getLoader(state) {
    return state.carregando
  }
}

// Actions
const actions = {
  fetchDocs({ commit }, { campanha, filtro, paginacao }) {
    return new Promise( async ( resolve ) => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = `/documentoFiscal/v1/origem/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`
      
      const ativo = filtro.dataEscaneado.ativo
      let atual = new Date()
      atual =
        atual.getFullYear().toString() +
        "-" +
        (atual.getMonth() + 1).toString() +
        "-" +
        atual.getDate().toString()
      const ate = filtro.dataEscaneado.ate
      const fim = ate && ativo ? ate : atual
      const inicioSelecionada = filtro.dataEscaneado.de
      const inicio = inicioSelecionada && ativo ? inicioSelecionada : "1970-01-01"
      const status = filtro.statusAtivo ? filtro.status : ""
      const usuario = filtro.usuarioAtivo ? filtro.usuario.replace(/[^\d]/g, "") : ""

      var dados = {
        dataInicial: inicio,
        dataFinal: fim,
        statusDocOrigem: status,
        usuario: usuario
      }

      let res = await comunicacao.send(endpoint, {
        method: 'POST',
        data : dados
      })

      if( [200, 201].includes(res.status) ) {
        commit('setDocs', res.data.retorno.retorno)
        commit('setTotalizador', res.data.retorno.total)
        commit('setPaginas', res.data.retorno.paginas)
      } else {
        commit('setDocs', [])
        commit('RESET')
      }
      commit('setLoader', false)
      resolve()
    })
  },
  reprocessarDoc({ commit }, { id, uf, saldo }) {
    return new Promise( async ( resolve ) => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = `/documentoFiscal/v1/documentos/escanear`

      const dados = {
        reprocessarDocumentoFiscalOrigem: {
          id: id,
          uf: uf,
          atualizarSaldo: saldo
        }
      }
      
      let res = await comunicacao.send(endpoint, {
        method: 'POST',
        data : { ...dados }
      })

      if( [200, 201].includes(res.status) ) {
        commit('setLoader', false)
        resolve({ status: 200, mensagem: 'Documento fiscal reprocessado com sucesso!' })
      } else {
        commit('setLoader', false)
        resolve({ status: res.status || 500, mensagem: res.data.mensagem || 'Erro ao reprocessar o documento'})
      }
    })
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  setDocs(state, data) {
    state.docs = data
  },
  setTotalizador(state, data) {
    state.totalDocs = data
  },
  setPaginas(state, data) {
    state.totalPaginas = data
  },
  setLoader(state, data) {
    state.carregando = data
  }
}

// Exporting store module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}