import Vue from 'vue';
import Router from 'vue-router';

import routes from './routes';

Vue.use(Router);

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes,
});


function fabricarMiddlewares(contexto, middleware, index) {
  const middlewareSeguinte = middleware[index]

  if (!middlewareSeguinte) return contexto.next;

  return (...params) => {
    contexto.next(...params)

    const proximoMiddleware = fabricarMiddlewares(contexto, middleware, index + 1)
    middlewareSeguinte({ ...contexto, next: proximoMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware || to.matched.some(record => record.meta.middleware)) {
    const middlewareExistente = to.meta.middleware || to.matched.filter(record => record.meta.middleware)[0].meta.middleware
    const middleware = Array.isArray(middlewareExistente) ? middlewareExistente : [middlewareExistente]

    const contexto = {
      from, next, router, to
    }

    const proximoMiddleware = fabricarMiddlewares(contexto, middleware, 1)
    return middleware[0]({ ...contexto, next: proximoMiddleware })
  }

  return next()
})

export default router;