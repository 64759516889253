import Vue from 'vue';

// Object initial state
const initialState = () => ({
  carregando: false,
  adesoes: null,
  associados: [],
  totalRegistros: 0,
  totalPaginas: 0,
  carregandoLimite: false,
  associadosLimite: null,
  totalRegistrosLimite: 0,
  totalPaginasLimite: 0,
  listaAssociados: [],
  paginacaoListaAssociados: { paginas: 0, total: 0 },
});

// State object
const state = initialState();

// Getter object
const getters = {
  getAssociados(state) {
    return state.adesoes;
  },
  getTodosAssociados(state) {
    return state.associados;
  },
  getCarregando(state) {
    return state.carregando;
  },
  getPaginas(state) {
    return state.totalPaginas;
  },
  getTotal(state) {
    return state.totalRegistros;
  },
  getAssociadosLimite(state) {
    return state.associadosLimite;
  },
  getCarregandoLimite(state) {
    return state.carregandoLimite;
  },
  getPaginasLimite(state) {
    return state.totalPaginasLimite;
  },
  getTotalLimite(state) {
    return state.totalRegistrosLimite;
  },
  obterListaAssociados(estado) {
    return estado.listaAssociados;
  },
  obterPaginacaoListaAssociados(estado) {
    return estado.paginacaoListaAssociados;
  },
};

// Actions
const actions = {
  fetchAssociados({ commit }, { campanha, filtroNome, filtroAtivo, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/campanha/v1/associados/${campanha.ano}/${campanha.identificacao}/cadastro/`;
      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          filtroAtivo: filtroAtivo,
          filtroNome: filtroNome,
          totalPorPagina: paginacao.porPagina,
          paginaAtual: paginacao.paginaAtual,
        },
      });
      if ([200, 201].includes(resposta.status)) {
        const retorno = resposta.data.retorno.retorno;
        commit('setAssociados', retorno);
        commit('setTodosAssociados', retorno);
        commit('setPaginas', resposta.data.retorno.paginas);
        commit('setTotal', resposta.data.retorno.total);
        resolve({ status: 200, mensagem: resposta.data.mensagem });
      } else {
        resolve({
          status: resposta.status,
          mensagem: resposta.data && resposta.data.mensagem ? resposta.data.mensagem : 'Erro',
        });
      }
      commit('setCarregando', false);
    });
  },
  cadastrarAssociadoCampanha({ commit }, { campanha, associado }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/campanha/v1/associados`;
      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          anoCampanha: campanha.ano,
          identificacaoCampanha: campanha.identificacao,
          cnpjCpf: associado.cnpjCpf.replace(/\D/g, ''),
          nome: associado.nome.toUpperCase(),
          serie: associado.serie,
        },
      });
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          mensagem: resposta.data.mensagem,
        });
      } else {
        let mensagem = resposta.data && resposta.data.mensagem ? resposta.data.mensagem : 'Erro';
        mensagem = resposta.data.retorno ? `${mensagem} ${resposta.data.retorno}` : mensagem;
        resolve({
          status: resposta.status,
          mensagem,
        });
      }
      commit('setCarregando', false);
    });
  },
  cadastrarAssociado({ commit }, { associado }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/associado/v1/associados`;
      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          cnpjCpf: associado.cnpjCpf.replace(/\D/g, ''),
          nome: associado.nome,
        },
      });
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          mensagem: resposta.data.mensagem,
        });
      } else {
        let mensagem = resposta.data && resposta.data.mensagem ? resposta.data.mensagem : 'Erro';
        mensagem = resposta.data.retorno ? `${mensagem} ${resposta.data.retorno}` : mensagem;
        resolve({
          status: resposta.status,
          mensagem,
        });
      }
      commit('setCarregando', false);
    });
  },
  cadastrarAssociadoCompleto({ commit }, { associado }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/associado/v1/associados`;
      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          cnpjCpf: associado.cnpjCpf.replace(/\D/g, ''),
          nome: associado.nome,
          loja: associado.loja,
          codigoInterno: associado.codigoInterno,
          razaoSocial: associado.razaoSocial,
        },
      });
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          mensagem: resposta.data.mensagem,
        });
      } else {
        let mensagem = resposta.data && resposta.data.mensagem ? resposta.data.mensagem : 'Erro';
        resolve({
          status: resposta.status,
          mensagem: mensagem,
          retorno: resposta.data.retorno,
        });
      }
      commit('setCarregando', false);
    });
  },
  // fetchAssociadosLimite({ commit }, { campanha, filtroNome, filtroAtivo, paginacao }) {
  fetchAssociadosLimite({ commit }, { campanha, paginacao, filtro }) {
    return new Promise(async (resolve) => {
      commit('setCarregandoLimite', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/associado/v1/associados/listarAssociados/${paginacao.porPagina}/${paginacao.paginaAtual}`;
      const resposta = await comunicacao.send(url, {
        method: 'GET',
        data: filtro,
      });
      const urlLimite = `/cupom/v2/associadoCampanha/limite/${campanha.ano}/${campanha.identificacao}/-1/1`;
      const respostaLimite = await comunicacao.send(urlLimite, {
        method: 'GET',
      });

      if ([200, 201].includes(resposta.status)) {
        const retornoAssociados = resposta.data.retorno.retorno;
        const retornoLimite = respostaLimite.data.retorno.retorno;
        const retorno = retornoAssociados.map((e) => {
          const retornoLimiteNS = retornoLimite.find((f) => f.cnpjCpf == e.cnpjCpf);
          const retornoTotalNS = retornoLimite.find((f) => f.cnpjCpf == e.cnpjCpf);
          const retornoCupons = retornoLimite.find((f) => f.cnpjCpf == e.cnpjCpf);
          return {
            ...e,
            limiteNS: retornoLimiteNS && retornoLimiteNS.limiteNS ? retornoLimiteNS.limiteNS : 0,
            totalNS: retornoTotalNS && retornoTotalNS.totalNS ? retornoTotalNS.totalNS : 0,
            cupons: retornoCupons && retornoCupons.cupons ? retornoCupons.cupons : 0,
          };
        });

        commit('setAssociadosLimite', retorno);
        commit('setPaginasLimite', resposta.data.retorno.paginas);
        commit('setTotalLimite', resposta.data.retorno.total);
        resolve({ status: 200, mensagem: resposta.data.mensagem });
      } else {
        resolve({
          status: resposta.status,
          mensagem: resposta.data && resposta.data.mensagem ? resposta.data.mensagem : 'Erro',
        });
      }
      commit('setCarregandoLimite', false);
    });
  },
  fetchAssociadosLimiteFiltro({ commit }, { campanha, filtro }) {
    return new Promise(async (resolve) => {
      commit('setCarregandoLimite', true);
      const comunicacao = new Vue.prototype.$comunicacao();

      let url = null;
      let dadosFiltro = null;

      if (typeof filtro == 'string') {
        url = `/cupom/v2/associadoCampanha/limite/${campanha.ano}/${campanha.identificacao}/${filtro}`;
      } else {
        url = `/cupom/v2/associadoCampanha/limite/${campanha.ano}/${campanha.identificacao}/filtro`;
        dadosFiltro = filtro;
      }

      const resposta = await comunicacao.send(url, {
        method: 'GET',
        data: dadosFiltro,
      });

      if ([200, 201].includes(resposta.status)) {
        const retorno = resposta.data.retorno.retorno;
        commit('setAssociadosLimite', retorno);
        // commit('setPaginasLimite', resposta.data.retorno.paginas);
        // commit('setTotalLimite', resposta.data.retorno.total);
        resolve({ status: 200, mensagem: resposta.data.mensagem });
      } else {
        resolve({
          status: resposta.status,
          mensagem: resposta.data && resposta.data.mensagem ? resposta.data.mensagem : 'Erro',
        });
      }
      commit('setCarregandoLimite', false);
    });
  },
  buscarListaAssociados({ commit }, { paginacao, filtro }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();

      const url = `/associado/v1/associados/listarAssociados/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const resposta = await comunicacao.send(url, {
        method: 'GET',
        data: filtro,
      });

      if (resposta.status !== 200) {
        commit('definirPaginacaoListaAssociados', {
          paginas: 0,
          total: 0,
        });
        commit('definirListaAssociados', []);
        commit('setCarregando', false);
        return resolve({
          status: resposta.status,
          mensagem: resposta.data && resposta.data.mensagem ? resposta.data.mensagem : 'Erro interno',
        });
      }

      commit('setCarregando', false);

      commit('definirPaginacaoListaAssociados', {
        paginas: resposta.data.retorno.paginas,
        total: resposta.data.retorno.total,
      });

      commit('definirListaAssociados', resposta.data.retorno.retorno);
      return resolve({ status: resposta.status, mensagem: resposta.data.mensagem, retorno: resposta.data.retorno });
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setAssociados(state, data) {
    const associados = {};
    for (let i in data) {
      let associado = data[i];
      associado.cupons = { totalCupons: 0, totalEscaneados: 0 };
      associado.lotes = {};
      associado.temLotes = false;
      associados[associado.cnpjCpf] = associado;
    }
    state.adesoes = associados;
  },
  setTodosAssociados(state, data) {
    state.associados = data;
  },
  setCarregando(state, data) {
    state.carregando = data;
  },
  setPaginas(state, data) {
    state.totalPaginas = data;
  },
  setTotal(state, data) {
    state.totalRegistros = data;
  },
  setAssociadosLimite(state, data) {
    const associadosLim = {};
    for (let i in data) {
      let associadoLimite = data[i];
      associadoLimite.cupons = { totalCupons: 0, totalEscaneados: 0 };
      associadosLim[associadoLimite.cnpjCpf] = associadoLimite;
    }
    state.associadosLimite = associadosLim;
  },
  setCarregandoLimite(state, data) {
    state.carregandoLimite = data;
  },
  setPaginasLimite(state, data) {
    state.totalPaginasLimite = data;
  },
  setTotalLimite(state, data) {
    state.totalRegistrosLimite = data;
  },
  definirListaAssociados(estado, dados) {
    estado.listaAssociados = dados;
  },
  definirPaginacaoListaAssociados(estado, dados) {
    estado.paginacaoListaAssociados = dados;
  },
};

// Exporting store module
export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
