import { Autenticacao } from '../../Autenticacao';
import { StoreConfig } from '../../Storage';

/**
 * @description Obter permissões de páginas para o usuário a partir das configurações
 */
class Permissoes {
  /**
   * @description Obter permissões de acesso as páginas pelo tipo de usuário
   * @returns {object} {titulo: string, condicao: string, icone: string, paginas: Object[]}
   */
  static obter() {
    const configuracoes = StoreConfig.configuracoes();
    const autenticacao = Autenticacao.default

    if (configuracoes) {
      const permissoesUsuario = configuracoes.PERMISSOES_PAGINAS.find((e) => e.tipo === autenticacao.logado().tipo)

      return {
        ...permissoesUsuario,
        paginas: permissoesUsuario.paginas.split(','),
        acessoTotal: permissoesUsuario.paginas === '*',
      }
    }

    return null;
  }
}

export default Permissoes;