<template>
  <div class="btn-grupo" @click="handleClick">
    <div class="icone" :style="estilosIcone">
      <i :class="icone"></i>
    </div>

    <button class="botao" :style="estilosBotao">
      <template v-if="titulo">
        <strong>{{ titulo }}</strong>
      </template>

      <template v-else>
        <slot></slot>
      </template>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonNew',

  props: {
    icone: {
      type: String,
      required: true,
    },
    titulo: {
      type: String,
      required: false,
    },
    iconeTextoCor: {
      type: String,
      required: false,
      default: '#FFF',
    },
    iconeFundoCor: {
      type: String,
      required: true,
    },
    botaoFundoCor: {
      type: String,
      required: true,
    },
    botaoTextoCor: {
      type: String,
      rquired: false,
      default: '#FFF'
    }
  },

  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },

  computed: {
    estilosIcone() {
      return {
        'background-color': this.iconeFundoCor,
        color: this.iconeTextoCor,
      };
    },
    estilosBotao() {
      return {
        'background-color': this.botaoFundoCor,
        'color': this.botaoTextoCor
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-grupo {
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  position: relative;
  transition: opacity 0.2s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-radius: 0.5rem;
  transition: all 0.2s;

  > .icone {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: -24px;
    font-size: 0.875rem;
  }

  > .botao {
    width: 100%;
    outline: none;
    border: 0;
    border-radius: 0.5rem;
    padding: 0 2.25rem;
    font-weight: 600;
    font-size: 0.9375rem;
    text-align: left;
    height: 100%;
    line-height: 100%;
    font-weight: 400;
  }

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    transform: scale(1.02);
  }

  &:active {
    opacity: 0.9;
  }
}
</style>