<template>
  <div class="paginacao d-flex align-items-center">
    <i class="fas fa-caret-left seta" @click="mudarPagina(paginaAtual - 1)"></i>

    <span>PÁGINA</span>

    <button
      class="selecionar-pagina"
      v-for="item in totalPaginas"
      :key="item"
      :class="[paginaAtual === item ? 'ativo' : '']"
      @click="mudarPagina(item)"
    >
      {{ item }}
    </button>

    <i class="fas fa-caret-right seta" @click="mudarPagina(paginaAtual + 1)"></i>
  </div>
</template>

<script>
export default {
  name: 'BasePaginacao',

  props: {
    paginaAtual: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    porPagina: {
      type: Number,
      required: true,
    },
  },

  computed: {
    totalPaginas() {
      if (this.total > 0) {
        return Math.ceil(this.total / this.porPagina);
      }

      return 1;
    },
  },

  methods: {
    mudarPagina(pagina) {
      if (pagina <= this.totalPaginas && pagina > 0) this.$emit('input', pagina);
    },
  },
};
</script>

<style lang="scss" scoped>
.paginacao {
  color: #aaa;
  font-size: 0.75rem;
  font-weight: 600;

  > .selecionar-pagina {
    border: 0;
    padding: 0 0.15rem;
    background-color: transparent;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    outline: none;

    &:hover,
    &.ativo {
      color: rgb(143, 143, 143);
    }
  }

  > .seta {
    margin: 0 0.15rem;
    cursor: pointer;

    &:hover {
      color: rgb(143, 143, 143);
    }
  }
}
</style>