var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"engajamento d-flex align-items-center",class:_vm.possuiPorcentagem ? 'porcentagem-ok' : 'sem-porcentagem'},[_c('div',{staticClass:"porcentagem",class:{ 'd-none': !_vm.possuiPorcentagem }},[_vm._v(_vm._s(_vm.porcentagem)+"%")]),_c('div',{staticClass:"base-titulo text-uppercase d-flex align-items-center",class:_vm.possuiPorcentagem ? 'porcentagem-ok' : 'sem-porcentagem'},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.nome),expression:"nome"}],staticClass:"titulo"},[_vm._v(_vm._s(_vm.nome))])]),_c('div',{staticClass:"dados d-flex align-items-center",class:_vm.possuiPorcentagem ? 'porcentagem-ok' : 'sem-porcentagem'},[_vm._t("default")],2),(_vm.possuiPorcentagem)?[(!_vm.progressoInvertido)?_c('div',{staticClass:"progresso",class:{
        verde: _vm.porcentagemVerificada < 75,
        amarelo: _vm.porcentagemVerificada >= 75 && _vm.porcentagemVerificada < 90,
        vermelho: _vm.porcentagemVerificada >= 90 && _vm.porcentagemVerificada <= 100,
      },style:({ width: (_vm.porcentagemVerificada + "%") })}):_c('div',{staticClass:"progresso",class:{
        vermelho: _vm.porcentagemVerificada < 50,
        amarelo: _vm.porcentagemVerificada >= 50 && _vm.porcentagemVerificada < 90,
        verde: _vm.porcentagemVerificada >= 90 && _vm.porcentagemVerificada <= 100,
      },style:({ width: (_vm.porcentagemVerificada + "%") })})]:_vm._e(),_c('div',{staticClass:"indice"},[_vm._v(_vm._s(_vm.indice)+"º")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }