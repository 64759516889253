import { Autenticacao } from '../../lib/Autenticacao';
import { Servico } from '../../lib/Storage';

const autenticacao = Autenticacao.default;
const servicoAtivo = Servico.obterServicoAtivo();

export default function deslogado({ to, next, router }) {
  const usuarioLogado = autenticacao.logado();

  if (usuarioLogado) {
    if (to.name === 'login' && to.query.token && (to.query.cliente || to.query.usuario)) {
      localStorage.removeItem('login');

      return router.push({
        path: to.fullPath,
        query: to.query,
      });
    }

    return router.push({
      name: servicoAtivo === 'SORTEIOS' ? 'dashboard' : 'cashback',
      query: { redirecionar: to.fullPath },
    });
  }

  return next();
}
