<template>
  <div class="btn-badge">
    <button class="badge badge-pill" :class="classBotao" @click="handleClick" :disabled="disabled">
      <i v-if="icone" class="mr-1" :class="icone"></i>
      <template v-if="titulo">
        <strong :style="{ color: tituloCor }">{{ titulo }}</strong>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseBadgeButton',
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
  props: {
    icone: {
      type: String,
      required: false,
    },
    titulo: {
      type: String,
      required: false,
    },
    tituloCor: {
      type: String,
      required: false,
    },
    bordaCor: {
      type: String,
      required: false,
    },
    classBotao: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style></style>
