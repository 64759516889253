<template>
  <div class="skeleton"></div>
</template>

<script>
export default {
  name: 'BaseSkeleton',
};
</script>

<style scoped>
.skeleton {
  border-radius: 10px;
  width: 100%;
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.4);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.8);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.4);
  }
}
</style>
