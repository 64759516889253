import Vue from 'vue';

const initialState = () => ({
  carregando: false,
  configuracao: null,
  configuracoes: [],
  notificacoes: [],
  totalNotificacoes: 0,
  listaTransmissoes: [],
  ConsumidoresListaTransmissao: {lista:[]},
});

const state = initialState();

const getters = {
  getCarregando(state) {
    return state.carregando;
  },

  getConfiguracao(state) {
    return state.configuracao;
  },

  getConfiguracoes(state) {
    return state.configuracoes;
  },

  getNotificacoes(state) {
    return state.notificacoes;
  },

  getTotalNotificacoes(state) {
    return state.totalNotificacoes;
  },

  getListaTransmissoes(state) {
    return state.listaTransmissoes;
  },

  getConsumidoresListaTransmissao(state) {
    return state.ConsumidoresListaTransmissao;
  },
};

const actions = {
  fetchConfiguracoes({ commit }, { evento, ativa }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/configuracoes`;
      commit('setCarregando', true);

      let data = {};
      if (evento != undefined) data.evento = evento;
      if (ativa != undefined) data.ativa = ativa;

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
        data,
      });

      commit('setCarregando', false);
      if (res.status == 200) {
        commit('setConfiguracoes', res.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Lista de configurações recuperada com sucesso',
          data: res.data.retorno,
        });
      } else {
        commit('setConfiguracoes', []);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição',
        });
      }
    });
  },

  salvarConfiguracao({ commit }, { configuracao }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/configuracao`;
      commit('setCarregando', true);

      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          ...configuracao,
        },
      });

      commit('setCarregando', false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          mensagem: 'Configuração salva com sucesso!',
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição.',
        });
      }
    });
  },

  fetchNotificacoes({ commit }, { campanha, paginacao }) {
    return new Promise(async (resolve, reject) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/notificacoes/${campanha.ano}/${campanha.identificacao}`;

      commit('setCarregando', true);

      const resultado = await comunicacao.send(endpoint, {
        method: 'GET',
        data: {
          paginaAtual: paginacao.atual,
          totalPorPagina: paginacao.quantidade,
        },
      });

      commit('setCarregando', false);

      if (resultado.status === 200) {
        const { lista, total } = resultado.data.retorno;

        const notificacoesPadronizadas = (lista || []).map((notificacao, index) => {
          const indice = (paginacao.atual - 1) * paginacao.quantidade + (index + 1);

          return {
            ...notificacao,
            indice: String(indice).padStart(2, 0),
          };
        });

        commit('setNotificacoes', notificacoesPadronizadas || []);
        commit('setTotalNotificacoes', total || 0);

        return resolve({
          status: 200,
          mensagem: resultado.data.mensagem,
          retorno: {
            total: total,
            lista: notificacoesPadronizadas,
          },
        });
      } else {
        commit('setNotificacoes', []);
        commit('setTotalNotificacoes', 0);

        return reject({
          status: resultado.status || 500,
          mensagem:
            resultado.data.mensagem ||
            'Não foi possível solicitar a listagem das notificações, verifique sua conexão com a internet, e tente novamente',
          retorno: resultado.data.retorno,
        });
      }
    });
  },

  enviarNotificacaoBase(
    { commit },
    { campanha, servico, titulo, mensagem, enviarPor, listasTransmissaoID, dataHoraAgendada, modeloEmail, nomeTemplate, namespace, variaveis }
  ) {
    return new Promise(async (resolve, reject) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/notificacoes/enviarBase`;

      commit('setCarregando', true);

      const resposta = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          campanha,
          servico,
          titulo,
          mensagem,
          enviarPor,
          listasTransmissaoID,
          dataHoraAgendada,
          modeloEmail,
          nomeTemplate,
          namespace,
          variaveis
        },
      });

      commit('setCarregando', false);

      if ([200, 201, 202].includes(resposta.status)) {
        return resolve({
          status: resposta.status,
          mensagem: resposta.data.mensagem,
          retorno: resposta.data.retorno,
        });
      }

      return reject({
        status: resposta.status || 500,
        mensagem: resposta.data.mensagem || 'Ocorreu um problema durante a requisição, tente novamente.',
        retorno: resposta.data.retorno || null,
      });
    });
  },

  fetchConsumidoresListaTransmissao({ commit }, { idListaTransmissao, paginacao, nome }) {
    return new Promise(async (resolve, reject) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/listasTransmissao/${idListaTransmissao}/consumidores/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      commit('setCarregando', true);

      let data = {};
      if (nome != undefined) data.nome = nome;
      // if (ativa !== undefined) data.ativa = ativa;

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
        data,
      });

      commit('setCarregando', false);
      if (res.status == 200) {
        commit('setConsumidoresListaTransmissao', res.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Lista de transmissões listada com sucesso',
          data: res.data.retorno,
        });
      } else {
        commit('setConsumidoresListaTransmissao', []);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição',
        });
      }
    });
  },

  fetchListaTransmissao({ commit }, { ativa }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/listasTransmissao`;
      commit('setCarregando', true);

      let data = {};
      if (ativa !== undefined) data.ativa = ativa;

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
        data,
      });

      commit('setCarregando', false);
      if (res.status == 200) {
        commit('setListaTransmissoes', res.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Lista de transmissões listada com sucesso',
          data: res.data.retorno,
        });
      } else {
        commit('setListaTransmissoes', []);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição',
        });
      }
    });
  },

  cadastrarListaTransmissao({ commit }, { listaTransmissao }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/listasTransmissao`;
      commit('setCarregando', true);

      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          ...listaTransmissao,
        },
      });

      commit('setCarregando', false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 201,
          mensagem: 'Grupo cadastrado com sucesso!',
          retorno: res.data,
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição.',
        });
      }
    });
  },

  alterarListaTransmissao({ commit }, { listaTransmissao, idListaTransmissao }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/listasTransmissao/${idListaTransmissao}`;
      commit('setCarregando', true);

      const res = await comunicacao.send(endpoint, {
        method: 'PATCH',
        data: {
          ...listaTransmissao,
        },
      });

      commit('setCarregando', false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          mensagem: 'Grupo salvo com sucesso!',
          retorno: res.data,
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição.',
        });
      }
    });
  },

  alterarNotificacao(
    { commit },
    { id, campanha, titulo, mensagem, servico, enviarPor, listasTransmissaoID, dataHoraAgendada, modeloEmail, nomeTemplate, namespace, variaveis }
  ) {
    return new Promise(async (resolve, reject) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/notificacoes/${id}`;
      commit('setCarregando', true);

      const resposta = await comunicacao.send(endpoint, {
        method: 'PATCH',
        data: {
          campanha,
          titulo,
          mensagem,
          servico,
          enviarPor,
          listasTransmissaoID,
          dataHoraAgendada,
          modeloEmail,
          nomeTemplate,
          namespace,
          variaveis
        },
      });

      commit('setCarregando', false);

      if ([200, 201, 202].includes(resposta.status)) {
        return resolve({
          status: resposta.status,
          mensagem: resposta.data.mensagem,
          retorno: resposta.data.retorno,
        });
      }

      return reject({
        status: resposta.status || 500,
        mensagem: resposta.data.mensagem || 'Ocorreu um problema durante a requisição, tente novamente.',
        retorno: resposta.data.retorno || null,
      });
    });
  },

  excluirNotificacao({ commit }, { id }) {
    return new Promise(async (resolve, reject) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/notificacoes/${id}`;
      commit('setCarregando', true);

      const resposta = await comunicacao.send(endpoint, {
        method: 'DELETE',
      });

      commit('setCarregando', false);
      if ([200, 201, 202].includes(resposta.status)) {
        return resolve({
          status: 200,
          mensagem: 'Notificação excluída com sucesso!',
          retorno: resposta.data,
        });
      }

      return reject({
        status: resposta.status || 500,
        mensagem: resposta.data.mensagem || 'Ocorreu um problema durante a requisição, tente novamente.',
        retorno: resposta.data.retorno || null,
      });
    });
  },

  popularListaTransmissao({ commit }, { idListaTransmissao, listaPersonalizada }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/notificacao/v1/listasTransmissao/${idListaTransmissao}/popular`;
      commit('setCarregando', true);

      const res = await comunicacao.send(endpoint, {
        method: 'PATCH',
        data: {
          listaPersonalizada,
        },
      });

      commit('setCarregando', false);
      if ([202].includes(res.status)) {
        resolve({
          status: 202,
          mensagem: res.data.mensagem,
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição.',
        });
      }
    });
  },
};

const mutations = {
  setCarregando(state, data) {
    state.carregando = data;
  },

  setConfiguracoes(state, data) {
    state.configuracoes = data;
  },

  setConfiguracao(state, data) {
    state.configuracao = data;
  },

  setNotificacoes(state, data) {
    state.notificacoes = data;
  },

  setTotalNotificacoes(state, data) {
    state.totalNotificacoes = data;
  },

  setListaTransmissoes(state, data) {
    state.listaTransmissoes = data;
  },

  setConsumidoresListaTransmissao(state, data) {
    state.ConsumidoresListaTransmissao = data;
  },
};

export default { namespaced: true, state, getters, actions, mutations };
