import Vue from 'vue';

const initialState = () => ({
  arquivos: null,
  tipos: null,
  totalArquivos: 0,
  carregando: false,
});

const state = initialState();

const getters = {
  getArquivos(state) {
    return state.arquivos;
  },
  getTotalArquivos(state) {
    return state.totalArquivos;
  },
  getTipos(state) {
    return state.tipos;
  },
  getCarregando(state) {
    return state.carregando;
  },
};

const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  listarArquivos({ commit }, { tipo = undefined }) {
    return new Promise(async resolve => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/servicoExportador/v1/arquivo/listar`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          tipo: tipo
        }
      })

      commit('setCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        commit('setArquivos', resposta.data.retorno.lista.reverse());
        commit('setTipos', resposta.data.retorno.tipos);
        resolve({ status: 200, mensagem: 'Arquivos obtidos com sucesso' })
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem })
      }
    })
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setArquivos(state, data) {
    state.arquivos = data;
    state.totalArquivos = data.length;
  },
  setTipos(state, data) {
    state.tipos = data;
  },
  setCarregando(state, data) {
    state.carregando = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
