import Vue from 'vue'

const initialState = () => ({
  associados: [],
  carregando: false,
})

const state = initialState()

const getters = {
  getAssociados(state) {
    return state.associados;
  },
  getLoader(state) {
    return state.carregando;
  },
}

const actions = {
  fetchAssociados({ commit }, { paginacao }) {
    return new Promise(async resolve => {
      commit("setLoader", true)

      const comunicacao = new Vue.prototype.$comunicacao()
      const url = '/associado/v1/associados/listarAssociados'

      const response = await comunicacao.send(url, {
        method: 'POST', data: {
          paginacao
        }
      })

      commit('setLoader', false)

      if (response.status == 200) {
        commit('setAssociados', response.data.retorno.retorno)

        resolve({
          status: 200,
          msg: "Associados obtidos com sucesso.",
          data: response.data.retorno.retorno
        })
      } else {
        commit('setAssociados', [])
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro ao obter associados',
          data: []
        })
      }
    })
  },
}

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
  setAssociados(state, data) {
    state.associados = data
  },
  setLoader(state, data) {
    state.carregando = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}