import Vue from 'vue';

const initialState = () => ({
  premiacoes: [],
  loading: false,
  carregandoImportacao: false,
  totalPremiacoes: 0,
  totalPremiacoesNaoPaginadaRetrocompatibilidade: false,
});

const state = initialState();

const getters = {
  getPremiacoes(state) {
    return state.premiacoes;
  },

  getLoadingState(state) {
    return state.loading;
  },

  getCarregandoImportacao(state) {
    return state.carregandoImportacao;
  },

  getTotalPremiacoes(state) {
    return state.totalPremiacoes;
  },

  getTotalPremiacoesNaoPaginadaRetrocompatibilidade(state) {
    return state.totalPremiacoesNaoPaginadaRetrocompatibilidade;
  },
};

const actions = {
  fetchPremiacoes({ commit }, { campanha, paginacao }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      let endpoint;
      if (paginacao) {
        endpoint = `/raspadinhaAlternativa/v1/tabelaPremiacao/${campanha.ano}/${campanha.identificacao}/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      } else {
        endpoint = `/raspadinhaAlternativa/v1/tabelaPremiacao/${campanha.ano}/${campanha.identificacao}/0/0`;
      }

      commit('setLoadingState', true);

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });
      if (res.status == 200) {
        commit('setLoadingState', false);
        commit('setPremiacoes', res.data.retorno.retorno);
        commit('setTotalPremiacoes', res.data.retorno.total);
        commit('setTotalPremiacoesNaoPaginadaRetrocompatibilidade', false);
        resolve({
          status: 200,
          mensagem: 'Lista de premiações recuperada com sucesso',
          premios: res.data.retorno.retorno,
        });
      } else {
        // Ajuste para retrocompatibilidade ---------
        if (res.status === 404) {
          const res2 = await comunicacao.send(
            `/raspadinhaAlternativa/v1/tabelaPremiacao/${campanha.ano}/${campanha.identificacao}`,
            {
              method: 'GET',
              cache: false,
            }
          );
          if (res2.status === 200) {
            commit('setLoadingState', false);
            commit('setPremiacoes', res2.data.retorno || []);
            commit('setTotalPremiacoes', res2.data.retorno ? res2.data.retorno.length : 0);
            commit('setTotalPremiacoesNaoPaginadaRetrocompatibilidade', true);
            return resolve({
              status: 200,
              mensagem: 'Lista de premiações recuperada com sucesso',
              premios: res2.data.retorno,
            });
          } else {
            commit('setLoadingState', false);
            commit('setPremiacoes', []);
            commit('setTotalPremiacoes', 0);
            commit('setTotalPremiacoesNaoPaginadaRetrocompatibilidade', false);
            return resolve({
              status: res2.status || 500,
              mensagem:
                res2.data.mensagem || 'Ocorreu um problema durante a requisição para obter a lista de premiações',
            });
          }
        }
        // Fim de Ajuste para retrocompatibilidade ---------

        commit('setLoadingState', false);
        commit('setPremiacoes', []);
        commit('setTotalPremiacoes', 0);
        commit('setTotalPremiacoesNaoPaginadaRetrocompatibilidade', false);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para obter a lista de premiações',
        });
      }
    });
  },

  inserirLotePremiacao({ commit }, { campanha, premiacoes }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      commit('setCarregandoImportacao', true);
      const endpoint = `/raspadinhaAlternativa/v1/loteTabelaPremiacao/`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          campanha,
          premiacoes,
        },
      });

      commit('setCarregandoImportacao', false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          mensagem: 'Lista de premiações inserida com sucesso',
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para inserir a lista de premiações',
        });
      }
    });
  },

  inserirPremiacao({ commit }, { campanha, premiacao }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      commit('setLoadingState', true);
      const endpoint = `/raspadinhaAlternativa/v1/tabelaPremiacao/`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          campanha,
          textoOculto: premiacao.textoOculto,
          cnpjLoja: premiacao.cnpjLoja,
          dataHora: premiacao.dataHora,
          codigoPremio: premiacao.premio.codigo,
        },
      });

      commit('setLoadingState', false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          mensagem: 'Premiação inserida com sucesso!',
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para inserir a premiação.',
        });
      }
    });
  },

  atualizarPremiacao({ commit }, { campanha, premiacao }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      commit('setLoadingState', true);
      const endpoint = `/raspadinhaAlternativa/v1/tabelaPremiacao/`;
      const res = await comunicacao.send(endpoint, {
        method: 'PUT',
        data: {
          id: premiacao.id,
          textoOculto: premiacao.textoOculto,
          campanha,
          cnpjLoja: premiacao.cnpjLoja,
          dataHora: premiacao.dataHora,
          premio: {
            codigo: premiacao.premio.codigo,
            descricao: premiacao.premio.descricao,
            valor: premiacao.premio.valor,
            cashback: premiacao.premio.cashback,
          },
        },
      });

      commit('setLoadingState', false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          mensagem: 'Premiação alterada com sucesso!',
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para atualizar a premiação.',
        });
      }
    });
  },

  removerPremiacao({ commit }, { campanha, id }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      commit('setLoadingState', true);
      const endpoint = `/raspadinhaAlternativa/v1/tabelaPremiacao/${id}`;
      const res = await comunicacao.send(endpoint, {
        method: 'DELETE',
        data: {
          campanha,
        },
      });

      commit('setLoadingState', false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          mensagem: 'Premiação removida com sucesso!',
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para remover a premiação.',
        });
      }
    });
  },
};

const mutations = {
  setLoadingState(state, data) {
    state.loading = data;
  },

  setPremiacoes(state, data) {
    state.premiacoes = data;
  },

  setCarregandoImportacao(state, data) {
    state.carregandoImportacao = data;
  },

  setTotalPremiacoes(state, data) {
    state.totalPremiacoes = data;
  },

  setTotalPremiacoesNaoPaginadaRetrocompatibilidade(state, data) {
    state.totalPremiacoesNaoPaginadaRetrocompatibilidade = data;
  },
};

export default { namespaced: true, state, getters, actions, mutations };
