const axios = require('axios');

const api = axios.create({
  baseURL: 'https://config.commercemaster.com.br',
  // baseURL: 'https://4o6ljp0xe0.execute-api.us-east-1.amazonaws.com/v1',
  headers: {
    Authorization: 'f3435c58c4dff86119a07d9ec88cc96a78c2dd65'
  }
});

module.exports = api;