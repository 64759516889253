import Vue from 'vue';

const initialState = () => ({
  metas: [],
  totalRegistros: 0,
  infoImportacao: null,
  carregando: false,
});

const state = initialState();

const getters = {
  getTotalizador(state) {
    return state.totalRegistros;
  },
  getLoader(state) {
    return state.carregando;
  },
  getMetas(state) {
    return state.metas;
  },
  getInfoImportacao(state) {
    return state.infoImportacao;
  },
};

const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  fetchMetas({ commit }, { campanha, paginacao, cnpjCpf }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/contacorrente/v1/metas/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, { method: 'POST', data: { cnpjCpf } });

      commit('switchCarregando', false);
      if ([200, 201].includes(res.status)) {
        commit('setMetas', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Lista de metas obtida com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setMetas', []);
        resolve({ status: 404, msg: 'Não foi encontrado metas' });
      }
    });
  },

  importarListaMetas({ commit }, { campanha, metas }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `contacorrente/v1/metas/importar/${campanha.ano}/${campanha.identificacao}`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          metas
        }
      })

      commit('switchCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        commit('setInfoImportacao', {
          inseridos: resposta.data.retorno.inseridos,
          alterados: resposta.data.retorno.alterados
        })
        resolve({ status: 201, mensagem: 'Lista de metas importada com sucesso', retorno: resposta.data.retorno })
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem, retorno: resposta.data.retorno })
      }
    })
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setMetas(state, data) {
    state.metas = data;
  },
  setTotalizador(state, data) {
    state.totalRegistros = data;
  },
  setInfoImportacao(state, data) {
    state.infoImportacao = data;
  },
  switchCarregando(state, data) {
    state.carregando = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
