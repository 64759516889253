import Badge from '../components/Badge';
import BaseAlert from '../components/BaseAlert';
import BaseButton from '../components/BaseButton';
import BaseCheckbox from '../components/BaseCheckbox';
import BaseInput from '../components/BaseInput';
import BaseDropdown from '../components/BaseDropdown';
import BaseNav from '../components/BaseNav';
import BasePagination from '../components/BasePagination';
import BaseProgress from '../components/BaseProgress';
import BaseRadio from '../components/BaseRadio';
import BaseSlider from '../components/BaseSlider';
import BaseSwitch from '../components/BaseSwitch';
import BaseTable from '../components/BaseTable';
import BaseHeader from '../components/BaseHeader';
import Card from '../components/Card';
import CardBaseCredit from '../components/CardBaseCredit';
import CardStatsWithIcon from '../components/CardStatsWithIcon';
import StatsCard from '../components/StatsCard';
import StatsProgressBar from '../components/StatsProgressBar';
import Modal from '../components/Modal';
import TabPane from '../components/Tabs/TabPane';
import Tabs from '../components/Tabs/Tabs';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { BCollapse } from 'bootstrap-vue';

// new components
import BaseButtonNew from '../components/ui/BaseButtonNew';
import BaseBadgeButton from '../components/ui/BaseBadgeButton';
import BaseSkeleton from '../components/ui/BaseSkeleton';
import ModalNew from '../components/ui/ModalNew';
import BaseLista from '../components/ui/BaseLista';
import BaseTitulo from '../components/ui/BaseTitulo';
import BaseCardInformacao from '../components/ui/BaseCardInformacao';
import BaseSubtitulo from '../components/ui/BaseSubtitulo';
import BaseRanking from '../components/ui/BaseRanking';
import BasePaginacao from '../components/ui/BasePaginacao';
import BaseExibicaoPorPagina from '../components/ui/BaseExibicaoPorPagina';
import BaseInputSenhaTemporaria from '../components/ui/BaseInputSenhaTemporaria';

export default {
  install(Vue) {
    Vue.component('flat-picker', flatPickr);
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSlider.name, BaseSlider);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(BaseTable.name, BaseTable);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(Card.name, Card);
    Vue.component(CardBaseCredit.name, CardBaseCredit);
    Vue.component(CardStatsWithIcon.name, CardStatsWithIcon);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(StatsProgressBar.name, StatsProgressBar);
    Vue.component(Modal.name, Modal);
    Vue.component(TabPane.name, TabPane);
    Vue.component(Tabs.name, Tabs);
    Vue.component('BCollapse', BCollapse);
    Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);

    //new
    Vue.component(BaseButtonNew.name, BaseButtonNew);
    Vue.component(BaseBadgeButton.name, BaseBadgeButton);
    Vue.component(BaseSkeleton.name, BaseSkeleton);
    Vue.component(ModalNew.name, ModalNew);
    Vue.component(BaseLista.name, BaseLista);
    Vue.component(BaseTitulo.name, BaseTitulo);
    Vue.component(BaseCardInformacao.name, BaseCardInformacao);
    Vue.component(BaseSubtitulo.name, BaseSubtitulo);
    Vue.component(BaseRanking.name, BaseRanking);
    Vue.component(BasePaginacao.name, BasePaginacao);
    Vue.component(BaseExibicaoPorPagina.name, BaseExibicaoPorPagina);
    Vue.component(BaseInputSenhaTemporaria.name, BaseInputSenhaTemporaria);
  },
};
