import Vue from 'vue'

const initialState = () => ({
  modelos: {
    paginas: 0,
    total: 0,
    lista: []
  },
})

const state = initialState()

const getters = {
  obterModelos(estado) {
    return estado.modelos;
  },
}

const actions = {
  buscarModelos({ commit }, { paginacao, filtros }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao()
      const url = `/servicoEmail/v1/modelos/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`

      const resposta = await comunicacao.send(url, {
        method: 'GET',
        data: filtros
      });

      if (resposta.status !== 200) {
        commit('setModelos', {
          paginas: 0,
          total: 0,
          lista: []
        })

        return resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Ocorreu um erro ao obter modelos',
        });
      }

      commit('setModelos', {
        paginas: resposta.data.retorno.paginas,
        total: resposta.data.retorno.total,
        lista: resposta.data.retorno.lista
      });

      return resolve({
        status: 200,
        msg: "Modelos obtidos com sucesso.",
      });
    })
  },

  salvarModelo({ commit }, { modelo }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao()
      const url = `/servicoEmail/v1/modelos`

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: modelo
      });

      return resolve({
        status: resposta.status || 500,
        msg: resposta.data.mensagem || 'Ocorreu um erro ao salvar modelo',
      });
    });
  },

  editarModelo({ commit }, { modelo }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao()
      const url = `/servicoEmail/v1/modelos`

      const resposta = await comunicacao.send(url, {
        method: 'PUT',
        data: modelo
      });

      return resolve({
        status: resposta.status || 500,
        msg: resposta.data.mensagem || 'Ocorreu um erro ao editar modelo',
      });
    });
  }
}

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },

  setModelos(state, data) {
    state.modelos = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
