const campanhaKey = 'campanha@DashboardACIMG'

/**
 * Classe para salvar e recuperar campanha do local storage quando necessário.
 */
class Campanha {
    /**
     * Construtor vazio.
     */
    constructor() {}
    /**
     * Grava a campanha informada no LocalStorage da aplicação.
     * @param {object} campanha 
     */
    static gravarCampanhaAtual (campanha) {
        let campanhaAtual = JSON.stringify(campanha);
        localStorage.setItem(campanhaKey, campanhaAtual);
    }
    /**
     * Retorna a campanha atual se ele estiver no LocalStorage da aplicação.
     */
    static obterCampanhaAtual () {
        if (localStorage.getItem(campanhaKey)) {
            return JSON.parse(localStorage.getItem(campanhaKey));
        } else {
            return null;
        }
    }
    /**
     * Remover a campanha atual do LocalStorage da aplicação.
     */
    static removerCampanhaAtual () {
        if (localStorage.getItem(campanhaKey)) {
            localStorage.removeItem(campanhaKey);
            return true;
        } else {
            return false;
        }
    }
}

export default Campanha;