var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dados.condicao(_vm.configuracoes))?_c('div',[(_vm.dados.paginas.length)?[_c('li',{staticClass:"nav-item",on:{"click":_vm.toggle}},[_c('a',{staticClass:"nav-link cursor-pointer"},[_c('i',{class:_vm.dados.icone}),_c('span',{staticClass:"nav-link-text",staticStyle:{"flex-grow":"1"}},[_vm._v(_vm._s(_vm.dados.titulo))]),_c('i',{staticClass:"text-right",class:{
            'fas fa-chevron-down': !_vm.ativo,
            'fas fa-chevron-up': _vm.ativo,
          }})])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.ativo),expression:"ativo"}],staticClass:"sidebar-collapse"},_vm._l((_vm.paginasFiltradas),function(pagina,idx){return _c('sidebar-item',{key:idx,attrs:{"link":{
          name: pagina.titulo(_vm.configuracoes),
          icon: ((pagina.icone) + " text-dark"),
          path: ("/" + (pagina.caminho)),
        }}})}),1)]:[_c('sidebar-item',{attrs:{"link":{
        name: _vm.dados.titulo,
        icon: ((_vm.dados.icone) + " text-dark"),
        path: ("/" + (_vm.dados.caminho)),
      }}})]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }