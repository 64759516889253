<template>
  <div id="app">
    <notifications v-if="this.$autenticacao.logado()"></notifications>
    <router-view :instancias="instancias" />
  </div>
</template>

<script>
/* eslint-disable no-console */
// Comentário para deploy na S3 Amplify
// import VueSocketIO from 'vue-socket.io';
// import io from 'socket.io-client';

// import VueSocketIOExt from 'vue-socket.io-extended'
// import io from 'socket.io-client'

// const socket = io('https://testechat.qrsorteios.com.br')
// const socket = io('http://172.20.48.1:3000')

// Vue.use(VueSocketIOExt, socket)

import Vue from 'vue';
// import VueSocketIO from 'vue-socket.io';
const VueGoogleMaps = require('vue2-google-maps');

export default {
  data: () => ({
    configuracoes: null,
    instancias: null,
    teste: null,
    conectado: false,
  }),
  beforeMount: function () {
    this.configuracoes = this.$storeConfig.configuracoes();
    this.instancias = this.$storeConfig.instancias();
    if (this.configuracoes.GERAL_TITULO) {
      document.title = this.configuracoes.GERAL_TITULO;
    }
    //Identifica ambiente de produção ou de teste
    let wsUrl = window.location.href.startsWith(this.configuracoes.VUE_APP_PROD_BASE_URL)
      ? this.configuracoes.VUE_APP_PROD_WS_URL
      : this.configuracoes.VUE_APP_DEV_WS_URL;

    new this.$comunicacao(wsUrl, this.$autenticacao.logado().token);

    const CHAVE_API_GOOGLE_MAPS = this.configuracoes.CHAVE_API_GOOGLE_MAPS;

    const GoogleMaps = (Vue) => {
      Vue.use(VueGoogleMaps, {
        load: {
          key: CHAVE_API_GOOGLE_MAPS,
          libraries: 'visualization',
        },
      });
    };

    Vue.use({
      install: GoogleMaps,
    });
  },
  mounted: function () {
    // this.$notify("teste");
    // if (this.$socket) {
    // setInterval(() => {
    //   if (!this.$socket.connected) {
    //     console.log('recarregando')
    //     this.$socket.connect();
    //   }
    // }, 1500)
    // }
  },
};
</script>
