<template>
  <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>

      <router-link
        class="navbar-brand"
        to=""
        v-if="this.tipoUsuario == 'lojista' || this.tipoUsuario == 'rede' || this.tipoUsuario == 'industria'"
      >
        <img :src="configuracoes.CLIENTE_IMAGEM_LOGO_DARK" class="navbar-brand-img" alt="..." />
      </router-link>

      <router-link class="navbar-brand" to="/" v-else>
        <img :src="configuracoes.CLIENTE_IMAGEM_LOGO_DARK" class="navbar-brand-img" alt="..." />
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <!-- Barra de notificações -->
          <base-dropdown class="nav-item d-none" position="right">
            <a
              slot="title"
              class="nav-link nav-link-icon"
              href="javascript:void(0)"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ni ni-bell-55"></i>
            </a>
            <a class="dropdown-item" href="javascript:void(0)">Não há notificações!</a>
          </base-dropdown>
          <!-- Menu de ações -->
          <base-dropdown class="nav-item" position="right">
            <a slot="title" class="nav-link" href="javascript:void(0)" role="button">
              <div class="media align-items-center">
                <span class="d-none avatar avatar-sm rounded-circle">
                  <!--<img 
                                  alt="Image placeholder"
                                  src="https://www.pena.com.br/wp-content/uploads/2017/05/user-icon.jpg"
                                >-->
                </span>
                <div class="media-body ml-2 d-lg-none d-sm-block">
                  <span class="mb-0 texto-verde text-sm font-weight-bold">Minha conta</span>
                </div>
              </div>
            </a>
            <!-- Título da sessão -->
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Bem Vindo: {{ this.nome }}</h6>
            </div>
            <!-- Item da sessão -->
            <div
              v-if="paginaAtual === '/cashbacklight' && this.dataHoraFinalCampanha && this.diasRestantes >= 0"
              class="dias-restantes"
              :class="{ 'dias-restantes--acabando': this.diasRestantes <= 3 }"
            >
              <span>Dias restantes: {{ this.diasRestantes }}</span>
            </div>
            <router-link to="/" class="dropdown-item" v-on:click.native="logout">
              <i class="ni ni-user-run"></i><span>Sair</span>
            </router-link>
          </base-dropdown>
        </ul>
      </slot>

      <slot></slot>

      <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="configuracoes.CLIENTE_IMAGEM_LOGO_DARK" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
            </div>
          </div>
        </div>

        <div
          v-if="paginaAtual === '/cashbacklight' && this.dataHoraFinalCampanha && this.diasRestantes >= 0"
          class="dias-restantes"
          :class="{ 'dias-restantes--acabando': this.diasRestantes <= 3 }"
        >
          <span>Dias restantes: {{ this.diasRestantes }}</span>
        </div>

        <!-- Selecionar serviço -->
        <slot name="selecionarServico"></slot>

        <!-- Links -->
        <ul class="navbar-nav menu-principal">
          <slot name="links"> </slot>
        </ul>

        <!-- Divider -->
        <hr class="my-3" />

        <!-- Selecionar campanha ativa -->
        <slot name="selecionarCampanha"></slot>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from '@/components/NavbarToggleButton';
import { mapActions } from 'vuex';

export default {
  name: 'sidebar',
  components: {
    NavbarToggleButton,
  },
  props: {
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  mounted() {
    if (this.paginaAtual === '/cashbacklight' && this.tipoUsuario === 'lojista') {
      this.carregarCampanha();
    }
  },
  data() {
    return {
      configuracoes: null,
      tipoUsuario: null,
      paginaAtual: window.location.pathname,
      dataHoraFinalCampanha: '',
    };
  },
  computed: {
    diasRestantes() {
      const currentDate = new Date();
      const dataFinal = new Date(this.dataHoraFinalCampanha);

      return Math.floor((dataFinal - currentDate) / (1000 * 60 * 60 * 24));
    },
  },
  beforeMount() {
    this.configuracoes = this.$storeConfig.configuracoes();
    this.tipoUsuario = this.$autenticacao.logado().tipo;
    this.usuario = this.$autenticacao.logado().usuario;
    this.nome = this.$autenticacao.logado().nome;
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    ...mapActions({
      fetchCampanhasCashback: 'Cashback/fetchCampanhasCashback',
    }),
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      //localStorage.clear();
      localStorage.removeItem('login');
      localStorage.removeItem('campanha@DashboardACIMG');
      localStorage.removeItem('servicos@DashboardQRSorteios');

      this.$router.push({
        name: 'login',
      });
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    async carregarCampanha() {
      const { data } = await this.fetchCampanhasCashback({
        paginacao: {
          totalPorPagina: -1,
          paginaAtual: 1,
        },
        data: {
          listaAssociadoCNPJ: this.usuario,
          listaTipos: 'COMPRA',
          retornarListaProdutos: true,
        },
      });

      this.dataHoraFinalCampanha = data.retorno[0].dataHoraFinal;
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-principal{
  max-height: 50vh !important;
}

.texto-verde {
  color: #137b6c;
}
.border-cinza {
  border: 1px solid #eeeeee !important;
}

.dias-restantes {
  padding: 0.25rem 0.5rem;
  background: #00a4ff;
  display: flex;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.dias-restantes span {
  width: 100%;
  color: #fff;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.dias-restantes--acabando {
  background: #ff0000;
}
</style>
