import Vue from 'vue';

// Object initial state
const initialState = () => ({
  carregando: false,
  pedidosVoucher: null,
  totalPedidosVoucher: 0,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getCarregando(state) {
    return state.carregando;
  },

  getPedidosVoucher(state) {
    return state.pedidosVoucher;
  },

  getTotalPedidosVoucher(state) {
    return state.totalPedidosVoucher;
  },
};

// Actions
const actions = {
  listarPedidosVoucher({ commit }, { paginacao, filtros = {} }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `fidelidade/v1/pedidosVoucher/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const res = await comunicacao.send(endpoint, { method: 'GET', data: filtros });

      if ([200, 201, 404].includes(res.status)) {
        commit('setPedidosVoucher', res.data.retorno.retorno);
        commit('setTotalPedidosVoucher', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Lista de pedidos obtida com sucesso!',
          total: res.data.retorno.total,
          pedidos: res.data.retorno.retorno,
        });
      } else {
        commit('setPedidosVoucher', []);
        commit('setTotalPedidosVoucher', 0);
        resolve({
          status: res.status || 500,
          msg: 'Erro desconhecido ao listar os pedidos de voucher. Tente novamente.',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setCarregando(state, data) {
    state.carregando = data;
  },

  setPedidosVoucher(state, data) {
    state.pedidosVoucher = data;
  },

  setTotalPedidosVoucher(state, data) {
    state.totalPedidosVoucher = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
