<template>
  <li class="nav-item">
    <router-link
      :to="link.path"
      @click.native="linkClick"
      class="nav-link"
      :target="link.target"
      :href="link.path"
      v-if="!link.anotherUrl"
    >
      <template>
        <i :class="link.icon"></i>
        <span class="nav-link-text" :style="link.notificacao > 0 ? 'flex-grow: 1;' : ''">{{
          link.name.includes("Cashback") ? link.name.replace("Cashback", "Pontos") : link.name
        }}</span>
        <div v-if="link.notificacao > 0" class="qtde-notificacao">{{ link.notificacao }}</div>
      </template>
    </router-link>
    <a class="nav-link" target="blank" :href="link.path" v-else>
      <template>
        <i :class="link.icon"></i>
        <span class="nav-link-text" :style="link.notificacao > 0 ? 'flex-grow: 1;' : ''">{{
          link.name
        }}</span>
        <div v-if="link.notificacao > 0" class="qtde-notificacao">{{ link.notificacao }}</div>
      </template>
    </a>
  </li>
</template>

<script>
export default {
  name: 'sidebar-item',
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: [],
          anotherUrl: false,
          notificacao: 0,
        };
      },
      description:
        'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info',
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  methods: {
    linkClick() {
      if (this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
