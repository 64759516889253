import Vue from 'vue'

// Object initial state
const initialState = () => ({
  redes: [],
  totalRedes: 0,
  carregando: false,
  erro: null,
  totalPaginas: 0,
  rede: {}
})

// State object
const state = initialState()

// Getter object
const getters = {
  getRede(state) {
    return state.rede
  },

  getRedes(state) {
    return state.redes
  },

  getTotalizador(state) {
    return state.totalRedes
  },

	getPaginas(state) {
		return state.totalPaginas;
  },
  
  getLoader(state) {
    return state.carregando
  },

  getErro(state) {
    return state.erro
  },
}

// Actions
const actions = {
  listarRedes({ commit }, { paginacao }) {
    return new Promise(async resolve => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = `/associado/v1/redes/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`
      const res = await comunicacao.send(endpoint, {
        method: 'get'
      })
      if ([200, 201].includes(res.status)) {
        commit('setRedes', res.data.retorno.retorno)
        commit('setTotalizador', res.data.retorno.total)
				commit('setPaginas', res.data.retorno.paginas);
        commit('setErro', null)
      } else if ([404].includes(res.status)) {
        commit('setRedes', [])
        commit('setTotalizador', 0)
        commit('setErro', null)
      } else {
        commit('setTotalizador', 0)
        commit(
          'setErro',
          res.data.mensagem ||
            'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        )
      }
      commit('setLoader', false)
      resolve()
    })
  },
  listarRedesFiltrada({ commit }, { nomeFantasia }) {
    return new Promise(async resolve => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = `/associado/v1/redes/${nomeFantasia}`
      const res = await comunicacao.send(endpoint, {
        method: 'get'
      })
      if ([200, 201].includes(res.status)) {
        commit('setRedes', res.data.retorno.retorno)
        commit('setErro', null)
				resolve({ status: 200, mensagem: res.data.mensagem });
      } else if ([404].includes(res.status)) {
        commit('setRedes', [])
        commit('setTotalizador', 0)
        commit('setErro', null)
				resolve({ status: 404, mensagem: res.data.mensagem });
      } else {
        commit(
          'setErro',
          res.data.mensagem ||
            'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        )
      resolve()
      }
      commit('setLoader', false)
    })
  },
  obterRede({ commit }, { cnpjCpf }) {
    return new Promise(async resolve => {
      commit('setLoader', true)
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = `/associado/v1/rede/${cnpjCpf}`
      const res = await comunicacao.send(endpoint, {
        method: 'get'
      })
      if ([200, 201].includes(res.status)) {
        commit('setRede', res.data.retorno)
        commit('setErro', null)
      } else if ([404].includes(res.status)) {
        commit('setRede', {})
        commit('setErro', null)
      } else {
        commit(
          'setErro',
          res.data.mensagem ||
            'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        )
      }
      commit('setLoader', false)
      resolve()
    })
  },
  cadastrarRede({ commit }, { cnpjCpf, razaoSocial, nomeFantasia, senhaBI, urlFotoRede }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = '/associado/v1/redes'
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { 
          cnpjCpf,
          razaoSocial,
          nomeFantasia,
          senhaBI,
          urlFotoRede
         },
      })
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false)
        resolve({ status: 200, mensagem: 'Rede cadastrada com sucesso' })
      } else {
        let msgErro = ''
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i]
          }
        } else {
          msgErro = res.data.mensagem
        }
        commit('setLoader', false)
        resolve({ status: res.status || 500, mensagem: msgErro })
      }
    })
  },
  alterarRede({ commit }, { cnpjCpf, razaoSocial, nomeFantasia, senhaBI, urlFotoRede }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao()
      const endpoint = '/associado/v1/redes'
      const res = await comunicacao.send(endpoint, {
        method: 'PUT',
        data: { 
          cnpjCpf,
          razaoSocial,
          nomeFantasia,
          senhaBI,
          urlFotoRede
        },
      })
      if ([200, 201].includes(res.status)) {
        commit('setLoader', false)
        resolve({ status: 200, mensagem: 'Rede alterada com sucesso' })
      } else {
        let msgErro = ''
        if (res.data.retorno) {
          for (let i in res.data.retorno) {
            msgErro += '<br/>' + res.data.retorno[i]
          }
        } else {
          msgErro = res.data.mensagem
        }
        commit('setLoader', false)
        resolve({ status: res.status || 500, mensagem: msgErro })
      }
    })
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  setRedes(state, data) {
    state.redes = data
  },

  setRede(state, data) {
    state.rede = data
  },

  setTotalizador(state, data) {
    state.totalRedes = data
  },

  setLoader(state, data) {
    state.carregando = data
  },

	setPaginas(state, data) {
		state.totalPaginas = data;
	},

  setErro(state, data) {
    state.erro = data
  },
}

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
