<template>
  <div class="base-title text-uppercase" :class="{ strip: isStrip }">
    <template v-if="title">
      <h1 class="title">{{ title }}</h1>
    </template>

    <template v-else><slot /></template>
  </div>
</template>

<script>
export default {
  name: 'BaseTitulo',
  props: {
    title: {
      required: false,
      type: String,
    },
    isStrip: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-title {
  background-color: #055ba3;
  padding: 0.125rem 3rem;
  border-radius: 0 0.5rem 0.5rem 0;
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  float: left;
  max-height: 44px;

  > .title {
    color: #fff;
    font-size: 1.625rem;
    margin-bottom: 0;
  }

  &.strip {
    margin-left: -9px;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 6px 8px;
      border-color: transparent transparent #073157 transparent;
      left: 0;
      top: -6px;
    }
  }
}
</style>