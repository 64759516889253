import Vue from 'vue';

const initialState = () => ({});

const state = initialState();

const getters = {};

const actions = {
  salvarConfiguracao(
    _,
    {
      campanha,
      quantidade,
      valor,
      valorGeral,
      multiplicador,
      formasPagamento,
      associados,
      quantidadeLimiteCuponsGerados,
      produtos = [],
    }
  ) {
    return new Promise(async (resolve) => {
      const data = {
        campanha: { ano: campanha.ano, identificacao: campanha.identificacao },
        quantidade,
        valor,
        valorGeral,
        multiplicador,
        produtos,
        formasPagamento,
        associados,
        quantidadeLimiteCuponsGerados,
      };

      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/cupom/v2/cupons/configuracoesCompra';
      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data,
      });

      if ([200, 201].includes(resposta.data.status)) {
        resolve({
          status: 200,
          mensagem: 'Configuração salva com sucesso',
        });
      } else {
        resolve({
          status: resposta.data.status,
          mensagem: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição',
        });
      }
    });
  },

  obterConfiguracao(_, { campanha }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cupom/v2/cupons/configuracaoCompra/${campanha.ano}/${campanha.identificacao}`;

      const resposta = await comunicacao.send(url, {
        method: 'GET',
      });

      if (resposta.data.status == 200) {
        resolve({
          status: 200,
          mensagem: 'Configuração obtida com sucesso',
          dados: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.data.status,
          mensagem: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição',
        });
      }
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
