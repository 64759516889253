import Vue from 'vue'

// Object initial state
const initialState = () => ({
  configuracoes: {},
  versaoServidor: null,
})

// State object
const state = initialState()

// Getter object
const getters = {
  getConfig(state) {
    return state.configuracoes;
  },

  getVersaoServidor(state) {
    return state.versaoServidor
  }
}

// Actions
const actions = {
  fetchConfig({ commit }) {
    const configuracoes = new Vue.prototype.$storeConfig.configuracoes();
    commit('setConfig', configuracoes);
  },

  async obterVersaoServidor({ commit }) {
    const comunicacao = new Vue.prototype.$comunicacao();

    const res = await comunicacao.send('/', {
      method: 'GET'
    });

    if (res.status === 200) {
      commit('setVersaoServidor', res.data.retorno.versao);
    }
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
  setConfig(state, data) {
    state.configuracoes = data;
  },
  setVersaoServidor(state, data) {
    state.versaoServidor = data;
  },
}

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
