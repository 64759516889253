import Vue from 'vue';

// Object initial state
const initialState = () => ({
  compras: [],
});

// State object
const state = initialState();

// Getter object
const getters = {
  getCompras(state) {
    return state.compras;
  },
};

// Actions
const actions = {
  cadastrarCompras({ commit }, { campanha, dados, numeroDigitado = null, produtosServicos = [] }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/documentoFiscal/v1/documentos/inserirNaoFiscal`;
      const resposta = await comunicacao.send(
        endpoint,
        {
          method: 'POST',
          data: {
            campanha: campanha ? { ano: campanha.ano, identificacao: campanha.identificacao } : null,
            usuario: dados.usuario,
            tipo: 'NaoFiscal',
            uf: dados.uf,
            extra: dados.extra,
            uriImagem: dados.uriImagem,
            dadosNaoFiscal: {
              cnpjEmitente: dados.cnpj,
              nomeEmitente: dados.nomeEmitente,
              dataEmissao: dados.dataEmissao,
              valorTotal: dados.valorTotal,
              cnpjCpf: dados.usuario,
              numeroDocumento: numeroDigitado ? String(numeroDigitado) : String(Date.now()),
              formaPagamento: dados.formaPagamento,
              produtosServicos: dados.produtosServicos,
              vendedor: dados.vendedor || null,
            },
          },
        },
        50000
      );
      if (resposta.data.status == 200) {
        commit('setCompras', resposta.data.retorno);
        resolve({ status: 200, mensagem: resposta.data.mensagem });
      } else {
        commit('setCompras', []);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem, dados, numeroDigitado });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setCompras(state, data) {
    state.compras = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
