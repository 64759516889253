// Importação de bibliotecas e modelo padrão.
const api = require('./StoreConfigApi');
const axios = require('axios');

// Propriedades privadas da classe.
const privateProps = new WeakMap();

/**
 * Classe que salva configurações dos módulos e componentes que o usuário poderá
 * acessar durante o uso do aplicativo.
 */
class StoreConfig {
	constructor() {
		let arquivo, storeConfigKey, logica, url;

		logica = StoreConfig.logicaNomeArquivoUrl();
		arquivo = logica.arquivo;
		storeConfigKey = logica.storeConfigKey;
		privateProps.set(this, { arquivo, url, storeConfigKey });
	}

	static instancias() {
		if (localStorage.getItem('storeConfig@List')) {
			return JSON.parse(localStorage.getItem('storeConfig@List'))
		} else {
			return []
		}
	}

	static logicaNomeArquivoUrl() {
		// Identificação de ambientes
		if (!localStorage.storeConfigDashboard) {
			return {
				arquivo: "default.json",
				storeConfigKey: 'storeConfig@default'
			};
		}
		// Retornando configurações.
		return {
			arquivo: localStorage.storeConfigDashboard,
			storeConfigKey: 'storeConfig@' + localStorage.storeConfigDashboard
		};
	}

	static setArquivo(arquivo) {
		if (!arquivo) {
			localStorage.removeItem('storeConfigDashboard');
		} else {
			let instancias = this.instancias();
			for (let i in instancias) {
				if (instancias[i].desc == arquivo) {
					localStorage.storeConfigDashboard = arquivo;
				}
			}
		}
	}

	get arquivo() {
		return privateProps.get(this).arquivo;
	}

	get storeConfigKey() {
		return privateProps.get(this).storeConfigKey;
	}

	async obterConfiguracaoPadrao() {
		try {
			// Requisição http.
			const resposta = await axios.get(`/config/${this.arquivo}`);
			// Verificando resposta.
			if ([200, 0].includes(resposta.status) && resposta.data) {
				// Recuperando entradas da resposta
				const entradas = resposta.data
				// Transformando configurações em string para gravação local
				const config = JSON.stringify(entradas);
				// Retornando configurações
				return config;
			} else {
				return false;
			}
		} catch (e) {
			return false;
		}
	}

	async obterListaConfiguracoes() {
		try {
			const resposta = await api.post(`/configuracoes/listar`, {
				filtro: { aplicacao: 'QRSORTEIOS', nome: 'DASHBOARD' }
			});
			if ([200, 201].includes(resposta.status)) {
				const configuracoes = resposta.data.dados;
				const configuracoesFormatadas = configuracoes.map(c => {
					return {
						id: c.id,
						arquivo: c.id,
						desc: c.cliente,
						urls: [],
						entradas: c.entradas
					}
				})
				return configuracoesFormatadas;
			}
		} catch (e) {
			return false;
		}
	}

	obterConfiguracaoDaLista(listaObtida) {
		for (let i in listaObtida) {
			let encontrouUrl = listaObtida[i].desc == this.arquivo;
			if (encontrouUrl) {
				const entradas = listaObtida[i].entradas;
				const entradasParseadas = {};
				entradas.forEach(e => {
					try {
						entradasParseadas[e.chave] = JSON.parse(e.valor);
					} catch (_) {
						entradasParseadas[e.chave] = e.valor;
					}
				})
				const config = JSON.stringify(entradasParseadas);
				return config
			}
		}
		return false
	}

	async iniciar() {
		// Lista vinda do configurador
		let listaConfiguracoes;
		if (localStorage.getItem('storeConfig@List')) {
			listaConfiguracoes = JSON.parse(localStorage.getItem('storeConfig@List'));
			const novaLista = await this.obterListaConfiguracoes();
			localStorage.setItem('storeConfig@List', JSON.stringify(novaLista))
		} else {
			listaConfiguracoes = await this.obterListaConfiguracoes();
			localStorage.setItem('storeConfig@List', JSON.stringify(listaConfiguracoes))
		}

		// Verificando se a lista veio sucessivamente
		if (!listaConfiguracoes) {
			return false;
		}

		// Buscando configuração
		const configuracao = (this.arquivo == 'default.json')
			? await this.obterConfiguracaoPadrao()
			: this.obterConfiguracaoDaLista(listaConfiguracoes);
		if (!configuracao) {
			return false;
		}
		localStorage.setItem(this.storeConfigKey, configuracao);

		// Retornando que deu tudo certo
		return true;
	}

	static configuracoes() {
		let logica = StoreConfig.logicaNomeArquivoUrl();
		if (localStorage.getItem(logica.storeConfigKey)) {
			return JSON.parse(localStorage.getItem(logica.storeConfigKey));
		} else {
			return false;
		}
	}
}

export default StoreConfig;