<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns" :columns="columns">
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item" :index="index">
          <td
            v-for="(column, index) in colsWithValue(item)"
            :key="index">
            {{ itemValue(item, column) }}
          </td>
        </slot>
      </tr>
    </tbody>
        <tfoot :class="tfootClasses">
      <tr>
        <slot name="foots" :foots="foots">
          <th v-for="foot in foots" :key="foot">{{ foot }}</th>
        </slot>
      </tr>
    </tfoot>
  </table>
</template>
<script>
export default {
  name: 'base-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns'
    },
    foots: {
      type: Array,
      default: () => [],
      description: 'Table foots'
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data'
    },
    type: {
      type: String, // striped | hover
      default: '',
      description: 'Whether table is striped or hover type'
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes'
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes'
    },
    tfootClasses: {
      type: String,
      default: '',
      description: '<tfoot> css classes'
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    colsWithValue() {
      return (row) => {
        return this.columns.filter(column => this.hasValue(row, column))
      }
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    }
  }
};
</script>

<style lang="scss">
  .table tbody tr:nth-last-child(-n+3) {
    position: relative;
    td .dropdown-menu.dropdown-menu-right.show {
      bottom: 35px;
      top: unset;
    }
    &:nth-child(-n+3) td .dropdown-menu.dropdown-menu-right.show {
      bottom: unset !important;
      top: 100% !important;
    }
  }
</style>