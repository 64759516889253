import Vue from 'vue';

const initialState = () => ({
  grupos: [],
});

const state = initialState();

const getters = {
  getGrupos: state => state.grupos,

  getGruposToSelectInput: state =>
    state.grupos.map(grupo => ({
      desc: `${grupo.id} · Série de ${grupo.serie.inicial} a ${grupo.serie.final}`,
      value: {
        serieInicial: grupo.serie.inicial,
        serieFinal: grupo.serie.final,
      },
    })),
};

const actions = {
  fetchGrupos({ commit }, { anoCampanha, identificacaoCampanha }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `campanha/v1/gruposassociados/${anoCampanha}/${identificacaoCampanha}`;

      const resposta = await comunicacao.send(url, { method: 'GET' });

      if (resposta.data.status == 200) {
        commit('setGrupos', resposta.data.retorno);
        resolve({ status: 200, mensagem: 'Lista de Grupos recuperada com sucesso' });
      } else {
        commit('setGrupos', []);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  incluirGrupo(_, { idCampanha, id, descricao, serie }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `campanha/v1/gruposassociados`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          idCampanha,
          id,
          descricao,
          serie,
        },
      });

      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Grupo cadastrado com sucesso' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  editarGrupo(_, { idCampanha, id, descricao, serie }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `campanha/v1/gruposassociados`;

      const resposta = await comunicacao.send(url, {
        method: 'PUT',
        data: {
          idCampanha,
          id,
          descricao,
          serie,
        },
      });

      if ([200].includes(resposta.data.status)) {
        resolve({ status: 200, mensagem: 'Grupo editado com sucesso' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  deletarGrupo(_, { idCampanha, id }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `campanha/v1/gruposassociados`;

      const resposta = await comunicacao.send(url, {
        method: 'DELETE',
        data: {
          idCampanha,
          id,
        },
      });

      if ([200, 204].includes(resposta.data.status)) {
        resolve({ status: 200, mensagem: 'Grupo excluído com sucesso' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },
};

const mutations = {
  setGrupos(state, data) {
    state.grupos = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
