import momentTimezone from 'moment-timezone';

/**
 * Classe para manipulação de datas
 */
class Datas {
  /**
   *
   * @param {Date} data Data a ser convertida
   * @param {Boolean} somenteData Se é somente a data sem a hora
   */
  static dateTimeToISO(data = new Date(), somenteData) {
    let iso = data.toISOString();
    let end = somenteData ? 10 : 19;

    iso = iso.replace('T', ' ').slice(0, end);

    return iso;
  }

  static dataLegenda(data) {
    return new Date(data).toISOString().slice(5, 10).split('-').reverse().join('/');
  }

  /**
   * Retorna um objeto Date informando a data selecionada para o quantidade de dias atrás
   * @param {qtdeDias} qtdeDias Numebr
   */
  static dataAnterior(qtdeDias) {
    let data = new Date();
    data.setDate(data.getDate() - qtdeDias);
    return data;
  }

  /**
   * Retorna a data atual no formato YYYY-MM-DD
   */
  static dataAtual(dataReferencia = null) {
    return dataReferencia
      ? momentTimezone(dataReferencia).format('YYYY-MM-DD')
      : momentTimezone.tz('America/Sao_Paulo').locale('pt-BR').format('YYYY-MM-DD');
  }

  /**
   * Formata data e hora do padrão YYYY-MM-DD HH:mm:dd para DD/MM/YYYY HH:mm:ss.
   * @param {string} dataHora - data no formato YYYY-MM-DD HH:mm:ss.
   * @param {string} formatacao - formato em que a data final deve ser retornada.
   * @return {string} - com data formatada no padrão: DD/MM/YYYY HH:mm:ss.
   */
  static formatarDataHora(dataHora = null, formatacao = null) {
    return dataHora
      ? momentTimezone(dataHora).format(formatacao || 'DD-MM-YYYY HH:mm:ss')
      : momentTimezone
          .tz('America/Sao_Paulo')
          .locale('pt-BR')
          .format(formatacao || 'DD-MM-YYYY HH:mm:ss');
  }

  /**
   * Formata data do padrão YYYY-MM-DD para DD/MM/YYYY.
   * @param {string} data - data no formato YYYY-MM-DD.
   * @param {string} formatacao - formato em que a data final deve ser retornada.
   * @return {string} - com data formatada no padrão: DD/MM/YYYY.
   */
  static formatarData(data = null, formatacao = null) {
    return data
      ? momentTimezone(data).format(formatacao || 'DD-MM-YYYY')
      : momentTimezone
          .tz('America/Sao_Paulo')
          .locale('pt-BR')
          .format(formatacao || 'DD-MM-YYYY');
  }

  /**
   * Retorna a hora atual no formato HH:mm:ss
   */
  static horaAtual() {
    return momentTimezone.tz('America/Sao_Paulo').locale('pt-BR').format('HH:mm:ss');
  }

  /**
   * Retorna a data e hora atual no formato YYYY-MM-DD HH:mm:ss
   */
  static dataHoraAtual() {
    return momentTimezone.tz('America/Sao_Paulo').locale('pt-BR').format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Retorna a data resultante do acréscimo entre a data informada e a quantidade de dias.
   * @param {string} data - data ou dataHora na qual a quantidade de dias sera acrescentada.
   * @param {number} numeroDias - numero de dias a somar na data informada.
   */
  static incrementaDiasData(data, numeroDias) {
    let limite = momentTimezone(data).add(numeroDias, 'days').format('YYYY-MM-DD');
    return limite;
  }

  /**
   * Retorna a data resultante do decréscimo entre a data informada e a quantidade de dias.
   * @param {string} data - data ou dataHora na qual a quantidade de dias sera acrescentada.
   * @param {number} numeroDias - numero de dias a somar na data informada.
   */
  static decrementaDiasData(numeroDias, data = null) {
    let limite = momentTimezone(data || this.dataAtual())
      .subtract(numeroDias, 'days')
      .format('YYYY-MM-DD');
    return limite;
  }

  /**
   * Retorna a data resultante do decréscimo entre a data informada e a quantidade de meses.
   * @param {string} data - data na qual a quantidade de meses será subtraída.
   * @param {number} numeroMeses - numero de meses a subtrair na data informada.
   * @param {string} formatacao - formato em que a data final deve ser retornada.
   */
  static decrementaMesesData(numeroMeses = 0, data = null, formatacao = null) {
    let limite = momentTimezone(data || this.dataAtual())
      .subtract(numeroMeses, 'months')
      .format(formatacao || 'YYYY-MM-DD');
    return limite;
  }

  /**
   * Retorna a diferença em dias entre duas datas
   */
  static diferencaDiasEntreDatas(dataInicial, dataFinal) {
    const diferenca = momentTimezone(dataInicial).diff(dataFinal || this.dataAtual(), 'days');
    return diferenca;
  }

  /**
   * Retorna se uma data é anterior a outra
   * @param {string} dataVerificar - data a verificar no formato YYYY-MM-DD
   * @param {string} dataMinima - data no formato YYYY-MM-DD
   * @return {boolean} - true se a data informada for anterior e false se não for
   */
  static dataEhAnterior(dataVerificar, dataMinima) {
    return momentTimezone(dataVerificar).isBefore(dataMinima, 'day');
  }

  /**
   * Formata data padrão ISO 8601
   * @param {8} data data
   */
  static formataData_ISO_8601(data) {
    if (!data) return '';
    return data.replace(/^(\d{2})\/(\d{2})\/(\d{4})(.)*/, '$3-$2-$1');
  }

  static excelDateToJSDate(data) {
    return new Date(Math.round((data - 25569) * 86400 * 1000));
  }

  /**
   * Verifica se as datas informadas são um mês completo
   * @param {string} dataEntradaInicial - Data inicial no formato YYYY-MM-DD
   * @param {string} dataEntradaFinal - Data final no formato YYYY-MM-DD
   */
  static verificarInicioFimDoMes(dataInicial, dataFinal) {
    // Criar objetos de data com as strings recebidas
    const inicioDoMes = momentTimezone(dataInicial);
    const fimDoMes = momentTimezone(dataFinal);

    // Verificar se a data inicial é o primeiro dia do mês
    const primeiroDiaDoMes = inicioDoMes.date() === 1;

    // Verificar se a data final é o último dia do mês
    const ultimoDiaDoMes = fimDoMes.date() === fimDoMes.daysInMonth();

    // Retornar o resultado da comparação
    return primeiroDiaDoMes && ultimoDiaDoMes;
  }

  /**
   * Verifica se as datas informadas são uma semana completa (segunda-feira à domingo)
   * @param {string} dataEntradaInicial - Data inicial no formato YYYY-MM-DD
   * @param {string} dataEntradaFinal - Data final no formato YYYY-MM-DD
   */
  static verificarSegundaEhDomingo(dataInicial, dataFinal) {
    const dataInicio = momentTimezone(dataInicial);
    const dataFim = momentTimezone(dataFinal);

    // Verificar se o dia da semana da data inicial é segunda-feira (1) e da data final é domingo (0)
    if (dataInicio.isoWeekday() === 1 && dataFim.isoWeekday() === 7) {
      return true;
    }

    return false;
  }

  /**
   * Retorna o comparativo de uma data no período anterior
   * @param {string} dataEntradaInicial - Data inicial no formato YYYY-MM-DD
   * @param {string} dataEntradaFinal - Data final no formato YYYY-MM-DD
   */
  static dataPeriodoAnterior(dataEntradaInicial, dataEntradaFinal) {
    const dataEntradaInicialFormatada = momentTimezone(dataEntradaInicial).format('YYYY-MM-DD');
    const dataEntradaFinalFormatada = momentTimezone(dataEntradaFinal).format('YYYY-MM-DD');

    let dataComparativoInicial;
    let dataComparativoFinal;
    let comparandoPor;

    const ehMesmoMes = momentTimezone(dataEntradaInicialFormatada).isSame(dataEntradaFinalFormatada, 'month');

    if (ehMesmoMes) {
      dataComparativoInicial = this.decrementaMesesData(1, dataEntradaInicialFormatada);
      dataComparativoFinal = dataComparativoFinal = momentTimezone(dataEntradaFinalFormatada)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      comparandoPor = 'mes-anterior';
    }

    const saoDiasIguais = dataEntradaInicialFormatada === dataEntradaFinalFormatada;

    if (saoDiasIguais) {
      dataComparativoInicial = this.decrementaDiasData(1, dataEntradaInicialFormatada);
      dataComparativoFinal = this.decrementaDiasData(1, dataEntradaFinalFormatada);
      comparandoPor = 'dia-anterior';
    }

    const ehMesCompleto = this.verificarInicioFimDoMes(dataEntradaInicialFormatada, dataEntradaFinalFormatada);

    if (ehMesCompleto) {
      dataComparativoInicial = this.decrementaMesesData(1, dataEntradaInicialFormatada);
      dataComparativoFinal = momentTimezone(dataEntradaFinalFormatada)
        .subtract(1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD');
      comparandoPor = 'mes-anterior-completo';
    }

    const ehSemanaCompleta = this.verificarSegundaEhDomingo(dataEntradaInicialFormatada, dataEntradaFinalFormatada);

    if (ehSemanaCompleta) {
      dataComparativoInicial = this.decrementaDiasData(7, dataEntradaInicialFormatada);
      dataComparativoFinal = this.decrementaDiasData(7, dataEntradaFinalFormatada);
      comparandoPor = 'semana-anterior-completa';
    }

    if (dataComparativoInicial && dataComparativoFinal) {
      return {
        dataComparativoInicial: this.formatarData(dataComparativoInicial, 'YYYY-MM-DD'),
        dataComparativoFinal: this.formatarData(dataComparativoFinal, 'YYYY-MM-DD'),
        comparandoPor,
      };
    }

    return {
      dataComparativoInicial: null,
      dataComparativoFinal: null,
      comparandoPor: null,
    };
  }
}

export default Datas;
