import Vue from 'vue';

// Object initial state
const initialState = () => ({
  carregando: false,
  provedoresDeVoucher: null,
  totalProvedoresDeVoucher: 0,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getCarregando(state) {
    return state.carregando;
  },

  getProvedoresDeVoucher(state) {
    return state.provedoresDeVoucher;
  },

  getTotalProvedoresDeVoucher(state) {
    return state.totalProvedoresDeVoucher;
  },
};

// Actions
const actions = {
  listarProvedoresDeVoucher({ commit }, { paginacao, filtros = {} }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `fidelidade/v1/provedoresDeVoucher/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const res = await comunicacao.send(endpoint, { method: 'GET', data: filtros });

      if ([200, 201, 404].includes(res.status)) {
        commit('setProvedoresDeVoucher', res.data.retorno.retorno);
        commit('setTotalrovedoresDeVoucher', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Lista de provedores obtida com sucesso!',
          total: res.data.retorno.total,
          provedores: res.data.retorno.retorno,
        });
      } else {
        commit('setProvedoresDeVoucher', []);
        commit('setTotalrovedoresDeVoucher', 0);
        resolve({
          status: res.status || 500,
          msg: 'Erro desconhecido ao listar os provedores de voucher. Tente novamente.',
        });
      }
    });
  },

  cadastrarProvedorDeVoucher({ commit }, { provedor, nome, imagem, descricao, instrucao }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `fidelidade/v1/provedoresDeVoucher`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          provedor,
          nome,
          imagem,
          descricao,
          instrucao,
        },
      });

      commit('setCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Provedor de voucher cadastrado com sucesso!' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  editarProvedorDeVoucher({ commit }, { idProvedor, provedor, nome, imagem, descricao, instrucao }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `fidelidade/v1/provedoresDeVoucher/${idProvedor}`;

      const resposta = await comunicacao.send(url, {
        method: 'PUT',
        data: {
          provedor,
          nome,
          imagem,
          descricao,
          instrucao,
        },
      });

      commit('setCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Provedor de voucher cadastrado com sucesso!' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  removerProvedorDeVoucher({ commit }, { idProvedor }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `fidelidade/v1/provedoresDeVoucher/${idProvedor}`;

      const resposta = await comunicacao.send(url, {
        method: 'DELETE',
        data: {},
      });

      commit('setCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 200, mensagem: 'Provedor de voucher deleteado com sucesso!' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setCarregando(state, data) {
    state.carregando = data;
  },

  setProvedoresDeVoucher(state, data) {
    state.provedoresDeVoucher = data;
  },

  setTotalrovedoresDeVoucher(state, data) {
    state.totalProvedoresDeVoucher = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
