/**
 * Retorno padrão para chamadas ao chat.
 * @class {Chat}
 */

const SockJS = require("./lib").SockJS;

let instancia = null;

class Chat {
    /**
     * Cria um objeto de comunicação com o Chat
     * @param {string} urlServidor - URL Base
     */

    constructor(urlServidor, aberta, fechada, receber) {
        if (instancia) return instancia;
        instancia = this;
        // this.urlServidor = "http://localhost:3000/echo";
        this.urlServidor = urlServidor;
        this.aberta = aberta;
        this.sock = new SockJS(this.urlServidor);
        this.conectado = false;

        this.sock.onopen = () => {
            this.conectado = true;
            this.aberta()
        }
            ;
        this.sock.onclose = fechada;
        this.sock.onmessage = (e) => {
            try {
                e = JSON.parse(e.data);
                // console.log("recebendo sock", e.canal, e.valor)
                receber(e);
            } catch (e) { 
                localStorage.setItem('erroChat', JSON.stringify(e));
            }
        };
    }


    /**
     * 
     * @param {string} canal - Canal de destino
     * @param {string} valor - valor
     */
    async enviar(canal, valor) {
        if (this.conectado) {
            // console.log("enviando sock", canal, valor)
            this.sock.send(
                JSON.stringify({ canal: canal, valor: valor })
            );
        }
        else{
            // console.log("ainda não conectado, aguardando", canal, valor);
        }
    }
}

export default Chat;