import Vue from 'vue';

Vue.filter('cnpj', function(valor) {
  var x = valor.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);

  return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
});

Vue.filter('dataHoraBR', function(valor) {
  return new Date(valor).toLocaleString();
});
