<template>
  <div
    class="exibicao-por-pagina d-flex justify-content-center"
    @click="dropdownAtivo = !dropdownAtivo"
    v-click-outside="closeDropdown">

    <p class="text-uppercase mb-0">
      Exibindo
      <strong>{{ totalNaPagina }} </strong>itens na página
      <i class="fas fa-angle-down"></i>
    </p>

    <ul class="base-itens d-flex flex-column align-items-center" :class="{ ativo: dropdownAtivo }">
      <li
        class="item"
        :class="{ ativo: quantidade <= totalItens && dropdownAtivo }"
        v-for="(quantidade, idx) in quantidadeFormatada"
        :key="idx"
        @click="alterarTotalPorPagina(quantidade)"
      >
        {{ quantidade }} itens
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BaseExibicaoPorPagina',

  props: {
    totalNaPagina: {
      required: true,
      type: Number,
    },
    totalItens: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      dropdownAtivo: false,
      quantidades: [5, 10, 20, 50, 100],
    };
  },

  computed: {
    quantidadeFormatada() {
      const quantidades = this.quantidades.filter(Boolean)

      if (!quantidades.includes(this.totalItens) && this.totalItens < 100) {
        quantidades.push(this.totalItens);
      }

      return quantidades.sort((a, b) => a - b);
    },
  },

  methods: {
    alterarTotalPorPagina(item) {
      this.$emit('input', item);
    },
    closeDropdown() {
      this.dropdownAtivo = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.exibicao-por-pagina {
  position: relative;

  i.fas {
    margin-left: 3px;
  }

  > p {
    font-size: 0.75rem;
    color: #aaa;

    > strong {
      font-weight: 600;
    }
  }

  > .base-itens {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    top: 24px;
    padding: 0.5rem 0;
    z-index: 99999;

    &.ativo {
      opacity: 1;
      pointer-events: all;
    }

    > .item {
      list-style: none;
      font-size: 0.75rem;
      color: #aaa;
      transition: all 0.3s;
      padding: 0 0.5rem;
      width: 100%;
      text-align: center;
      pointer-events: none;
      opacity: 0.6;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &.ativo {
        pointer-events: all;
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
</style>