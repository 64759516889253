/**
 * Classe de manipulação de objetos
 */
class Objeto {
  /** Manipulacao de Objetos */

  /**
   * Mergeia dois objetos JSON
   * @param {Object} a Objeto A
   * @param {Object} b Objeto B
   */
  static leftMerge(a, b) {
    const res = {};
    for (const p in a) res[p] = (p in b ? b : a)[p];
    return res;
  }
  /**
   * Limpa propriedades nulas dentro de um objeto
   * @param {Object} obj Objeto a ser limpado
   */
  static clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  static ordernarArray(propriedade, ordem) {
    if (propriedade[0] === "-") {
      ordem = -1;
      propriedade = propriedade.substr(1);
    }
    // eslint-disable-next-line
    return function (a, b) {

      if (propriedade == 'raspada.dataHoraRaspada') {
        if (!a.raspada.dataHoraRaspada && ordem == -1) a.raspada.dataHoraRaspada = "0000-00-00 00:00:00"
        else if (!a.raspada.dataHoraRaspada && ordem == 1) a.raspada.dataHoraRaspada = "9999-12-31 00:00:00"
        if (!b.raspada.dataHoraRaspada && ordem == -1) b.raspada.dataHoraRaspada = "0000-00-00 00:00:00"
        else if (!b.raspada.dataHoraRaspada && ordem == 1) b.raspada.dataHoraRaspada = "9999-12-31 00:00:00"

        a.raspada.dataHoraRaspada = a.raspada.dataHoraRaspada.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        b.raspada.dataHoraRaspada = b.raspada.dataHoraRaspada.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let resultado = (a.raspada.dataHoraRaspada < b.raspada.dataHoraRaspada) ? -1 : (a.raspada.dataHoraRaspada > b.raspada.dataHoraRaspada) ? 1 : 0;

        if (a.raspada.dataHoraRaspada == "0000-00-00 00:00:00" || a.raspada.dataHoraRaspada == "9999-12-31 00:00:00") a.raspada.dataHoraRaspada = null
        if (b.raspada.dataHoraRaspada == "0000-00-00 00:00:00" || b.raspada.dataHoraRaspada == "9999-12-31 00:00:00") b.raspada.dataHoraRaspada = null

        return resultado * ordem;
      }
      else {
        a = a[propriedade] ? a[propriedade] : eval('a.' + propriedade);
        b = b[propriedade] ? b[propriedade] : eval('b.' + propriedade);


        if (typeof a == 'number' && typeof b == 'number') {
          var resultadoNumerico = (a < b) ? -1 : (a > b) ? 1 : 0;
          return resultadoNumerico * ordem;
        }

        if (typeof a == 'boolean' && typeof b == 'boolean') {
          var resultadobooleano = (a && !b) ? -1 : (!a && b) ? 1 : 0;
          return resultadobooleano * ordem;
        }

        if (a && b) {
          a = a.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          b = b.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }


        let resultado = (a < b) ? -1 : (a > b) ? 1 : 0;

        return resultado * ordem;
      }
    }
  }
  /**
   * Filtra numericamente um objeto usando o parametro de pesquisa nos campos selecionados
   * @param {Object} dados Objeto de dados da tabela
   * @param {String} pesquisa String de pesquisa numerica
   * @param {Array} campos Campos a serem filtrados dentro do objeto
   */
  static filtrarNumero(dados, pesquisa, campos) {
    let pesquisaNum = pesquisa.replace(/[^\d.-]/g, "");
    if (pesquisaNum.length == pesquisa.length) {
      for (let i in campos) {
        if (dados[campos[i]].startsWith(pesquisaNum)) {
          return true;
        }
      }
    }
    return false;
  }
  /**
   * Filtra  um objeto usando o parametro de pesquisa nos campos selecionados
   * @param {Object} dados Objeto de dados da tabela
   * @param {String} pesquisa String de pesquisa
   * @param {Array} campos Campos a serem filtrados dentro do objeto
   */
  static filtrarTexto(dados, pesquisa, campos) {
    if (pesquisa.length) {
      for (let i in campos) {
        if (dados[campos[i]].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(pesquisa.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  /**
   * Filtro de pesquisa que aciona o FiltrarTexto() e FiltrarNumero()
   * @param {Object} data Objeto
   * @param {Object} opcoes Opções {pesquisa: "", camposTexto: [], camposNumero: []}
   */
  static filtroPesquisa(data, opcoes) {
    let opcoesPadrao = {
      pesquisa: "", camposTexto: [], camposNumero: []
    }
    opcoes = this.leftMerge(opcoesPadrao, opcoes);

    if (this.filtrarTexto(data, opcoes.pesquisa, opcoes.camposTexto)) {
      return true;
    }
    if (this.filtrarNumero(data, opcoes.pesquisa, opcoes.camposNumero)) {
      return true;
    }

    return false;
  }

  /**
   * Dividr um array em lotes
   * @param {any[]} itens - array de itens.
   * @param {number} maximo - numero de itens por lote. 
   * @returns {any[]} - array de lotes.
   */
  static dividirEmLotes(itens, maximo) {
    return itens.reduce((acumulador, item, indice) => {
      const grupo = Math.floor(indice / maximo);
      acumulador[grupo] = [...(acumulador[grupo] || []), item];

      return acumulador;
    }, []);
  }
}
export default Objeto;
