<template>
  <card
    class="card-stats hover-black"
    :style="`background-color: ${backgroundColor}`"
    :show-footer-line="true"
    bodyClasses="m-0 pb-0 pt-0 d-flex justify-content-center"
  >
    <div class="row" style="align-items: center; flex-wrap: nowrap">
      <div class="col-auto p-0" v-if="$slots.icon || icon">
        <slot name="icon">
          <div class="icon-sm icon-shape text-white rounded-circle shadow" :class="[`bg-${type}`, iconClasses]">
            <i :class="icon"></i>
          </div>
        </slot>
      </div>

      <div class="col d-flex flex-column justify-content-between" style="line-height: 1; min-height: 70px">
        <slot>
          <h2
            class="card-title text-uppercase mb-0"
            :class="textDark ? 'text-dark' : ''"
            v-if="subTitle && !loading"
            style="color: #fff"
            :style="campanha ? 'font-size: 0.9rem;' : ''"
            v-html="!moeda ? subTitle : `<span style='font-size: 0.6rem;'>R$</span>${subTitle}`"
          ></h2>
          <p
            class="card-title text-uppercase mb-0"
            :class="textDark ? 'text-dark' : ''"
            v-if="description && !loading"
            style="color: #fff; font-size: 0.8rem; font-weight: bold"
            :style="campanha ? 'font-size: 0.9rem;' : ''"
          >
            {{ description }}
          </p>
          <span
            class="h5 font-weight-bold mb-0 span-text-card"
            :class="textDark ? 'text-dark' : ''"
            v-if="title"
            style="font-size: 10px"
            v-html="title + (dia == true ? ' Por Dia' : '')"
          >
          </span>
          <span></span>

          <base-progress
            v-if="progress && !loading"
            type="default"
            :showPercentage="false"
            :animated="true"
            :height="2"
            :value="progress"
          ></base-progress>
          <div v-if="loading" class="text-center pt-1">
            <loading
              color="#246152"
              background-color="#52a065"
              :height="15"
              :active="true"
              :is-full-page="false"
            ></loading>
          </div>
        </slot>
      </div>

      <div class="col-12 p-0 mt--3 d-flex justify-content-end" v-if="$slots.switchIcon || switchIcon">
        <slot name="switchIcon">
          <a href="#" @click.prevent="callback(dia)">
            <div class="icon icon-shape text-white rounded-circle shadow" :class="[`bg-${type}`, iconClasses]">
              <i v-if="!dia" :class="switchIcon"></i>
              <i v-else class="fas fa-undo-alt"></i>
            </div>
          </a>
        </slot>
      </div>
    </div>

    <p class="mt-3 mb-0 text-sm">
      <slot name="footer"></slot>
    </p>
  </card>
</template>
<script>
import Card from './Card.vue';
import Loading from 'vue-loading-overlay';

export default {
  name: 'stats-card',
  components: {
    Card,
    Loading,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
    },

    icon: String,

    dia: {
      type: Boolean,
      default: false,
    },

    switchIcon: String,

    title: String,
    description: String,

    progress: Number,

    loading: {
      type: Boolean,
      default: false,
    },

    subTitle: String,

    iconClasses: [String, Array],

    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.25)',
    },

    textDark: {
      type: Boolean,
      default: false,
    },

    moeda: {
      type: Boolean,
      default: false,
    },

    campanha: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    callback: function (dia) {
      return this.$emit('clicked', dia);
    },
  },
};
</script>

<style scoped lang="scss">
h5 {
  font-size: 0.7rem;
}
.hover-black:hover {
  p {
    color: black !important;
  }
}
</style>
