/**
 * Classe para formatar um objeto de endereço.
 */
class Endereco {
    
    /**
     * Construtor vazio.
     */
    constructor() {}

    /**
     * Formata o endereço informado e o retorna em formato de string.
     * @param {Endereco} endereco
     * @returns {string} - Endereço formatado
     */
    static formatarEndereço(endereco) {
        return `${endereco.endereco}, ${endereco.numero}${endereco.complemento 
            ? ', ' + endereco.complemento 
            : ''}, ${endereco.bairro}, ${endereco.cidade}, ${endereco.cep} - ${endereco.estado}`;
    }
}

export default Endereco;