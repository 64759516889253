import { Autenticacao } from '../../lib/Autenticacao';

const autenticacao = Autenticacao.default;

export default function logado({ to, next, router }) {
  const usuarioLogado = autenticacao.logado();

  if (!usuarioLogado.token) {
    return router.push({
      name: 'login',
      query: { redirecionar: to.fullPath },
    });
  }
  
  return next();
}