<template>
  <div class="base-subtitulo d-flex align-items-center">
    <template v-if="texto">
      <h2 class="subtitulo">{{ texto }}</h2>
    </template>

    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseSubtitulo',

  props: {
    texto: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-subtitulo {
  &::before {
    content: '';
    width: 4px;
    height: 20px;
    background-color: #51b0ff;
    display: block;
    margin-right: 5px;
  }

  .subtitulo {
    margin-bottom: 0;
    font-weight: 700;
    color: #cecece;
    text-transform: uppercase;
    line-height: 100%;
  }

  .btn-download {
    background-color: #055ba3;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    height: 25px;
    color: #fff;
    font-size: 0.75rem;
    margin-left: 0.5rem;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
}
</style>